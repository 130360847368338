import React 									from 'react'
import { observer }								from 'mobx-react'
import Eventscolas 								from '../../fun/js'

import Auxiliar 								from '../../fun/fun'
import MUO_functions 							from './js/MUO_fun'

import MUO_str 									from './storage/MUO_str'
// import Sesion 									from './ses'

// import Register 								from './register/register'
// import Routes 									from './download/Download'
// import Shop 									from './shop/shop'

import Loggin 									from './loggin/loggin'



import { Outlet, Link} 								from "react-router-dom"



// import Reports 									from './rep'
// import Equipment 								from './equ'
// import Payments 								from './pay'
// import Admin 									from './adm'
// import Lateral_m								from './mla'
// import Lateral_sm								from './mls'

// import List_veh									from './lis'
// import List_veh_focus							from './lis-f'
// import Legend_buttons							from './lbo'
// import Denuncias								from './den'
// import TimeRange 								from 'react-timeline-range-slider' 
import DateRangePicker 							from '@wojtekmaj/react-daterange-picker'

// import useSound from 'use-sound'
// import UIfx from 'uifx';
// import boopSfx from '../../son/alert.mp3'

// // const beep = new UIfx({asset: boopSfx, volume: 1.0 });
// const beep = new UIfx(boopSfx);

// function Sss() {
// 	const [play] = useSound(boopSfx, {
// 		volume: 0.8,
// 		playbackRate: 1.5
// 	})
// 	play()
// }

// console.log(boopSfx);

class Start extends React.Component {
	
	render () {
		return(<>
		    <div
		    	className="ctn-MW us_se_n ov1 hep100"
		    >
				<header>
					<div
						className='header-MW hep100'
					>
						<div
							className='others hep100 fo_sr15'
						>
							<div
								className='logo-sg hep100 c_red cupo'
							><Link to={`home`}>{MUO_str.aad.aa001}</Link>
								
							</div>
							<div
								className='sg-games hep100'
							>
								{MUO_str.aad.aa002}
							</div>
							<div
								className='register hep100 cupo'
							><Link to={`register`}>{MUO_str.aad.aa003}</Link>
							</div>
							<div
								className='login hep100 cupo'
								onClick={()=> MUO_str.aac[0].bale === ''? MUO_str.increment(5, {sub : 1}) : MUO_str.increment(5, {sub : 3})}
							>
								{MUO_str.aac[0].bale === ''?  MUO_str.aad.aa004 : MUO_str.aac[0].bale}
							</div>
							<select
								className='languaje fo_sr11 her20 c_white black'
								onChange={(e)=> (MUO_functions.select_languaje(e.target.value))}  // incremenet 3
								value={MUO_str.aaa}
							>
								{MUO_str.aab.map((m, i) =>(
									m.active?
									<option 
										key = {i}
										value={m.iso6392}
									>{m.iso6392.toUpperCase()}</option>
									: ''
								))}
							</select>
						</div>
					</div>
				</header>
				<main
					className='ctn-body black sb_y1'
					id='bodycont'
				>
					<canvas
						className='amination '
					>

					</canvas>

					<div
						className='all_menus delay-1 z_i1'
					>
						<div
							className='menusle hep100'
						>
							<div
								className='news hep100 '
							>{MUO_str.aad.aa005}
							</div>
							<div
								className='guide hep100'
							>{MUO_str.aad.aa006}
							</div>
							<div
								className='board hep100'
							>{MUO_str.aad.aa007}
							</div>
						</div>
						<div
							className='down hep100'
						>
							<div
								className='download hep100 cupo'
							><Link to={`home`}> </Link>
							</div>
						</div>
						<div
							className='menusri hep100'
						>
							<div
								className='ranking hep100 cupo'
							><Link to={`ranking`}>{MUO_str.aad.aa008}</Link>
							</div>
							<div
								className='download  hep100 cupo'
								onClick={()=> Auxiliar.modal_control(0, {var1 : 'caf', var2 : 'ccc', e : true})}
							>{MUO_str.aad.aa009}
							</div>
							<div
								className='faq hep100'
							>{MUO_str.aad.aa010}
							</div>
							<div
								className='shop hep100 cupo'
							><Link to={`shop`}>{MUO_str.aad.aa011}</Link>
							</div>
						</div>
						
					</div>
					<div
						className='download_game delay-1 z_i1 cupo'
						onClick={()=> Auxiliar.modal_control(0, {var1 : 'caf', var2 : 'ccc', e : true})}
					>

					</div>
					{MUO_str.aac[0].aae ? 
						<div
							className='logginweb z_i1'
						>
							<Loggin/>
						</div>
					:''	}
					<div
						className='all_contents'
					>	
						{/* clild */}
						{/* {MUO_str.aac[0].aaa ?
							<Home/>
						:''	} */}
						{/* registrar */}
						{/* {MUO_str.aac[0].aab ?
							<Register/>
						:''	} */}
						{/* descargar */}
						{/* {MUO_str.aac[0].aac ?
							<Routes/>
						:''	} */}
						{/* {MUO_str.aac[0].aad ?
							<Ranking/>
						:''	} */}
						{/* {MUO_str.aac[0].aaf ?
							<Shop/>
						:''	} */}
						
						<Outlet />
					</div>

				</main>


				{/* <footer className="dark1 c_light alsece hep100">
					<div>
						&copy; 2020-2023
					</div>
				</footer> */}
		    	
		    </div>
 		</>)
	}
}
export default observer(Start)

//https://www.autoevolution.com/carfinder/