import React 									from 'react'
import { observer }								from 'mobx-react'



import Auxiliar 								from '../../../fun/fun'

import MUO_fun 									from '../js/MUO_fun'
import MUO_str 									from '../storage/MUO_str'

import ReactPlayer 								from 'react-player'

import MUO_APIs									from "../js/MUO_APIs"


	MUO_str.increment(1001)
	MUO_str.caa('aaba', true)
	if (!MUO_str.aac[0].oal){
		MUO_str.caa('oal', true)
		MUO_APIs.aaa(1, 0, 'GLO_GET_COUNTRIES', MUO_str.aac[0].oac === true ? MUO_str.aac[0].oaka : MUO_str.aac[0].oakb, 'POST', (ra)=>{
			// console.log(JSON.parse(ra).data.msg, 'MUO-STR-00673', new Date())
			MUO_str.cac(JSON.parse(ra).data.msg)
			MUO_str.caa('oal', false)
		}).catch((e)=>{
			console.log('error API GLO_GET_COUNTRIES', e)
		})
	}




class Register extends React.Component {

	render () {

 		return(<>
			{MUO_str.aac[0].aaba?
				<div
					className='toggled-element-left light register'
				>
					<div
						className='stepone hep100 stepon'
					>
						<div
							className=''
						>{MUO_str.aad.ab001}
						</div>
					</div>
					<div
						className='steptwo hep100 stepoff'
					>
						<div
							className=''
						>{MUO_str.aad.ab002}
						</div>
					</div>
					<div
						className='stepthree hep100 stepoff'
					>
						<div
							className=''
						>{MUO_str.aad.ab003}
						</div>
					</div>
					<div
						className='regis hep100'
					>
						<div
							className='tittle dig fo_sr30 hep100'
						>
							<div
								className=''
							>{MUO_str.aad.ab004}
							</div>
						</div>
						<div
							className='alerts hep100'
						>
							<div
								className='palere10'
							>{MUO_str.aad.ab005}<br/>{MUO_str.aad.ab006}<br/>{MUO_str.aad.ab007}
							</div>
						</div>
						<div
							className='regis_contend hep100'
						>
							<div
								className='regis_stepone hep100'
							>
								<div
									className='terms_privacy_perdata hep100' 
								>
									<div
										className='terms hep100 cupo'
										onClick={()=> Auxiliar.modal_control(0, {var1 : 'cab', var2 : 'ccc', e : true})} 
									>
										
									</div>
									<div
										className='privacy hep100 cupo'
										onClick={()=> Auxiliar.modal_control(0, {var1 : 'cac', var2 : 'ccc', e : true})} 
									>
										
									</div>
									<div
										className='perdata hep100'
									>{MUO_str.aad.ab010}
									</div>
									<div
										className='img_show hep100'
									>
									</div>
								</div>
								<div
									className='terms_privacy_perdata_ok hep100'
								>
									<div
										className='s_terms hep100 wip100'
									>
										<div
											className='di5'
										>
											<input
												type='checkbox'
												id='terms'
												className='di0 sw'
												checked={MUO_str.aac[0].baa === 1 ? 'checked' : ''} 
												onChange={()=>(Auxiliar.togle_swith('baa', MUO_str.aac[0].baa, 0))}
												
											/>
											<label className='lbl' htmlFor='terms'></label> 
										</div> 
									</div>
									<div
										className='t_terms'
									>{MUO_str.aad.ab008}
									</div>
									<div
										className='s_policy hep100 wip100'
									>
										<div
											className='di5'
										>
											<input
												type='checkbox'
												id='privacy'
												className='di0 sw'
												checked={MUO_str.aac[0].bab === 1 ? 'checked' : ''} 
												onChange={()=>(Auxiliar.togle_swith('bab', MUO_str.aac[0].bab ,0))} 
												
											/>
											<label className='lbl' htmlFor='privacy'></label> 
										</div>
									</div>
									<div
										className='t_policy'
									>{MUO_str.aad.ab009}
									</div>
									<div
										className='s_perdata hep100 wip100'
									>
										<div
											className='di5'
										>
											<input
												type='checkbox'
												id='persodata'
												className='di0 sw'
												checked={MUO_str.aac[0].bac === 1 ? 'checked' : ''} 
												onChange={()=>(Auxiliar.togle_swith('bac', MUO_str.aac[0].bac, 0))} 
												
											/>
											<label className='lbl' htmlFor='persodata'></label>
										</div>
									</div>
									<div
										className='t_perdata'
									>{MUO_str.aad.ab011}
									</div>
								</div>
								<div
									className='guide_steps hep100'
								>
									<div
										className='palere10'
									>{MUO_str.aad.ab014}
										<br/><br/>{MUO_str.aad.ab015}
										<br/>{MUO_str.aad.ab016}
										<br/><br/>{MUO_str.aad.ab017}
										<br/>{MUO_str.aad.ab018}
										<br/>{MUO_str.aad.ab019}
									</div>
								</div>
							</div>
						</div>
						<div
							className='next_panel hep100'
						>
							<div
								className='bottons'
							>
								<div
									className='cancel'
									onClick={()=> Auxiliar.modal_control(0, {var1 : 'caa', var2 : 'ccc', e : true})}
									>
									<div
										className='dig jucoce'
									>{MUO_str.aad.ab013}
									</div>
								</div>
								<div
									className='next'
									onClick={MUO_str.aac[0].baa === 1 ? 
										MUO_str.aac[0].bab === 1 ? 
											MUO_str.aac[0].bac === 1 ? 
												()=> <>	{MUO_str.increment(1001)} {MUO_str.caa('aabc', true)} </>
											: ()=> Auxiliar.modal_control(0, {var1 : 'cad', var2 : 'ccc', e : true}) 
										: ()=> Auxiliar.modal_control(0, {var1 : 'cad', var2 : 'ccc', e : true}) 
									: ()=> Auxiliar.modal_control(0, {var1 : 'cad', var2 : 'ccc', e : true})}
									><div
										className='dig jucoce'
									>{MUO_str.aad.ab012}
									</div>
								</div>
							</div>
							
						</div>
					</div>
				</div>
			:''}
			{MUO_str.aac[0].aabb? ''
			
			:''}
			{MUO_str.aac[0].aabc?
				<div
					className='toggled-element-left light register'
				>
					<div
						className='stepone hep100 stepoff'
					>
						<div
							className=''
						>{MUO_str.aad.ab001}
						</div>
					</div>
					<div
						className='steptwo hep100 stepoff'
					>
						<div
							className=''
						>{MUO_str.aad.ab002}
						</div>
					</div>
					<div
						className='stepthree hep100 stepon'
					>
						<div
							className=''
						>{MUO_str.aad.ab003}
						</div>
					</div>
					<div
						className='regis hep100'
					>
						<div
							className='tittle dig fo_sr30 hep100'
						>
							<div
								className=''
							>{MUO_str.aad.ab004}
							</div>
						</div>
						<div
							className='alerts hep100'
						>
							<div
								className='palere10'
							>{MUO_str.aad.ab030} <br/> {MUO_str.aad.ab046}
							</div>
						</div>
						<div
							className='regis_contend hep100'
						>
							<div
								className='regisform'
							>
								<div
									className='formcontend'
								>
									<div className='form_libre hep100'>
									</div>
										{/* <div className='form_contry'>
											<label className='al_sec herr20 wip30' htmlFor='impviii'>{MUO_str.aad.ab047}</label>
											<select
												className='fo_sr11 wip65 her20'
												id='impviii'
												onChange={(e)=>(MUO_fun.validation('bad', e.target.value, 0))}
												value={MUO_str.aac[0].bad}
											>
												<option
													value='0'
													disabled
												>{MUO_str.aad.ab048}</option>
												{MUO_str.aae.map((m, i) =>(
													m.M49_id === '0' ? '' : 
													
													<option 
														key = {i}
														value={m.M49_id}
													>{m.Native_name}</option>
												))}
											</select>
											<div className='her20 ma_lp31 c_red patore03 wip69'>{MUO_str.aac[0].bada ? MUO_str.aac[0].bada :''}</div>
										</div>
										<div className='form_ndoc'>
											<label className='al_sec herr20 wip30' htmlFor='impi'>{MUO_str.aad.ab049}</label>
											<input className='fo_sr11 wip65 her20 tetrup' id='impi' type='text' value={MUO_str.aac[0].bae ? MUO_str.aac[0].bae :''} onChange={(val) => MUO_fun.validation('bae', val.target.value, 0)}/>
											<div className='her20 ma_lp31 c_red patore03 pa_tr03 wip69'>{MUO_str.aac[0].baea ? MUO_str.aac[0].baea :''}</div>
										</div> */}
										<div className='form_id'>
											<label className='al_sec herr20 wip30' htmlFor='impii'>{MUO_str.aad.ab050}</label>
											<input  className='fo_sr11 wip65 her20' id='impii' type='text' value={MUO_str.aac[0].baf ? MUO_str.aac[0].baf :''} onChange={(val) => MUO_fun.validation('baf', val.target.value, 0)}/>
											<div className='her20 ma_lp31 c_red patore03 wip69'>{MUO_str.aac[0].bafa ? MUO_str.aac[0].bafa :''}</div>
										</div>
										<div className='form_pass'>
											<label className='al_sec herr20 wip30'  htmlFor='impiii'>{MUO_str.aad.ab051}</label>
											<input className='fo_sr11 wip65 her20' id='impiii' type='password' value={MUO_str.aac[0].bag ? MUO_str.aac[0].bag :''} onChange={(val) => MUO_fun.validation('bag', val.target.value, 0)}/>
											<div className='her20 ma_lp31 c_red patore03 wip69'>{MUO_str.aac[0].baga ? MUO_str.aac[0].baga :''}</div>
										</div>
										{/* <div className='form_cell'>
											<label className='al_sec herr20 wip30' htmlFor='impvi'>{MUO_str.aad.ab052}</label>
											<input className='fo_sr11 wip65 her20' id='impvi' type='text' value={MUO_str.aac[0].bah ? MUO_str.aac[0].bah :''} onChange={(val) => MUO_fun.validation('bah', val.target.value, 0)}/>
											<div className='her20 ma_lp31 c_red patore03 wip69'>{MUO_str.aac[0].baha ? MUO_str.aac[0].baha :''}</div>
										</div> */}
										<div className='form_corre'>
											<label className='al_sec herr20 wip30' htmlFor='impvii'>{MUO_str.aad.ab053}</label>
											<input className='fo_sr11 wip65 her20' id='impvii' type='text' value={MUO_str.aac[0].bai ? MUO_str.aac[0].bai :''} onChange={(val) => MUO_fun.validation('bai', val.target.value, 0)}/>
											<div className='her20 ma_lp31 c_red patore03 wip69'>{MUO_str.aac[0].baia ? MUO_str.aac[0].baia :''}</div>
										</div>
									</div>
								</div>
							</div>
						<div
							className='next_panel hep100'
						>
							<div
								className='bottons'
							>
								<div
									className='cancel'
									onClick={()=> Auxiliar.modal_control(0, {var1 : 'caa', var2 : 'ccc', e : true})}
									>
									<div
										className='dig jucoce'
									>{MUO_str.aad.ab013}
									</div>
								</div>
								<div
									className='next'
									onClick={MUO_str.aac[0].baa === 1 ? 
										MUO_str.aac[0].bab === 1 ? 
											MUO_str.aac[0].bac === 1 ? 
												()=> MUO_fun.validacion_registro()
											: ()=> Auxiliar.modal_control(0, {var1 : 'cad', var2 : 'ccc', e : true}) 
										: ()=> Auxiliar.modal_control(0, {var1 : 'cad', var2 : 'ccc', e : true}) 
									: ()=> Auxiliar.modal_control(0, {var1 : 'cad', var2 : 'ccc', e : true})}
									><div
										className='dig jucoce'
									>{MUO_str.aad.ab054}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			:''}
			{MUO_str.aac[0].aabd?
				<div
					className='toggled-element-left light register'
				>
					<div
						className='stepone hep100 stepoff'
					>
						<div
							className=''
						>{MUO_str.aad.ab001}
						</div>
					</div>
					<div
						className='steptwo hep100 stepoff'
					>
						<div
							className=''
						>{MUO_str.aad.ab002}
						</div>
					</div>
					<div
						className={MUO_str.aaf.status ? MUO_str.aaf.event === 'MUO_USER_EMPTY' ? 'stepthree hep100 stepgo' : 'stepthree hep100 stepon' : 'stepthree hep100 stepon' }
					>
						<div
							className=''
						>{MUO_str.aad.ab003}
						</div>
					</div>


					<div
						className='regis hep100'
					>
						<div
							className='tittle dig fo_sr30 hep100'
						>
							<div
								className=''
							>{MUO_str.aaf.status ? MUO_str.aaf.event === 'MUO_USER_EMPTY' ? MUO_str.aad.ab063 : MUO_str.aad.ab004 : MUO_str.aad.ab004 }
							</div>
						</div>
						<div
							className='alerts hep100'
						>
							<div
								className='palere10'
							>{MUO_str.aaf.status ? MUO_str.aaf.event === 'MUO_USER_EMPTY' ? <> {MUO_str.aad.ab064} <br/> {MUO_str.aad.ab065}</> : MUO_str.aad.ab069 : MUO_str.aad.ab068 } 
							</div>
						</div>
						<div
							className='regis_contend hep100'
						>
							<div
								className='regisconfirm'
							>
								{MUO_str.aaf.status ? 
									MUO_str.aaf.event === 'MUO_USER_EMPTY' ?
										<div
											className='confirmcontend'
										>
											<div className='confirm_libre hep100'>
											</div>
											<div className='confirm_videopollo hep100'>
												<ReactPlayer 
													url={MUO_str.aac[0].oad}
													playing={true}
													volume={0.800}
													speed={1}
												 />
											</div>
											<div className='confirm_user'>
												<div className='hep100'>{MUO_str.aad.ab055.replace('%u', MUO_str.aaf.msg.db[0][0].memb___id)}</div>
											</div>
											<div className='confirm_pass'>
												<div className='hep100'>{MUO_str.aad.ab051 + ' ' + MUO_str.aaf.msg.db[0][0].memb__pwd}</div>
											</div>
											{/* <div className='confirm_cell'>
												<div className='hep100'>{MUO_str.aad.ab052 + ' ' + MUO_str.aaf.msg.db[0][0].phon_numb}</div>
											</div> */}
											<div className='confirm_corre'>
												<div className='hep100'>{MUO_str.aad.ab053 + ' ' + MUO_str.aaf.msg.db[0][0].mail_addr}</div>
											</div>
											<div className='confirm_data'>
												<div className='hep100'>{MUO_str.aad.ab056}</div>
											</div>
										</div>
									: 
										<div
											className='confirmcontend'
										>
											<div className='confirm_libre hep100'>
											</div>
											<div className='confirm_user'>
												<div className='hep100'></div>
											</div>
											<div className='confirm_pass'>
												<div className='hep100 c_red'>{MUO_str.aad.ab066}</div>
											</div>
											{/* <div className='confirm_cell'>
												<div className='hep100'></div>
											</div> */}
											<div className='confirm_corre'>
												<div className='hep100'></div>
											</div>
											<div className='confirm_data'>
												<div className='hep100'></div>
											</div>
										</div>
								:
									<div
										className='confirmcontend'
									>
										<div className='confirm_libre hep100'>
										</div>
										<div className='confirm_user'>
											<div className='hep100'></div>
										</div>
										<div className='confirm_pass'>
											<div className='hep100 c_red'>{MUO_str.aad.ab067}</div>
										</div>
										{/* <div className='confirm_cell'>
											<div className='hep100'></div>
										</div> */}
										<div className='confirm_corre'>
											<div className='hep100'></div>
										</div>
										<div className='confirm_data'>
											<div className='hep100'></div>
										</div>
									</div>
								}
							</div>
						</div>
						<div
							className='next_panel hep100'
						>
							<div
								className='bottons'
							>
								<div
									className='next'
									onClick={MUO_str.aac[0].baa === 1 ? 
										MUO_str.aac[0].bab === 1 ? 
											MUO_str.aac[0].bac === 1 ? 
												MUO_str.aaf.status ? 
													MUO_str.aaf.event === 'MUO_USER_EMPTY' ? 
														()=> Auxiliar.modal_control(0, {var1 : 'caf', var2 : 'ccc', e : true}) 
													: ()=> MUO_str.increment(2, {sub : 3})
												: ()=> MUO_str.increment(2, {sub : 3})
											: ()=> Auxiliar.modal_control(0, {var1 : 'cad', var2 : 'ccc', e : true}) 
										: ()=> Auxiliar.modal_control(0, {var1 : 'cad', var2 : 'ccc', e : true}) 
									: ()=> Auxiliar.modal_control(0, {var1 : 'cad', var2 : 'ccc', e : true})}
									><div
										className='dig jucoce'
									>{MUO_str.aaf.status ? MUO_str.aaf.event === 'MUO_USER_EMPTY' ? MUO_str.aad.aa009 : MUO_str.aad.ab070 : MUO_str.aad.ab070}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			:''}

 		</>)
	}
}

// ""
//             ""
//             ""
//             ""
//             ""
//             ""
//             ""
//             ""
//             ""
//             "";
export default observer(Register)