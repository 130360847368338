import React 									from 'react'
import { observer }								from 'mobx-react'


import MUO_fun 									from '../js/MUO_fun'
import MUO_str 									from '../storage/MUO_str'

import MUO_APIs									from "../js/MUO_APIs"

import Loading 									from '../loading/loading'


if (!MUO_str.aac[0].oal){
	MUO_str.caa('oal', true)
	MUO_str.increment(1002)
	MUO_str.caa('aada', true)
	
	MUO_APIs.aaa(1, 0, 'MUO_GET_RANKING', MUO_str.aac[0].oac === true ? MUO_str.aac[0].oaka : MUO_str.aac[0].oakb, 'POST', (ra)=>{
		let ps = JSON.parse(ra).data.msg
		MUO_str.cae(ps.da)
		MUO_str.cag(ps.dc)
		MUO_fun.orderranking(ps.da, ps.db, ps.dc).then((a) => {
			// console.log(a, 'server')
			// {to=`shop`}
			MUO_str.caa('oal', false)
			MUO_str.increment(1002)
			MUO_str.caa('aadb', true)
		}).catch((e) => {
			console.log(e, 'Connection Error')
		})
	}).catch((e)=>{
		console.log('error API MUO_GET_RANKING', e)
	})
}


class Ranking extends React.Component {
	
	
	render () {
		return(<>
		{MUO_str.aac[0].aada?
			<Loading />
		: ''}
		{MUO_str.aac[0].aadb?
			<div
				className='toggled-element-left light ranking'
			>
				<div
					className='rank_servers hep100'
				>
					{MUO_str.aag.map((m, i) =>(
							<div
								key={m.id_server}
								className={m.active === 1? 'orange dig hep100 jucoce alcoce' : 'gray dig hep100 jucoce alcoce'}
								onClick={()=> MUO_str.cah(m)}
							>
								<div
									className=''
								>
									{m.server_name}
								</div>
							</div>
					))}
				</div>
				<div
					className='rank_type hep100'
				>
					<div
						className={MUO_str.aac[0].baj === 0? 'orange dig hep100 jucoce alcoce' : 'gray dig hep100 jucoce alcoce'}
						onClick={()=> MUO_str.caa('baj', 0)}
					>
						<div
							className=''
						>
							{MUO_str.aad.ad001}
						</div>
					</div>
					<div
						className={MUO_str.aac[0].baj === 1? 'orange dig hep100 jucoce alcoce' : 'gray dig hep100 jucoce alcoce'}
						onClick={()=> MUO_str.caa('baj', 1)}
					>
						<div
							className=''
						>
							{MUO_str.aad.ad002}
						</div>
					</div>
				</div>
				<div
					className='rank_winner_contend'
				>
					<div
						className='rank_winner'
					>
						<div
							className='winner_cup'
						>
							<div
								className='winner_cup_img'
							>
							</div>
						</div>
						<div
							className='winner_class'
						>	
							{MUO_str.aac[0].baj === 0 ? // gens
								MUO_str.aah.db.map((p, i) =>(
									MUO_str.aac[0].bak === 1?
										MUO_str.aac[0].baka.Name === p.Name?
											<div
												key={p.Class}
												className={'winner_class_img  clasimg' + p.Class }
											>
											</div>
										:''
									:
										MUO_str.aac[0].bakb.Name === p.Name?
											<div
												key={p.Class}
												className={'winner_class_img  clasimg' + p.Class }
											>
											</div>
										:'' 
								))
							:
								<div
									className={'winner_class_img  clasimg' + MUO_str.aac[0].bakc.Class }
								>
								</div>
							}
						</div>
						<div
							className='winner_name_class_contend'
							
						>
							<div
								className='winner_name_class'
							>
								<div
									className='pj_namet'
								>
									{MUO_str.aad.ad003}
								</div>
								<div
									className='pj_name'
								>
									{MUO_str.aac[0].baj === 0 ? // gens
										MUO_str.aac[0].bak === 1?
											MUO_str.aac[0].baka.Name
										:
											MUO_str.aac[0].bakb.Name
									:
										MUO_str.aac[0].bakc.Name
									}
								</div>
								<div
									className='pj_classt'
								>
									{MUO_str.aad.ad004}
								</div>
								<div
									className='pj_class'
								>	
									{MUO_str.aac[0].baj === 0 ? // gens
										MUO_str.aah.db.map((p, i) =>(
											MUO_str.aac[0].bak === 1?
												MUO_str.aac[0].baka.Name === p.Name?
													MUO_fun.classcharacter(p.Class)
												:''
											:
												MUO_str.aac[0].bakb.Name === p.Name?
													MUO_fun.classcharacter(p.Class)
												:'' 
										))
									:
										MUO_fun.classcharacter(MUO_str.aac[0].bakc.Class)
												
									
									
									
									// MUO_str.aah.db.map((m, i) =>(
									// 	m.Name === MUO_str.aai[0].Name? 
									// 		MUO_fun.classcharacter(m.Class)
									// 	:''
									// ))
									
									
									}
								</div>
							</div>
						</div>
						<div
							className='winner_gens_contend'
						>
							<div
								className='winner_gens'
							>
								<div
									className='g_emblemt'
								>
									{MUO_str.aad.ad005}
								</div>
								<div
									className={
										MUO_str.aac[0].baj === 0 ? // gens
											MUO_str.aac[0].bak === 1?
												'g_emblem gensimg' + MUO_str.aac[0].bak + MUO_str.aac[0].baka.Class
											:
												'g_emblem gensimg' + MUO_str.aac[0].bak + MUO_str.aac[0].bakb.Class 
										: 
											MUO_fun.tituloreset()
									}
									
									
									
									// {'g_emblem gensimg' + MUO_str.aac[0].bak + MUO_str.aai[0].Class}
								>
									
								</div>
								<div
									className='g_pointst'
								>
									{MUO_str.aad.ad006}
								</div>
								<div
									className='g_points'
								>	
									{MUO_str.aac[0].baj === 0 ? // gens
										MUO_str.aac[0].bak === 1?
											MUO_str.aac[0].baka.Points
										:
											MUO_str.aac[0].bakb.Points
									: MUO_fun.pointsreset()}
								</div>
							</div>
						</div>
						<div
							className='winner_quarrel_contend'
						>
							<div
								className='winner_quarrel'
							>
								{MUO_str.aac[0].baj === 0 ? // gens
									<>
										<div
											className={MUO_str.aac[0].bak === 1? 'quarrel_d gensimg11 light' : 'quarrel_d gensimg11 cupo gray'}
											onClick={MUO_str.aac[0].bak === 1? console.log('') : ()=> MUO_str.caa('bak', 1)}
										>
											<div
												className={MUO_str.aac[0].bak === 1? 'c_gray' : 'c_light'}
											>
												{MUO_str.aad.ad009}
											</div>
										</div>
										<div
											className={MUO_str.aac[0].bak === 2? 'quarrel_v gensimg21 light' : 'quarrel_v gensimg21 cupo gray'}
											onClick={MUO_str.aac[0].bak === 2? console.log('') : ()=> MUO_str.caa('bak', 2)}
										>	
											<div
												className={MUO_str.aac[0].bak === 2? 'c_gray' : 'c_light'}
											>
												{MUO_str.aad.ad010}
											</div>
										</div>
									</>
										
								:
									<>
										<div
											className={'quarrel_d'}
										>
											<div
												className='class_select'
											>
												<div
													className='class_selectimg cupo clasimg0'
													onClick={()=> MUO_str.caa('bakd', 'aa')}
												>
													
												</div>
												<div
													className='class_selectimg cupo clasimg16'
													onClick={()=> MUO_str.caa('bakd', 'ab')}
												>
													
												</div>
												<div
													className='class_selectimg cupo clasimg32'
													onClick={()=> MUO_str.caa('bakd', 'ac')}
												>
													
												</div>
												<div
													className='class_selectimg cupo clasimg48'
													onClick={()=> MUO_str.caa('bakd', 'ad')}
												>
													
												</div>
												<div
													className='class_selectimg cupo clasimg64'
													onClick={()=> MUO_str.caa('bakd', 'ae')}
												>
													
												</div>
												<div
													className='class_selectimg cupo clasimg80'
													onClick={()=> MUO_str.caa('bakd', 'af')}
												>
													
												</div>
												<div
													className='class_selectimg cupo clasimg96'
													onClick={()=> MUO_str.caa('bakd', 'ag')}
												>
													
												</div>
												<div
													className='class_selectimg cupo clasimg112'
													onClick={()=> MUO_str.caa('bakd', 'ah')}
												>
													
												</div>
												<div
													className='class_selectimg cupo clasimg128'
													onClick={()=> MUO_str.caa('bakd', 'ai')}
												>
													
												</div>
												<div
													className='class_selectimg cupo clasimg144'
													onClick={()=> MUO_str.caa('bakd', 'aj')}
												>
													
												</div>
												<div
													className='class_selectimg cupo clasimg160'
													onClick={()=> MUO_str.caa('bakd', 'ak')}
												>
													
												</div>
												<div
													className='class_selectimg cupo clasimg176'
													onClick={()=> MUO_str.caa('bakd', 'al')}
												>
													
												</div>
												<div
													className='class_selectimg cupo clasimg192'
													onClick={()=> MUO_str.caa('bakd', 'am')}
												>
													
												</div>
												<div
													className='class_selectimg cupo clasimg208'
													onClick={()=> MUO_str.caa('bakd', 'an')}
												>
													
												</div>
												<div
													className='class_selectall c_red'
													onClick={()=> MUO_str.caa('bakd', 'db')}
												>?
													
												</div>
												
											</div>
										</div>
										<div
											className={'quarrel_v'}
										>	
											<div
												className='subclass_select'
											>
												{MUO_str.aac[0].bakd === 'db'? '' :
													MUO_str.aac[0].bakd === 'aa' || MUO_str.aac[0].bakd === 'aaa' || MUO_str.aac[0].bakd === 'aab' || MUO_str.aac[0].bakd === 'aac' || MUO_str.aac[0].bakd === 'aad' ?
														<>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'aaa')}
															>
																<div
																>
																	{MUO_str.aad.ad013}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'aab')}
															>
																<div
																>
																	{MUO_str.aad.ad014}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'aac')}
															>
																<div
																>
																	{MUO_str.aad.ad015}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'aad')}
															>
																<div
																>
																	{MUO_str.aad.ad016}
																</div>
															</div>
														</>
													: MUO_str.aac[0].bakd === 'ab' || MUO_str.aac[0].bakd === 'aba' || MUO_str.aac[0].bakd === 'abb' || MUO_str.aac[0].bakd === 'abc' || MUO_str.aac[0].bakd === 'abd' ?
														<>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'aba')}
															>
																<div
																>
																	{MUO_str.aad.ad017}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'abb')}
															>
																<div
																>
																	{MUO_str.aad.ad018}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'abc')}
															>
																<div
																>
																	{MUO_str.aad.ad019}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'abd')}
															>
																<div
																>
																	{MUO_str.aad.ad020}
																</div>
															</div>
														</>
													: MUO_str.aac[0].bakd === 'ac' || MUO_str.aac[0].bakd === 'aca' || MUO_str.aac[0].bakd === 'acb' || MUO_str.aac[0].bakd === 'acc' || MUO_str.aac[0].bakd === 'acd' ?
														<>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'aca')}
															>
																<div
																>
																	{MUO_str.aad.ad021}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'acb')}
															>
																<div
																>
																	{MUO_str.aad.ad022}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'acc')}
															>
																<div
																>
																	{MUO_str.aad.ad023}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'acd')}
															>
																<div
																>
																	{MUO_str.aad.ad024}
																</div>
															</div>
														</>
													: MUO_str.aac[0].bakd === 'ad' || MUO_str.aac[0].bakd === 'ada' || MUO_str.aac[0].bakd === 'adb' || MUO_str.aac[0].bakd === 'adc' || MUO_str.aac[0].bakd === 'add' ?
														<>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'ada')}
															>
																<div
																>
																	{MUO_str.aad.ad025}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'adb')}
															>
																<div
																>
																	{MUO_str.aad.ad026}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'adc')}
															>
																<div
																>
																	{MUO_str.aad.ad027}
																</div>
															</div>
														</>
													: MUO_str.aac[0].bakd === 'ae' || MUO_str.aac[0].bakd === 'aea' || MUO_str.aac[0].bakd === 'aeb' || MUO_str.aac[0].bakd === 'aec' || MUO_str.aac[0].bakd === 'aed' ?
														<>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'aea')}
															>
																<div
																>
																	{MUO_str.aad.ad028}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'aeb')}
															>
																<div
																>
																	{MUO_str.aad.ad029}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'aec')}
															>
																<div
																>
																	{MUO_str.aad.ad030}
																</div>
															</div>
														</>
													:  MUO_str.aac[0].bakd === 'af' || MUO_str.aac[0].bakd === 'afa' || MUO_str.aac[0].bakd === 'afb' || MUO_str.aac[0].bakd === 'afc' || MUO_str.aac[0].bakd === 'afd' ?
														<>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'afa')}
															>
																<div
																>
																	{MUO_str.aad.ad031}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'afb')}
															>
																<div
																>
																	{MUO_str.aad.ad032}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'afc')}
															>
																<div
																>
																	{MUO_str.aad.ad033}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'afd')}
															>
																<div
																>
																	{MUO_str.aad.ad034}
																</div>
															</div>
														</>
													: MUO_str.aac[0].bakd === 'ag' || MUO_str.aac[0].bakd === 'aga' || MUO_str.aac[0].bakd === 'agb' || MUO_str.aac[0].bakd === 'agc' || MUO_str.aac[0].bakd === 'agd' ?
														<>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'aga')}
															>
																<div
																>
																	{MUO_str.aad.ad035}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'agb')}
															>
																<div
																>
																	{MUO_str.aad.ad036}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'agc')}
															>
																<div
																>
																	{MUO_str.aad.ad037}
																</div>
															</div>
														</>
													: MUO_str.aac[0].bakd === 'ah' || MUO_str.aac[0].bakd === 'aha' || MUO_str.aac[0].bakd === 'ahb' || MUO_str.aac[0].bakd === 'ahc' || MUO_str.aac[0].bakd === 'ahd' ?
														<>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'aha')}
															>
																<div
																>
																	{MUO_str.aad.ad038}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'ahb')}
															>
																<div
																>
																	{MUO_str.aad.ad039}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'ahc')}
															>
																<div
																>
																	{MUO_str.aad.ad040}
																</div>
															</div>
														</>
													:  MUO_str.aac[0].bakd === 'ai' || MUO_str.aac[0].bakd === 'aia' || MUO_str.aac[0].bakd === 'aib' || MUO_str.aac[0].bakd === 'aic' || MUO_str.aac[0].bakd === 'aid' ?
														<>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'aia')}
															>
																<div
																>
																	{MUO_str.aad.ad041}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'aib')}
															>
																<div
																>
																	{MUO_str.aad.ad042}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'aic')}
															>
																<div
																>
																	{MUO_str.aad.ad043}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'aid')}
															>
																<div
																>
																	{MUO_str.aad.ad044}
																</div>
															</div>
														</>
													: MUO_str.aac[0].bakd === 'aj' || MUO_str.aac[0].bakd === 'aja' || MUO_str.aac[0].bakd === 'ajb' || MUO_str.aac[0].bakd === 'ajc' || MUO_str.aac[0].bakd === 'ajd' ?
														<>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'aja')}
															>
																<div
																>
																	{MUO_str.aad.ad045}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'ajb')}
															>
																<div
																>
																	{MUO_str.aad.ad046}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'ajc')}
															>
																<div
																>
																	{MUO_str.aad.ad047}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'ajd')}
															>
																<div
																>
																	{MUO_str.aad.ad048}
																</div>
															</div>
														</>
													: MUO_str.aac[0].bakd === 'ak' || MUO_str.aac[0].bakd === 'aka' || MUO_str.aac[0].bakd === 'akb' || MUO_str.aac[0].bakd === 'akc' || MUO_str.aac[0].bakd === 'akd' ?
														<>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'aka')}
															>
																<div
																>
																	{MUO_str.aad.ad049}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'akb')}
															>
																<div
																>
																	{MUO_str.aad.ad050}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'akc')}
															>
																<div
																>
																	{MUO_str.aad.ad051}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'akd')}
															>
																<div
																>
																	{MUO_str.aad.ad052}
																</div>
															</div>
														</>
													: MUO_str.aac[0].bakd === 'al' || MUO_str.aac[0].bakd === 'ala' || MUO_str.aac[0].bakd === 'alb' || MUO_str.aac[0].bakd === 'alc' || MUO_str.aac[0].bakd === 'ald' ?
														<>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'ala')}
															>
																<div
																>
																	{MUO_str.aad.ad053}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'alb')}
															>
																<div
																>
																	{MUO_str.aad.ad054}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'alc')}
															>
																<div
																>
																	{MUO_str.aad.ad055}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'ald')}
															>
																<div
																>
																	{MUO_str.aad.ad056}
																</div>
															</div>
														</>
													: MUO_str.aac[0].bakd === 'am' || MUO_str.aac[0].bakd === 'ama' || MUO_str.aac[0].bakd === 'amb' || MUO_str.aac[0].bakd === 'amc' || MUO_str.aac[0].bakd === 'amd' ?
														<>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'ama')}
															>
																<div
																>
																	{MUO_str.aad.ad057}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'amb')}
															>
																<div
																>
																	{MUO_str.aad.ad058}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'amc')}
															>
																<div
																>
																	{MUO_str.aad.ad059}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'amd')}
															>
																<div
																>
																	{MUO_str.aad.ad060}
																</div>
															</div>
														</>
													: MUO_str.aac[0].bakd === 'an' || MUO_str.aac[0].bakd === 'ana' || MUO_str.aac[0].bakd === 'anb' || MUO_str.aac[0].bakd === 'anc' || MUO_str.aac[0].bakd === 'and' ?
														<>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'ana')}
															>
																<div
																>
																	{MUO_str.aad.ad061}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'anb')}
															>
																<div
																>
																	{MUO_str.aad.ad062}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'anc')}
															>
																<div
																>
																	{MUO_str.aad.ad063}
																</div>
															</div>
															<div
																className='subclass_button cupo'
																onClick={()=> MUO_str.caa('bakd', 'and')}
															>
																<div
																>
																	{MUO_str.aad.ad064}
																</div>
															</div>
														</>
													: ''


												}
												
											</div>
										</div>
									</>
								
								
								
								}
								
								
							</div>
						</div>

					</div>

				</div>
				<div
					className='rank_search'
				>
					
				</div>
				<div
					className='rank_details'
				>
					<div
						className='rank_details_titles'
					>
						<div
							className='rank_details_titles_rank'
						>
							{MUO_str.aad.ad007}
						</div>
						<div
							className='rank_details_titles_title'
						>
							{MUO_str.aac[0].baj === 0 ? MUO_str.aad.ad005 : MUO_str.aad.ad002}
						</div>
						<div
							className='rank_details_titles_name'
						>
							{MUO_str.aad.ad003}
						</div>
						<div
							className='rank_details_titles_class'
						>
							{MUO_str.aad.ad004}
						</div>
						<div
							className='rank_details_titles_clan'
						>
							{MUO_str.aac[0].baj === 0 ? MUO_str.aad.ad008 : MUO_str.aad.ad011}
						</div>
						<div
							className='rank_details_titles_points'
						>
							{MUO_str.aac[0].baj === 0 ? MUO_str.aad.ad006 : MUO_str.aad.ad012}
						</div>
						
					</div>
					<div
						className='rank_details_body_contend'
					>
						{MUO_str.aac[0].baj === 0 ? //gens
							MUO_str.aai.map((m, i) =>(
								m.Influence ===  MUO_str.aac[0].bak?
									<div
										key={m.Rank}
										className='rank_details_body'
									>
										<div
											className={m.Rank === 1? 'rank_details_body_rank hep100 classrank' + 1 : m.Rank === 2? 'rank_details_body_rank hep100 classrank' + 2 : m.Rank === 3? 'rank_details_body_rank hep100 classrank' + 3 : 'rank_details_body_rank'}
										>
											{m.Rank === 1 || m.Rank === 2 || m.Rank === 3? '' : m.Rank}
										</div>
										<div
											className={'rank_details_body_title hep100 gensimg' + m.Influence + m.Class}
										>
										</div>
										<div
											className='rank_details_body_name'
										>
											{m.Name}
										</div>
										<div
											className='rank_details_body_class'
										>
											{MUO_str.aah.db.map((mm, i) =>(
												mm.Name === m.Name?
													MUO_fun.classcharacter(mm.Class)
												:''
											))}
										</div>
										<div
											className='rank_details_body_clan'
										>
											{/* falta traer datos de clan */}
										</div>
										<div
											className='rank_details_body_points'
										>
											{m.Points}
										</div>	
									</div>
								:''
							))

						: // rese
							// console.log(MUO_str.aac[0].bakd)
							// console.log(MUO_str.aah['' +MUO_str.aac[0].bakd + ''])
							MUO_str.aah[''+ MUO_str.aac[0].bakd+''].map((m, i) =>(
								<div
									key={i}
									className='rank_details_body'
								>
									<div
										className={i + 1 === 1? 'rank_details_body_rank hep100 classrank' + 1 : i + 1 === 2? 'rank_details_body_rank hep100 classrank' + 2 : i + 1 === 3? 'rank_details_body_rank hep100 classrank' + 3 : 'rank_details_body_rank'}
									>
										{i + 1 === 1 || i + 1 === 2 || i + 1 === 3? '' : i + 1}
									</div>
									<div
										className='rank_details_body_title'
									>
										{m.RESETS}
									</div>
									<div
										className='rank_details_body_name'
									>
										{m.Name}
									</div>
									<div
										className='rank_details_body_class'
									>
										{MUO_fun.classcharacter(m.Class)}
									</div>
									<div
										className='rank_details_body_clan'
									>
										{m.cLevel}
									</div>
									<div
										className='rank_details_body_points'
									>
										{m.mLevel}
									</div>	
								</div>
							))
						}

					</div>
				</div>
			</div>
		: ''}

 		</>)
	}
}

// ""
//             ""
//             ""
//             ""
//             ""
//             ""
//             ""
//             ""
//             ""
//             "";
export default observer(Ranking)