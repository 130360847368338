import MUO_str						from "../storage/MUO_str"
import dateFormat 						from 'dateformat'
// import fetch 							from 'node-fetch';

class MUO_APIs {
	async aaa(a, parametros, evento, dominio, methodo, c ){
		let data
		if (a === 1){
			data = JSON.stringify({
				mm 		: '19',
				token	: 			MUO_str.aac[0].oaa,
				payload	: 	{
					event	: evento,
					data	: parametros
				}
			})
		}
		try{
			var myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/json");
			myHeaders.append("Cookie", "connect.sid=s%3A68ohz1HldiZ1O6HsIbFT9CbN40P5_eLG.qeQp6pYDYsVurEjlNI7mn8LDRMq0Mw%2BJ6uti6tGqTkY");
			// console.log(data, 'fffffffffffff ks')
			var requestOptions = {
				method: methodo,
				headers: myHeaders,
				body: data,
				redirect: 'follow'
			}
			fetch(dominio, requestOptions)
			.then(response => response.text())
			.then(result => c(result))
			.catch(error => console.log('error', error))
		} catch(e){
			console.log(e)
		}
	}
	async aab(a, b, dominio, methodo, c ){
		
		
		let data
		var myHeaders = new Headers();
		if (a === 1){
			// console.log(a,b,dominio, methodo);  return
			data = JSON.stringify(b)
			
		}

		if (a === 2){
			dominio = dominio + b.a + '/'
			// console.log(localStorage.getItem('munistoken'))
			myHeaders.append("Authorization", "Bearer " + localStorage.getItem('munistoken'));

			// myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjc5OTUyMzI0LCJpYXQiOjE2NzkwODgzMjQsImp0aSI6IjRjMGU5ODhjNThkYjQ3MTBiY2E2MDFiNmFkMDc5MWYyIiwidXNlcl9pZCI6NX0.n9Mm2Q39TJTmjmBUU5-knm39y0yhRzCliod6pIqFlu4");

			data = JSON.stringify({
				status: 0,
				description: b.b
			})
		}
		if (a === 3){
			myHeaders.append("Authorization", "Bearer " + localStorage.getItem('munistoken'));
		}

		try{
			
			myHeaders.append("Content-Type", "application/json");
			myHeaders.append("Cookie", "connect.sid=s%3ABodFCzxh1iIHEHCQSwJiKh_wNeBPBJSY.KJMEGYo0rN5diMfDvo9ZJIkO2%2FmmUurKYjGim1lSMzQ");
			// console.log(data, 'fffffffffffff ks')
			if (methodo === 'POST') {
				var requestOptions = {
				method: methodo,
				headers: myHeaders,
				body: data,
				redirect: 'follow'
				};
			}
			if (methodo === 'GET') {
				var requestOptions = {
				method: methodo,
				headers: myHeaders,
				redirect: 'follow'
				};
			}

			fetch(dominio, requestOptions)
			.then(response => response.text())
			.then(result => c(result))

			.catch(error => console.log('error', error))

		


		} catch(e){
			console.log(e)
		}



	}
	
	


}
export default new MUO_APIs();