import React 									from 'react'
import { observer }								from 'mobx-react'





import '../../../css/sections.css'
import '../../../css/globales.css'
import '../../../css/tree.css'
//import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import '../../../fnt/style.css'

let aaa
class Muapp_error extends React.Component {
	componentDidMount() {
		if (aaa) {
			// already mounted previously
			return;
		}
	
	}


	render(){
		return (<>
		{
			<div
				className="ctn-MW us_se_n ov1 hep100 lo_error"
			>
			 <header>
			 </header>
			 <main className='black sb_y1'>
			 </main>
		 </div>

		}
		</>)

	}
}
export default observer(Muapp_error)