import { makeObservable, observable, action } from 'mobx'
import Eventscolas							from '../../../fun/js'
import dateFormat 							from 'dateformat'
import Auxiliar								from "../../../fun/fun"

import MUO_APIs								from "../js/MUO_APIs"
import MUO_fun 								from '../js/MUO_fun'

import {Link} 						from "react-router-dom"



class RapidStore {
	aaa = 'eng'
	aab = [
		{
			iso6391 : 'ar',
			iso6392 : 'ara',
			nombre	: 'Árabe',
			active	: false
		},
		{
			iso6391 : 'zh',
			iso6392 : 'zho',
			nombre	: 'Chino',
			active	: false
		},
		{
			iso6391 : 'en', 
			iso6392 : 'eng',
			nombre	: 'Inglés',
			active	: true,
			//start
			aa001	: 'SG',
			aa002	: 'Games',
			aa003	: 'Register',
			aa004	: 'Login',
			aa005	: 'News',
            aa006   : 'Guide',
            aa007   : 'Board',
            aa008   : 'Ranking',
            aa009   : 'Download',
            aa010   : 'Faq',
            aa011   : 'Shop',
			// register
			ab001	: 'Policies, Terms and Conditions',
			ab002	: 'Identification',
			ab003	: 'Register',
			ab004	: 'Register an account',
			ab005	: '•	To register as a member of MU-Peruano Origgen, read and accept the Terms of Use and Privacy and Cookies Policy of MU-Peruano Origgen.',
			ab006	: '•	This service can only be registered and used by people over 13 years of age.',
			ab007	: '•	If you agree to use MU-Peruano Origgen',
			ab008	: 'I accept the Terms of use of the game.',
			ab009	: 'By registering as a member, you accept each of the points of the MU-Peruano Origgen Privacy and Cookies Policy.',
			ab010	: 'The retention period of personal information will be equal to the notification period of its deletion, the collection and use of personal information is for exclusive use for the MU-Peruano Origgen game service, and if your account is not used for a period certain period of time in accordance with the Terms of Use, the service is terminated and they are deleted.',
			ab011	: 'I agree to use the game MU-Peruano Origgen.',
			ab012	: 'Next',
			ab013	: 'Cancel',
			ab014	: 'Information on identity verification to protect personal information.',
			ab015	: '- When you click the "Next" button, we will proceed to authenticate and verify your identity. Please see the instructions below.',
			ab016	: '- ServeGamnes, will apply an identity verification system to protect valuable personal information and prevent damage from theft of personal information.',
			ab017	: '1.	All information entered when verifying your identity will be used for identity verification purposes only and there is no fear of it being exposed to the outside world due to full encryption, so please use it with peace of mind.',
			ab018	: '2.	When verifying your identity, you will need to enter the same information as your name.',
			ab019	: '3.	When checking your mobile phone, make sure it is the owner of the mobile phone line.',
			ab020	: 'http://global.servegames.net/PUBLIC/DOCX/Politica_de_privacidad_y_de_cookies_de_MUo.pdf',
			ab021	: 'http://global.servegames.net/PUBLIC/DOCX/Terminos_de_uso_de_MUo.pdf',
			ab022	: 'To access the MU-Peruano Origgen server an account is required, do you want to continue to create one?',
			ab023	: 'Important',
			ab024	: 'Continue',
			ab025	: 'MU-Peruano Origgen terms of use',
			ab026	: 'MU-Peruano Origgen privacy and cookie policy',
			ab027	: 'It is necessary to accept the terms of use of the game',
			ab028	: 'To register as a member, it is important to accept each of the points of the MU-Peruano Origgen Privacy and Cookies Policy',
			ab029	: 'If your intention is to use the game MU-Peruano Origgen you need to accept that condition',
			ab030	: 'Fill out each of the following fields and then click on the send button.',
			ab031	: 'To access the MU-Peruano Origgen online game you must accept the terms, policies and conditions.',
			ab032	: 'Choose your country of origin.',
			ab032 	: 'Choose your country of origin.',
			ab033 	: 'Country Code contains letters',
			ab034	: 'D.N.I. field. empty',
			ab035	: 'D.N.I. field. Incomplete',
			ab036	: 'If your number is complete, skip the message',
			ab037 	: 'Empty ID field',
			ab038 	: 'ID cannot be less than 6 characters',
			ab039 	: 'Empty password field',
			ab040 	: 'password cannot be less than 10 characters',
			ab041 	: 'Empty cell phone number field',
			ab042 	: 'Incomplete cell phone number',
			ab043 	: 'Cell Number has letters',
			ab044 	: 'Empty email field',
			ab045 	: 'Incorrect email format',
			ab046	: 'D.N.I. (National Identity Document or equivalent in your country.)',
			ab047	: 'Country:',
			ab048	: 'Choose Country of Residence',
			ab049	: 'D.N.I. :',
			ab050 	: 'ID :',
			ab051 	: 'Password :',
			ab052 	: 'Cell Number:',
			ab053 	: 'Email:',
			ab054 	: 'Send',
			ab055	: 'Excellent %u you already have an account to enter MU-Peruano Origgen',
			ab056	: 'Share with your friends to make this community bigger',
			ab057 	: 'When downloading the MU-Peruano Origgen client, at least %i of free hard disk space is required. Be sure to check the capacity before installation.',
			ab058 	: 'Capacity when installed %i.',
			ab059 	: 'Update date 2024-02-18.',
			ab060 	: 'How to uninstall: Windows > Control Panel > Add or Remove Programs > MUO-Installer.',
			ab061 	: 'Loading...',
			ab062 	: 'Download the client from here estimated installer weight %i.',
			ab063 	: 'Account Created successfully',
			ab064 	: 'Download the game client to join this community.',
			ab065 	: 'Write down and save your data in a safe place that only you have access to.',
			ab066	: 'This account ID is already registered by another user.',
			ab067 	: 'Error try another ID.',
			ab068	: 'Internal error',
			ab069	: 'ID in use',
			ab070	: 'Retry',
			// ranking
			ad001	: 'Gens',
			ad002	: 'Reset',
			ad003	: 'Name',
			ad004	: 'Class',
			ad005	: 'Title',
            ad006	: 'Points',
            ad007	: 'Top',
            ad008	: 'Clan',
            ad009	: 'Duprian',
            ad010	: 'Valiant',
            ad011 	: 'Level',
            ad012 	: 'Master level',
			ad013	: 'DW',
            ad014	: 'SM',
            ad015	: 'GM',
            ad016	: 'SW',
			ad017	: 'DK',
            ad018	: 'BK',
            ad019	: 'BM',
            ad020	: 'DKN',
			ad021	: 'FE',
            ad022	: 'ME',
            ad023	: 'HE',
            ad024	: 'NE',
            ad025	: 'MG',
            ad026	: 'DM',
            ad027	: 'MK',
            ad028	: 'DL',
            ad029	: 'LE',
            ad030	: 'EL',
            ad031	: 'S',
            ad032	: 'BS',
            ad033	: 'DMA',
            ad034	: 'DS',
            ad035	: 'RF',
            ad036	: 'FM',
            ad037	: 'FB',
            ad038	: 'GL',
            ad039	: 'ML',
            ad040	: 'SL',
            ad041	: 'RW',
            ad042	: 'RSM',
            ad043	: 'GRU',
            ad044	: 'MRW',
            ad045	: 'SL',
            ad046	: 'RS',
            ad047	: 'MS',
            ad048	: 'SLA',
            ad049	: 'GC',
            ad050	: 'GB',
            ad051	: 'MGB',
            ad052	: 'HGC',
            ad053	: 'LW',
            ad054	: 'LM',
            ad055	: 'SWI',
            ad056	: 'LWI',
            ad057	: 'LMA',
            ad058	: 'W',
            ad059	: 'A',
            ad060	: 'MM',
            ad061	: 'IK',
            ad062	: 'MKN',
            ad063	: 'IM',
            ad064	: 'MKNI',
			//loggin
			ae001 	: 'Enter an email for your session.', // libre 
			ae002 	: 'Invalid email format.',// libre 
			ae003 	: 'Enter your password.',// libre 
			ae004 	: 'ID and password.', 
			ae005 	: 'They do not match.', 
			ae006 	: 'Incorrect user password.',// libre 
			ae007 	: 'consult your administrator.',// libre 
			ae008 	: 'Account blocked.', 
			ae009 	: 'You can appeal in FAQ', 
			ae010 	: 'Your account license has expired.',// libre 
			ae011 	: 'ID',
			ae012 	: 'Password',
			ae013 	: 'Remember',
			ae014	: 'Wrong ID',
			ae015	: 'Wrong PW',
			ae016 	: 'Recover password',
			ae017 	: 'First log in',
			ae018	: 'Log out',
			//shop
			af001 	: 'Popular for everyone',
			af002 	: 'Packages',
			af003 	: 'Individual',
			af004 	: 'You have chosen to donate to the server in return we will send you %i ',
			af005 	: 'Item code %i ',
			af006 	: 'Donation $ %i ',
			af007	: 'Thank you, your contribution allows us to improve this community, check out your Gremory Case and recommends MU-Peruano Origgen',
			af008	: 'This item requires you to choose which character will use it.',
			af009	: 'Available only for Peru, make your donation by attaching the item code and your ID in the message. example: MUO000001 MyGameId.',
			af010	: 'Excellent, the set for the character you have chosen was sent to the Gremory Case, enjoy MU-Peruano Origgen',
			af011	: 'You have reached the maximum limit of chick sets for your account; maximum 3',
			af012	: 'Thanks to your contribution it allows us to improve this community, recommends MU-Peruano Origgen',
			af013	: '1',
			af014	: '2',
			af015	: '3',
			af016	: '4',
			af017	: '5',
			af018	: '6',
			af019	: '7',
			af021	: '8',
			af021	: '9',
			af022	: '0',
			af023	: 'Start your session',
			af024	: 'Donate to the server to keep this community active',



			afa00001 : 'ZEN Coupon',
			afb00001 : '2000 millions ZEN',
			afa00002	: 'Set Blood Angel',
			afb00002 : 'Full armor + 15 full options, Excellent and Anciend',
			afa00003 : 'Im Chick Chicken',
			afb00003 : 'Complete starter armor + 15, luck, Excellent and option 28',
			afa00004 : 'VIP Pass',
            afb00004: 'Access to Silent map (VIP) in which there are exclusive areas to level up, shops with exclusive items, and greater exp.',
			
		},
		{
			iso6391 : 'fr',
			iso6392 : 'fra',
			nombre	: 'Francés',
			active	: false
		},
		{
			iso6391 : 'de',
			iso6392 : 'deu',
			nombre	: 'Alemán',
			active	: false
		},
		{
			iso6391 : 'hi',
			iso6392 : 'hin',
			nombre	: 'Hindi',
			active	: false
		},
		{
			iso6391 : 'ja',
			iso6392 : 'jpn',
			nombre	: 'Japonés',
			active	: false
		},
		{
			iso6391 : 'ko',
			iso6392 : 'kor',
			nombre	: 'Corea',
			active	: false
		},
		{
			iso6391 : 'pt',
			iso6392 : 'por',
			nombre	: 'Portugués',
			active	: true,
			//começar
			aa001	: 'SG',
			aa002	: 'Juegos',
			aa003	: 'Registrador',
			aa004	: 'Iniciar sessão',
			aa005	: 'Notícias',
			aa006	: 'Guia',
			aa007	: 'Tablero',
			aa008	: 'Classificação',
			aa009	: 'Descargar',
			aa010	: 'Consultas',
			aa011	: 'Tienda',
			// registro
			ab001	: 'Políticas, Termos e Condições',
			ab002	: 'Identificação',
			ab003	: 'Registrador',
			ab004	: 'Registrar uma conta',
			ab005	: '• Para registrar-se como membro da MU-Peruano Origgen, leia e aceite os Termos de uso e Política de privacidade e cookies da MU-Peruano Origgen.',
			ab006	: '• Este serviço só pode ser registrado e utilizado por pessoas maiores de 13 anos.',
			ab007	: '• Si aceitas usar MU-Peruano Origgen',
			ab008	: 'Aceito os termos de uso do jogo.',
			ab009	: 'Al registrarme como miembro, aceite cada um dos pontos da Política de privacidade e dos cookies de MU-Peruano Origgen.',
			ab010	: 'O período de retenção de informações pessoais será igual ao período de notificação da eliminação do mismo, da coleta e do uso, das informações pessoais são de uso exclusivo para o serviço do jogo MU-Peruano Origgen, e se sua conta não se utiliza durante um determinado período de tempo de acordo com os Termos de Uso, se o serviço for concluído e eliminado.',
			ab011	: 'Aceito usar o jogo MU-Peruano Origgen.',
			ab012	: 'Próximo',
			ab013	: 'Cancelar',
			ab014	: 'Informações sobre verificação de identidade para proteger informações pessoais.',
			ab015	: '- Quando você clicar no botão "Siguiente", procederemos à autenticação e verificaremos sua identidade. Por favor, consulte as instruções a seguir.',
			ab016	: '- ServeGamnes, aplica um sistema de verificação de identidade para proteger informações pessoais valiosas e prevenir danos por robô de informações pessoais.',
			ab017	: '1. Toda a informação obtida para verificar sua identidade será usada apenas com multas de verificação de identidade e não há medo de que o que foi exposto ao mundo exterior devesse ser cifrado completo, assim que o usasse com tranquilidade.',
			ab018	: '2. Ao verificar sua identidade, você deverá inserir as mesmas informações de seu nome.',
			ab019	: '3. Ao verificar seu telefone móvel, certifique-se de que é o titular da linha telefônica móvel.',
			ab020	: 'http://global.servegames.net/PUBLIC/DOCX/Politica_de_privacidad_y_de_cookies_de_MUo.pdf',
			ab021	: 'http://global.servegames.net/PUBLIC/DOCX/Terminos_de_uso_de_MUo.pdf',
			ab022	: 'Para acessar o servidor de MU-Peruano Origgen é necessária uma conta, ¿deseja continuar para criar uma?',
			ab023	: 'Importante',
			ab024	: 'Continuar',
			ab025	: 'Términos de uso de MU-Peruano Origgen',
			ab026	: 'Política de privacidade e cookies da MU-Peruano Origgen',
			ab027	: 'É necessário aceitar os termos de uso do jogo.',
			ab028	: 'Para registrarse como miembro, é importante aceitar cada um dos pontos da Política de privacidade e dos cookies de MU-Peruano Origgen.',
			ab029	: 'Se você pretende usar o jogo MU-Peruano Origgen precisa aceitar essa condição.',
			ab030	: ' Preencha cada um dos campos seguintes e depois clique no botão enviar.',
			ab031	: 'Para acessar o jogo on-line MU-Peruano Origgen deve aceitar os termos, políticas e condições.',
			ab032	: 'Elija seu país de procedência.',
			ab033	: 'Código de País contém letras',
			ab034	: 'Campo D.N.I. vácuo',
			ab035	: 'Campo D.N.I. Incompleto',
			ab036	: 'Se o seu número estiver completo, omita a mensagem',
			ab037	: 'Campo ID vazio',
			ab038	: 'ID não pode ter menos de 6 caracteres',
			ab039	: 'Campo contraseña vazio',
			ab040	: 'contrasenha não pode ter menos de 10 caracteres',
			ab041	: 'Campo número celular vazio',
			ab042	: 'Número celular incompleto',
			ab043	: 'Número de celular tem letras',
			ab044	: 'Campo correio eletrônico vazio',
			ab045	: 'Formato de correio eletrônico incorreto',
			ab046	: 'D.N.I. (Documento Nacional de Identidade ou equivalente no seu país.)',
			ab047	: 'País :',
			ab048	: 'Elija País de Residência',
			ab049	: 'D.N.I. :',
			ab050	: 'ID:',
			ab051	: 'Contraseña :',
			ab052	: 'Número Celular :',
			ab053	: 'Correio eletrônico :',
			ab054	: 'Enviar',
			ab055	: 'Excelente %u você já tem uma conta para entrar no MU-Peruano Origgen',
			ab056	: 'Compartilhe com seus amigos para tornar esta comunidade maior',
			ab057	: 'Ao baixar o cliente MU-Peruano Origgen, é necessário pelo menos %i de espaço livre no disco rígido. Certifique-se de verificar a capacidade antes da instalação.',
			ab058	: 'Capacidade quando instalado %i.',
			ab059	: 'Data de atualização 18/02/2024.',
			ab060	: 'Como desinstalar: Windows > Painel de controle > Adicionar ou remover programas > MUO-Installer.',
			ab061	: 'Carregando...',
			ab062	: 'Baixe o cliente aqui peso estimado do instalador %i.',
			ab063	: 'Conta criada com sucesso',
			ab064	: 'Baixe o cliente do jogo para entrar nesta comunidade.',
			ab065	: 'Anote e salve seus dados em um local seguro ao qual somente você tenha acesso.',
			ab066	: 'Este ID de conta já está registrado por outro usuário.',
			ab067	: 'Erro ao tentar outro ID.',
			ab068	: 'Erro interno',
			ab069	: 'ID em uso',
			ab070	: 'Tentar novamente',
			//classificação
			ad001	: 'Geração',
			ad002	: 'Redefinir',
			ad003	: 'Nome',
			ad004	: 'Classe',
			ad005	: 'Título',
			ad006	: 'Pontos',
			ad007	: 'Superior',
			ad008	: 'Clã',
			ad009	: 'Duprian',
			ad010	: 'Valente',
			ad011	: 'Nível',
			ad012	: 'Nível mestre',
			ad013	: 'DW',
			ad014	: 'SM',
			ad015	: 'GM',
			ad016	: 'SW',
			ad017	: 'NS',
			ad018	: 'BK',
			ad019	: 'BM',
			ad020	: 'DKN',
			ad021	: 'FE',
			ad022	: 'EU',
			ad023	: 'ELE',
			ad024	: 'NE',
			ad025	: 'MG',
			ad026	: 'DM',
			ad027	: 'MK',
			ad028	: 'DL',
			ad029	: 'LE',
			ad030	: 'EL',
			ad031	: 'S',
			ad032	: 'BS',
			ad033	: 'DMA',
			ad034	: 'DS',
			ad035	: 'RF',
			ad036	: 'FM',
			ad037	: 'FB',
			ad038	: 'GL',
			ad039	: 'ML',
			ad040	: 'SL',
			ad041	: 'RW',
			ad042	: 'RSM',
			ad043	: 'GRU',
			ad044	: 'MRW',
			ad045	: 'SL',
			ad046	: 'RS',
			ad047	: 'MS',
			ad048	: 'SLA',
			ad049	: 'GC',
			ad050	: 'GB',
			ad051	: 'MGB',
			ad052	: 'HGC',
			ad053	: 'LW',
			ad054	: 'LM',
			ad055	: 'SWI',
			ad056	: 'LWI',
			ad057	: 'LMA',
			ad058	: 'W',
			ad059	: 'A',
			ad060	: 'MM',
			ad061	: 'IK',
			ad062	: 'MKN',
			ad063	: 'IM',
			ad064	: 'MKNI',
			//login
			ae001	: 'Insira um e-mail para sua sessão.', // libre
			ae002	: 'Formato de e-mail inválido.',// libre
			ae003	: 'Digite sua senha.',// livre
			ae004	: 'ID e senha.',
			ae005	: 'Eles não combinam.',
			ae006	: 'Senha de usuário incorreta.',// libre
			ae007	: 'consulte seu administrador.',// libre
			ae008	: 'Conta bloqueada.',
			ae009	: 'Você pode recorrer no FAQ',
			ae010	: 'A licença da sua conta expirou.',// libre
			ae011	: 'ID',
			ae012	: 'Senha',
			ae013	: 'Lembre-se',
			ae014	: 'ID errado',
			ae015	: 'PW errado',
			ae016	: 'Recuperar senha',
			ae017	: 'Primeiro login',
			ae018	: 'Sair',
			//comprar
			af001	: 'Popular para todos',
			af002	: 'Pacotes',
			af003	: 'Individual',
			af004	: 'Você optou por doar para o servidor em troca nós lhe enviaremos %i ',
			af005	: 'Código do item %i ',
			af006	: 'Doação $ %i ',
			af007	: 'Obrigado, sua contribuição nos permite melhorar esta comunidade, confira seu Caso Gremory e recomenda MU-Peruano Origgen',
			af008	: 'Este item exige que você escolha qual personagem irá usá-lo.',
			af009	: 'Disponível apenas para o Peru, faça sua doação anexando o código do item e seu ID na mensagem. exemplo: MUO000001 MyGameId.',
			af010	: 'Excelente, o set do personagem que você escolheu foi enviado para o Caso Gremory, aproveite MU-Peruano Origgen',
			af011	: 'Você atingiu o limite máximo de conjuntos de pintinhos para sua conta; máximo 3',
			af012	: 'Graças à sua contribuição nos permite melhorar esta comunidade, recomenda MU-Peruano Origgen',
			af013	: '1',
			af014	: '2',
			af015	: '3',
			af016	: '4',
			af017	: '5',
			af018	: '6',
			af019	: '7',
			af021	: '8',
			af021	: '9',
			af022	: '0',
			af023 : 'Inicie sua sessão',
			af024 : 'Doe ao servidor para manter esta comunidade ativa',

			afa00001: 'Cupom ZEN',
			afb00001: '2.000 milhões de ZEN',
			afa00002 : 'Definir Anjo de Sangue',
			afb00002 : 'Armadura completa + 15 opções completas, Excelente e Anciend',
			afa00003: 'Eu sou uma galinha',
			afb00003 : 'Armadura inicial completa + 15, sorte, Excelente e opção 28',
			afa00004: 'Passe VIP',
            afb00004: 'Acesso ao mapa Silent (VIP) onde existem áreas exclusivas para subir de nível, lojas com itens exclusivos e maior exp.',
		},
		{
			iso6391 : 'qu',
			iso6392 : 'que',
			nombre	: 'Quechua',
			active	: false
		},
		{
			iso6391 : 'es',
			iso6392 : 'spa',
			nombre	: 'Español',
			active	: true,
			//start
			aa001	: 'SG',
            aa002	: 'Juegos',
            aa003	: 'Registrarse',
            aa004	: 'Iniciar sesión',
            aa005	: 'Noticias',
            aa006	: 'Guía',
            aa007	: 'Tablero',
            aa008	: 'Clasificación',
            aa009	: 'Descargar',
            aa010	: 'Consultas',
            aa011	: 'Tienda',
            // register
            ab001   : 'Políticas, Términos y Condiciones',
            ab002   : 'Identificación',
            ab003   : 'Registrar',
            ab004   : 'Registrar una cuenta',
            ab005   : '•    Para registrarse como miembro de MU-Peruano Origgen, lea y acepte los Términos de uso y Política de privacidad y de cookies de MU-Peruano Origgen.',
            ab006   : '•    Este servicio solo puede ser registrado y utilizado por personas mayores de 13 años.',
            ab007   : '•    Si aceptas utilizar MU-Peruano Origgen',
            ab008   : 'Acepto los Términos de uso del juego.',
            ab009   : 'Al registrarme como miembro, acepta cada uno de los puntos de la Política de privacidad y de cookies de MU-Peruano Origgen.',
            ab010   : 'El período de retención de información personal será igual al periodo de notificación de la eliminación del mismo, la recopilación y uso, de información personal es de uso esclusivo para el servicio del juego MU-Peruano Origgen, y si su cuenta no se utiliza durante un cierto período de tiempo de acuerdo con los Términos de uso, se da por concluido el servicio y se eliminan.',
            ab011   : 'Acepto utilizar el juego MU-Peruano Origgen.',
            ab012   : 'Siguiente',
            ab013   : 'Cancelar',
            ab014   : 'Información sobre verificación de identidad para proteger la información personal.',
            ab015   : '- Cuando hagas clic en el botón "Siguiente", procederemos a autenticar y verificar tu identidad. Por favor consulte las instrucciones a continuación.',
            ab016   : '- ServeGamnes, aplicara un sistema de verificación de identidad para proteger información personal valiosa y prevenir daños por robo de información personal.',
            ab017   : '1.   Toda la información ingresada al verificar su identidad se utilizará únicamente con fines de verificación de identidad y no hay temor de que quede expuesta al mundo exterior debido al cifrado completo, así que úsela con tranquilidad.',
            ab018   : '2.   Al verificar su identidad, deberá ingresar la misma información que su nombre.',
            ab019   : '3.   Al verificar su teléfono móvil, asegúrese de que sea el titular de la línea telefónica móvil.',
            ab020   : 'http://global.servegames.net/PUBLIC/DOCX/Politica_de_privacidad_y_de_cookies_de_MUo.pdf',
            ab021   : 'http://global.servegames.net/PUBLIC/DOCX/Terminos_de_uso_de_MUo.pdf',
            ab022   : 'Para acceder al servidor de MU-Peruano Origgen es necesaria una cuenta, ¿deseas continuar para crearte una?',
            ab023   : 'Importante',
            ab024   : 'Continuar',
            ab025   : 'Términos de uso de MU-Peruano Origgen',
            ab026   : 'MU-Peruano Origgen privacy and cookie policy',
            ab027   : 'Es necesario aceptar los términos de uso del juego.',
            ab028   : 'Para registrarse como miembro, es importante aceptar cada uno de los puntos de la Política de privacidad y de cookies de MU-Peruano Origgen.',
            ab029   : 'Si tu intención es utilizar el juego MU-Peruano Origgen necesitas aceptar esa condición.',
            ab030   : 'Llene cada uno de los campos siguientes y luego de clic en el botón enviar.',
            ab031   : 'Para acceder al juego en línea MU-Peruano Origgen debe aceptar los términos, políticas y condiciones.',
            ab032   : 'Elija su país de procedencia.',
            ab033   : 'Código de País contiene letras',
            ab034   : 'Campo D.N.I. vacío',
            ab035   : 'Campo D.N.I. Incompleto',
            ab036   : 'Si su número esta completo omita el mensaje',
            ab037   : 'Campo ID vacío',
            ab038   : 'ID no puede ser menor de 6 caracteres',
            ab039   : 'Campo contraseña vacío',
            ab040   : 'contraseña no puede tener menos de 10 caracteres',
            ab041   : 'Campo número celular vacío',
            ab042   : 'Número celular incompleto',
            ab043   : 'Número Celular tiene letras',
            ab044   : 'Campo correo electrónico vacío',
            ab045   : 'Formato de correo electrónico incorrecto',
            ab046   : 'D.N.I. (Documento Nacional de Identidad o equivalente en su país.)',
            ab047   : 'País :',
            ab048   : 'Elija País de Residencia',
            ab049   : 'D.N.I. :',
            ab050   : 'ID :',
            ab051   : 'Contraseña :',
            ab052   : 'Número Celular :',
            ab053   : 'Correo electrónico :',
            ab054   : 'Enviar',
            ab055   : 'Excelente %u ya tienes una cuenta para ingresar a MU-Peruano Origgen',
            ab056   : 'Comparte con tus amigos para hacer esta comunidad mas grande',
            ab057   : 'Al descargar el cliente de MU-Peruano Origgen, se requiere al menos %i de espacio libre en el disco duro. Asegúrese de verificar la capacidad antes de la instalación.',
            ab058   : 'Capacidad cuando está instalado %i.',
            ab059   : 'Fecha de actualización 2024-02-18.',
            ab060   : 'Como desinstalar: Windows > Panel del control > Agregar o quitar programas > MUO-Installer.',
            ab061   : 'Cargando...', 
            ab062   : 'Descargue el cliente desde aquí peso estimado del instalador %i.',
            ab063   : 'cuenta creada con éxito',
            ab064   : 'Descargue el cliente del juego para unirte a esta comunidad.',
            ab065   : 'Anota y guarda tus datos en un lugar seguro al que solo tu tienes acceso.',
            ab066   : 'Este ID de cuenta ya se encuentra registrado por otro usuario.',
            ab067   : 'Error intente otro ID.',
            ab068   : 'Error interno',
            ab069   : 'ID en uso',
            ab070   : 'Reintentar',
            // ranking
            ad001   : 'Gens',
            ad002   : 'Reset',
            ad003   : 'Nombre',
            ad004   : 'Clase',
            ad005   : 'Título',
            ad006   : 'Puntos',
            ad007   : 'Top',
            ad008   : 'Clan',
            ad009   : 'Duprian',
            ad010   : 'Valiant',
            ad011   : 'Nivel',
            ad012   : 'Nivel master',
            ad013   : 'DW',
            ad014   : 'SM',
            ad015   : 'GM',
            ad016   : 'SW',
            ad017   : 'DK',
            ad018   : 'BK',
            ad019   : 'BM',
            ad020   : 'DKN',
            ad021   : 'FE',
            ad022   : 'ME',
            ad023   : 'HE',
            ad024   : 'NE',
            ad025   : 'MG',
            ad026   : 'DM',
            ad027   : 'MK',
            ad028   : 'DL',
            ad029   : 'LE',
            ad030   : 'EL',
            ad031   : 'S',
            ad032   : 'BS',
            ad033   : 'DMA',
            ad034   : 'DS',
            ad035   : 'RF',
            ad036   : 'FM',
            ad037   : 'FB',
            ad038   : 'GL',
            ad039   : 'ML',
            ad040   : 'SL',
            ad041   : 'RW',
            ad042   : 'RSM',
            ad043   : 'GRU',
            ad044   : 'MRW',
            ad045   : 'SL',
            ad046   : 'RS',
            ad047   : 'MS',
            ad048   : 'SLA',
            ad049   : 'GC',
            ad050   : 'GB',
            ad051   : 'MGB',
            ad052   : 'HGC',
            ad053   : 'LW',
            ad054   : 'LM',
            ad055   : 'SWI',
            ad056   : 'LWI',
            ad057   : 'LMA',
            ad058   : 'W',
            ad059   : 'A',
            ad060   : 'MM',
            ad061   : 'IK',
            ad062   : 'MKN',
            ad063   : 'IM',
            ad064   : 'MKNI',
            // loggin
            ae001   : 'Ingrese un e-mail para su sesión.',  // libre 
            ae002   : 'Formato de e-mail no válido.', // libre 
            ae003   : 'Ingrese su contraseña.', // libre 
            ae004   : 'ID y contraseña.', 
            ae005   : 'No coinciden.',  
            ae006   : 'Contraseña de usuario incorrecta.',  // libre 
            ae007   : 'consulte a su administrador.', // libre 
            ae008   : 'Cuenta bloqueada.', 
            ae009   : 'Puede apelar en FAQ', 
            ae010   : 'Venció la licencia de su cuenta.', // libre 
            ae011   : 'ID',
            ae012   : 'Contraseña',
            ae013   : 'Recordar',
            ae014   : 'ID incorrecto',
            ae015   : 'PW incorrecto',
            ae016   : 'Recuperar contraseña',
            ae017   : 'Primero inicie sesión',
            ae018   : 'Cerrar sesión ',
            // shop
            af001   : 'Popular para todos',
            af002   : 'Paquetes',
            af003   : 'Individual',
            af004   : 'Ha elegido donar al servidor a cambio le enviaremos %i ',
            af005   : 'Código de ítem %i ',
            af006   : 'Donación $ %i ',
            af007   : 'Gracias tu aporte nos permite mejorar esta comunidad revisa tu Gremory Case',
            af008   : 'Este ítem requiere que elijas que personaje lo usara.',
            af009   : 'Disponible solo para Perú, haz tu donativo adjuntando el código del ítem y tu ID en el mensaje. ejemplo: MUO000001 MiIdDelJuego',
            af010   : 'Excelente, el set para el personaje que haz elegido se envió al Gremory Case, disfruta de MU-Peruano Origgen',
            af011   : 'Has alcanzado el límite máximo de sets pollito para tu cuenta; máximo 3',
            af012   : 'Gracias tu aporte nos permite mejorar esta comunidad, recomienda MU-Peruano Origgen',
			af013	: '1',
			af014	: '2',
			af015	: '3',
			af016	: '4',
			af017	: '5',
			af018	: '6',
			af019	: '7',
			af021	: '8',
			af021	: '9',
			af022	: '0',
			af023	: 'Inicia tu sesión',
			af024	: 'Dona al servidor para mantener esta comunidad activa',
			// home
			ag001   : 'Servidores',
			ag002   : 'Eventos',

			aga001   : 'Servidor Origgen (SPEED)',
			aga002   : 'País: Perú',
			aga003   : 'Versión: 19 ep 1-3',
			aga004   : 'Exp: 5000X',
			aga005   : 'Vip Exp: 9000X + bonus',
			aga006   : 'Reset: 400 nivel normal',
			aga007   : 'Sub Server 1 1500rr: En línea',
			aga008   : 'Sub Server 2 1500rr: En línea',
			aga009   : 'Sub Server 3 No PvP: En línea',
			aga010   : 'Sub Server 4 500rr: En línea',
			aga011   : 'Sub Server 5 250rr: En línea',
			aga012   : 'Sub Server 6 100rr: En línea',
			aga013   : 'Level master 1600',

			agb001   : 'Servidor Peruano (SLOW)',
			agb002   : 'País: Perú',
			agb003   : 'Versión: 19 ep 1-3',
			agb004   : 'Exp: 50X',
			agb005   : 'Vip Exp: 800X + bonus',
			agb006   : 'Reset: No Reset',
			agb007   : 'Sub Server 1 pvp: En línea',
			agb008   : 'Sub Server 2 pvp: En línea',
			agb009   : '',
			agb010   : '',
			agb011   : '',
			agb012   : '',
			agb013   : 'Level master 1600',

			agc001   : 'Temporada 1%',
			agc002   : 'https://www.youtube.com/watch?v=xe1_UeVsD10',
			agc003   : 'Efecto del Nova de Lycaon, redujo el poder de los monstruos jefes al 1%',
			agc004   : 'Duración de evento: Abril a Junio',





            afa00001    : 'Cupón de ZEN',
            afb00001    : '2000 millones de ZEN',
            afa00002    : 'Set Blood Angel',
            afb00002    : 'Armadura completa + 15 full opciones, Excelente y Anciend',
            afa00003    : 'Cupón Soy Pollito',
            afb00003    : 'Armadura completa de iniciación + 15, con suerte, Excelente y opción 28',
            afa00004    : 'Pase VIP',
            afb00004    : 'Acceso a mapa Silent (VIP) en el hay Zonas exclusivas para subir de nivel, tiendas con ítems exclusivos, y mayor exp.',

			
			


		},
	]																		//textos
	aac = [{

		aaa 			: false,													//inicio
		aab				: false,													//register
			aaba				: false,													//register Terminos y Politicas
			aabb				: false,													//register validacion
			aabc				: false,													//register formulario
			aabd				: false,													//register confirmacion
		aac 			: false,													//download

		// aad 			: false,													//ranking
			aada				: false,													//loading
			aadb				: false,													//rqanking
		// 	aadc				: false,												//free
		// 	aadd				: false,												//free

		aae				: false,													//loggin
			aaea				: false,												//libre
			aaeb				: false,												//libre

		aaf				: false,													//shop
			aafa				: false,													//loading
			aafb				: false,													//rqanking
			aafc				: false,												//free
			aafd				: false,												//free


		aag				: false,											//libre
		aah				: false,											//libre
		aai				: false,											//libre



		// variables de aceptacion de politicas y terminos y uso de datos
		baa 			: 0,														//check terms
		bab 			: 0,														//check policy
		bac 			: 0,														//check person data
		
		// variables de descarga de clietne
		baca 			: '2.59GB',													//peso de cliente instalado
		bacb			: '1.73GB',													// peso del instalador
		bacd			: 'https://www.mediafire.com/file/0g8qcbswsikuv2j/MUO-INSTALER.exe/file',

		// variables para formulario de registro
		bad		    	: 0, 														// contiene el pais elejido en el form
		bada		    : '', 														// contiene mensaje de alerta si no elije un pais en el form
		bae				: '0',														// contiene el dni del usuario en el form
		baea		    : '', 														// contiene mensaje de alerta si no ingresa un dni en el form
		baeb		    : 18, 														// longitud maxima para dni
		baec		    : 18, 														// longitud minima para dni

		baf				: '',														// contiene el id del usuario en el form
		bafa		    : '', 														// contiene mensaje de alerta si no ingresa un id en el form
		bafb		    : 10, 														// longitud maxima de ID de usuario
		bafc		    : 6, 														// longitud minima de ID de usuario

		bag				: '',														// contiene el password del usuario en el form
		baga		    : '', 														// contiene mensaje de alerta si no ingresa un password en el form
		bagb		    : 20, 														// longitud maxima contraseña de usuario
		bagc		    : 10, 														// longitud minima contraseña de usuario

		bah				: '0',														// contiene el celular del usuario en el form
		baha		    : '', 														// contiene mensaje de alerta si no ingresa un celular en el form
		bahb		    : 20, 														// longitud maxima telefono de usuario
		bahc		    : 9, 														// longitud minima telefono de usuario

		bai				: '',														// contiene el correo del usuario en el form
		baia		    : '', 														// contiene mensaje de alerta si no ingresa un correo en el form
		baib		    : 50, 														// contiene mensaje de alerta si no ingresa un correo en el form
		
		
		// variables de ranking 
		baj		    	: 0, 														// contiene el tipo de reankin que se quiere ver gens o reset
		bak		    	: 0, 														// contiene el tipo de titulo gens ganador
		baka		    : {}, 														// contiene al gabador de duprian gens
		bakb		    : {}, 														// contiene el ganador de valiant gens
		bakc		    : {}, 														// contiene el ganador de resets gens
		bakd		    : 'db', 													// contiene variable mutable para elegirque ranking usar
		bake		    : [], 														// contiene rankng por clasificaiones
		
		// variables de loggin 
		bal		    	: false, 													// inicio de secion activa
		bala			: '',														// variable usuario
		balb			: '',														// varaible contraseña
		balc			: 0,														// variable auxiliar usuario
		bald			: 0,														// varaible auxiliar contraseña
		bale			: '',														// nombre de usaurio
		// balf			: 20,														// varaible limite de caracteres contraseña
		balg			: '',														// checket
		balh			: false,													// activar boton

		// shop
		bama				: 'Kaelum', 												// server
		bamb				: '', 														// character
		bamc				: '', 														// cambia para botones shop
		bamda				: 0, 														// cambia swith shop
		bamdb				: 0, 														// cambia swith shop
		bamdc				: 0, 														// cambia swith shop
		bamdd				: 0, 														// cambia swith shop
		bamde				: 0, 														// cambia swith shop
		bamdf				: 0, 														// cambia swith shop
		bamdg				: 0, 														// cambia swith shop
		bamdh				: 0, 														// cambia swith shop
		bamdi				: 0, 														// cambia swith shop
		bamdj				: 0, 														// cambia swith shop
		bamdk				: 0, 														// cambia swith shop
		bamdl				: 0, 														// cambia swith shop
		bamdm				: 0, 														// cambia swith shop
		bamdn				: 0, 														// cambia swith shop
		bame				: '', 														// cambia swith shop




				// c_d: 8,																//limite dni userlogin  (tomar config)
				// c_e: false,																//llave session actualizar
				// c_f: '',																//var user loggin
				// c_g: '',																//var user pass
				// c_h: false,																//llave active boleteria
				// c_i: false,																//llave desciptcion room
				// c_j: [],																//contend room
				// c_k: false,																//Control socket envio sin repeticiones.
				// c_l: 17,																//llave consulta dni
				// c_m: '',																//var user dni
				// c_n: 0,																	//tipe doc code
				// c_o: '',																//tipe doc text




		oaa				: '0982173498dhjwqhd089712hd923874hd807123y4io2',			// token api
		oab				: false,											 		// deasrrollo (true) o produccion (false) paypal
		
		oac				: false,													// deasrrollo (true) o produccion (false) servidor
		oad				: 'https://youtu.be/zedSZhGfTf0',							// VIDEO YOUTUBE SET POLLO
														 	

		oaca				: "AcGBwLqPvuITs-W5x-8Z6Ig8rNJPdr5MzYQ4eZyQ0mwse6MegFHdwrEEdFhBVF9t1JKqHtgRET5WE_o2",		 //deasrrollo token paypal
		oacb				: "Aa7Ngup8fsfqcxuiq4RBASbVAR1l6mcrqTKzgwhhW--HOfQ70STTaPZo7I9H82ne3b6J5qcXYYBsKrrv",		 //produccion token paypal

		oaka				: 'http://localhost:8884',							// url del servidor de APIS
		// oak				: 'https://localhost:4444',							// url del servidor de APIS
		
		//	oak				: 'http://muo.servegames.net:8884',					// url del servidor de APIS
		oakb				: 'https://muo.servegames.net:4444',							// url del servidor de APIS

		oal				: false,													// swith envio por post a la espera de una respuesta

		oap				: '000201010211393271e362fba45a5db899e602839813b24a5204561153036045802PE5906YAPERO6004Lima63048AA6',		// codigo yape
		




		// j_b: '',																	// vars support name
		// j_c: '',																	// vars support firstname
		// j_d: '',																	// vars support lastname
		// j_e: '',																	// vars support birthday
		// j_f: '',																	// vars support gender
		// j_g: '',																	// vars support cell
		// j_h: '',																	// vars support email
		// j_i: '0',																	// vars support country	name
		// j_j: '0',																	// vars support City
		// j_k: '0',																	// vars support Province name
		// j_l: '0',																	// vars support district name
		// j_m: '0',																	// vars support Populated_center name
		// j_n: '',																	// vars support Address
		// j_o: '0',																	// vars support ID
		// j_p: {},																	// var details of the user's nationality (objet)
		// j_q: '0',																	// vars support ID
		// j_r: '0',																	// vars support ID


		caa				: false,													//modal cancelacion de registro
			caaa		: false,													//
		cab				: false,													//modal terminos de uso MUO
			caba		: false,													//
		cac				: false,													//modal politicas de uso MUO
			caca		: false,													//
		cad				: false,													//modal intenta continuar sin aceptar terminos, politicas y uso de MUO
			cada		: false,													//
		cae				: false,													//modal confirma la creacion de cuenta
			caea		: false,													//
		caf				: false,													//modal descargas del juego (temporal)
			cafa		: false,													//
		cag				: false,													//modal shop item del juego (temporal)
			cafa		: false,													//
		cah				: false,													//modal shop item confirma del juego (temporal)
			caha		: false,													//


		ccc				: false,													//modal switch global


		// 	b_da		: false,														//routes list get routes
		// 	b_db		: false,														//routes legend
		// 	b_dc		: false,														//routes search
		// 	b_dd		: false,														//routes list
		// 	b_de		: false,														//Denuncias


		// b_e 			: false,														//reports
		// b_f 			: false,														//equipment
		// b_g 			: false,														//payments
		// b_h 			: false,														//admin







		// d_a				: 'f67s987df6sa98f76s9d7f6safsfs76fsfshfbsakjfh',			//usada
		// d_b 			: '0982173498dhjwqhd089712hd923874hd807123y4io2',														//usada
		// d_c 			: 'Onta',													//usada
		// d_d				: true,		//llave online
		// d_e				: 'http://con.localhost',		//llave online
		// d_f				: 'http://apifa.traker.ga',		//llave online

		// // e_a 			:'ws://localhost:6959?App=Onta',						//usanda
		// e_a 			:'ws://ws.nwseguridad.com:6950?App=Onta',						//usanda
		// e_b 			:'',														//var socket



		// e_c 			: false,									//->	key indexdb update ontaito
		// e_d 			: 0,										//->	indexdb key vehicle
		// e_e				: false,									//->	switch update
		// e_f				: 'Onta',									//->	indexdb prefix db name
		// e_g				: 0,										//->	indexdb version
		// e_h				: 0,										//->	indexdb date one selected
		// e_i				: 0,										//->	indexdb date two selected
		// e_j				: 0,										//->	TimeLine selected interval
		// e_k				: 0,										//->	TimeLine show interval
		// e_l				: 0,										//->	TimeLine disable interval
		// e_m				: 0,										//->	TimeLine error limit
		// e_n				: false,									//->	TimeLine switch manipulation selected time intervals
		// e_o				: false,									//->	switch show routes and TimeLine
		// e_p				: false,									//->	switch cordinates update map
		// e_q				: 0,										//->	Contend cordinates for print on routes
		// e_r				: false,									//->	Datepicker switch manipulation selected date intervals
		// e_s				: 0,										//->	control time mayor minor bulean
		// e_t				: 0,										//->	Datepicker two date's changer value
		// e_u				: 0,										//->	Datepicker date base for e_h, e_i
		// e_v				: 0,										//->	indexdb on use db name
		// e_w				: 0,										//->	TimeLine ticksNumber value
		// e_x				: 0,										//->	Datepicker day counter
		// e_y				: 0,										//->	List vehicles switch manipulation
		// e_z				: 0,										//->	counter timer picker
		// eba				: 0,										//->	kilometers motor
		// ebb				: false,									//->	switch show last point






		// f_a				: false,													//togle menus
		// f_b				: 0,														//key menus
		// f_c				: false,													//sos
		// f_d				: false,													//sos_v
		// f_e				: 0,														//sos_code
		// f_g				: 0,														//sum_submenus
		// f_f				: 0,														//key submenus lits
		// f_h				: 0,														//key submenus legend
		// f_i				: 0,														//key submenus search
		// f_j				: {},														//objeto timer onta auxliar
		// f_k				: 0,														//objeto timer onta auxliar

		// g_a				: false,													//switch show marker speed 10 to 15
		// g_b				: false,													//switch show marker speed 15 to 20
		// g_c				: false,													//switch show marker speed 20 to 30
		// g_d				: false,													//switch show marker speed 30 to mx
		// g_e				: false,													//switch show marker overspeed
		// g_f				: false,													//switch show marker power on engine
		// g_g				: false,													//switch show marker power off engine
		// g_h				: false,													//switch show marker power cut engine
		// g_i				: false,													//switch show marker lowbattery
		// g_j				: false,													//switch show marker sos
		// g_k				: false,													//switch show marker shock
		// g_l				: false,													//switch show marker door
		// g_m				: false,													//switch show marker door




		// // j_a: 'wss://ws.nwseguridad.com:6969?App=Onta',							//usanda produccion https
		// j_a: 'ws://ws.nwseguridad.com:6959?App=Onta',								//usanda produccion http
		// // j_a: 'ws://ws.nwseguridad.com:6950?App=Onta',								//usanda dessarrollo http
		// // j_a: 'wss://ws.nwseguridad.com:6960?App=Onta',								//usanda dessarrollo https
		// j_b: '',																//var socket
		// j_c: false,																//preconnect
		// j_d: false,																//connected

		// j_g: 'ws://159.65.107.11:60001/?App=Mi-Muni',							// launcher
		// j_h: '',																//var socket
		// j_i: false,																//preconnect
		// j_j: false,																//connected


		// j_m: 'ws://159.65.107.11:60001/?App=Mi-Muni',										//usanda
		// j_n: '',																//var socket
		// j_o: false,																//preconnect
		// j_p: false,																//connected

	}]


	// en estas variables se almacena diccionarios o matrices que usa la web
		aad = {}																	// almacena todos los textos de la web config languaje
		aae = {}																	// almacena datos de paices
		aaf = {}																	// almacena datos temporales de cualqueir tipo
		aag = {}																	// almacena datos de servidores activos
		aah = {}																	// almacena datos de ranking reset
		aai = {}																	// almacena datos de ranking gens
		aaj = {}																	// almacena datos de loggin
		aak = {}																	// almacena datos de compra





	// a_e = {}																		//dates user
	// a_f = {}																		//admins
	// a_g = []																		//positions
	// a_h = []																		//positions sos
	// a_i = []																		//lateral menus
	// a_j = []																		//indexdb
	// a_k = []																		//cordiantes for lines
	// a_l = []																		//array routes for vehicles
	// a_m = []																		//array speed yellow for vehicles 10 to 15
	// a_n = []																		//legend buttons
	// a_o = []																		//array speed yellow for vehicles 15 to 20
	// a_p = []																		//array speed orange for vehicles 20 to 30
	// a_q = []																		//array speed red for vehicles 30 to max
	// a_r = []																		//array speed danger for vehicles overspeed
	// a_s = []																		//array speed green for power on engine
	// a_t = []																		//array speed green for power off engine
	// a_u = []																		//array speed green for power cut engine
	// a_v = []																		//array speed green for power lowbattery
	// a_w = []																		//array speed green for power sos
	// a_x = []																		//array speed green for power shock
	// a_y = []																		//array speed green for power door
	// a_z = []																		//array speed green for power door


	// aaa = []																		//array speed green for power shock
	// aab = []																		//array speed green for power door
	// aac = []																		//array speed green for power door

	// aad = []																		//array denuncias



	// b_a = (a) => this.a_a = a														//chargin

	// b_b = (a) => {																	//user loggin
	// 	this.a_b = a.v
	// 	this.a_d = a.s
	// 	// console.log(a.s, a.s.latitude, a.s.longitude)
	// 	this.c_a('e_q', [a.s.longitude, a.s.longitude])
	// 	this.a_e = a.t
	// 	this.a_f = a.u															// admins users array
	// }
	// baa = (a) => {																	//user loggin
	// 	// console.log(a)
	// 	this.aaa = a.d															// LOGGIN
	// 	this.aab = a.j															// LOCAL SOS
	// 	this.aac = a.u															// SOS
	// }


	// bac = (a) => {
	// 	// console.log(a)															//user loggin
	// 	this.aac.pop()																// SOS
	// 	this.aac = a.u																// SOS

	// }
	// bad = (a) => {
	// 	console.log(a)																	//user loggin
	// 	this.aab.pop()																// SOS
	// 	this.aab = a.u																// SOS

	// }
	
	// baf = (a) => {																	//user loggin
	// 	this.c_a('e_p', false)
	// 	this.c_a('e_q', [a.lat, a.lon])
	// 	this.c_a('e_p', true)
	// }



	// b_c = (a) => {																	//user access
	// 	this.a_g = a.t																//list vehicles for points
	// 	this.a_i = a.u																//menu lateral izquierdo
	// 	this.a_j = a.v   															//menu lateral derecho
	// 	this.a_n = a.w   															//legend buttons
	// 	this.a_g.lastupdate = dateFormat(a.t.servertime, 'dd-mm-yyyy HH:MM:ss')
	// }
	// b_d = (a) => {
	// 	this.a_h.push(a)   															//user sos
	// }
	// b_e = (a) => {

	// 	//this.a_k = []															//ad coordinates line
	// 	this.a_k = []															//ad coordinates line
	// 	this.a_m = []															//ad coordinates line
	// 	this.a_o = []															//ad coordinates line
	// 	this.a_p = []															//ad coordinates line
	// 	this.a_q = []															//ad coordinates line
	// 	this.a_r = []															//ad coordinates line
	// 	this.a_s = []															//ad coordinates line
	// 	this.a_t = []															//ad coordinates line
	// 	this.a_u = []															//ad coordinates line
	// 	this.a_v = []															//ad coordinates line
	// 	this.a_w = []															//ad coordinates line
	// 	this.a_x = []															//ad coordinates line
	// 	this.a_y = []															//ad coordinates line
	// 	this.a_z = []															//ad coordinates line
	// 	this.a_k.push(a.rout)
	// 	this.a_m.push(a.speeye)
	// 	this.a_o.push(a.speeor)
	// 	this.a_p.push(a.speere)
	// 	this.a_q.push(a.speeda)
	// 	this.a_r.push(a.oversp)
	// 	this.a_s.push(a.poweon)
	// 	this.a_t.push(a.poweof)
	// 	this.a_u.push(a.powecu)
	// 	this.a_v.push(a.lowbat)
	// 	this.a_w.push(a.alasos)
	// 	this.a_x.push(a.ashock)
	// 	this.a_y.push(a.aldoor)
	// 	this.a_z.push(a.jumpin)

	// 	//console.log(this.a_k)
	// 	this.c_a('e_c', false)
	// 	this.c_a('e_o', true)

	// 	return
	// }
	// b_f = (a) => {
	// 	this.a_l = []															//ad coordinates line
	// 	this.a_l.push(a)
	// }

	// //console.log(this.a_b, 'ddddd')

	// //this.a_k = a[6]
	// //this.a_l = a[7]
	// //console.log(this.a_g, 'ssssssssssssssss')
	// //b_d = (a) => this.a_j = a




	caa = (a,b) => {this.aac[0][a] = b}												//update vars a_c
	cab = (a) => {this.aad = a}														// actulizando variable de lenguaje
	cac = (a) => {this.aae = a}														// actulizando variable de paices
	cad = (a) => {this.aaa = a}														// actulizando idioma de usuario

	cae = (a) => {this.aag = a}														// actulizando idioma de usuario
	caf = (a) => {this.aah = a}														// actulizando idioma de usuario
	cag = (a) => {this.aai = a}														// actulizando idioma de usuario
	cah = (a) => {																	//user loggin
		for (let it of this.aag){
			if(it.id_server === a.id_server){
				// console.log(zz[ww].date, ww, it.id, us[xb].Id_user_services, zz.length)
				it.active = 1
			} else {
				it.active = 0
			}
		}
	}
	cai = (a,b) => {this.aaj = a}	
	caj = (a,b) => {this.aak = a}	



	// c_b = (a) => {																	//update cords a_g
	// 	this.a_g.map((j) => {
	// 		let xx
	// 		let zz = JSON.parse(a.attributes)
	// 		if (zz.ignition===true || zz.ignition===false){
	// 			if (zz.ignition===true){
	// 				xx = 1
	// 			} else {
	// 				xx = 0
	// 			}
	// 		} else {
	// 			xx = ''
	// 		}

	// 		//console.log(j, a)
	// 		if (j.id === a.deviceid) {
	// 			j.latitude = a.latitude
	// 			j.longitude = a.longitude
	// 			j.speed = a.speed
	// 			j.devicetime = a.devicetime
	// 			j.attributes = a.attributes
	// 			j.course = a.course
	// 			j.ignition = xx ===''? j.ignition : xx
	// 			j.online = 'online'
	// 			j.lastupdate = dateFormat(a.servertime, 'dd-mm-yyyy HH:MM:ss')
	// 			return j
	// 		}
	// 	})
	// 	//this.a_j[0][a] = b
	// }
	// c_c = (a) => {
	// 	if (Auxiliar.aad(this.a_h)){
	// 		this.b_d({
	// 			"id": a.deviceid,
	// 			"ignition": a.ignition? a.ignition : 0,
	// 			"devicetime": a.devicetime,
	// 			"latitude": a.latitude,
	// 			"longitude": a.longitude,
	// 			"altitude": 0,
	// 			"speed": a.speed,
	// 			"course": a.course,
	// 			"attributes": a.attributes,
	// 			"online": true,
	// 			"online_date": a.devicetime,
	// 			"name": 'SOS',
	// 			"lastupdate": dateFormat(a.servertime, 'dd-mm-yyyy HH:MM:ss'),
	// 			"positionid": a.id,
	// 			"phone": "",
	// 			"contact": '',
	// 			"car_marca": '',
	// 			"car_modelo": '',
	// 			"car_anio": '',
	// 			"car_color": '',
	// 			"gps_num": '',
	// 			"car_icon": '',
	// 			"car_apodo": ''
	// 		})
	// 		//this.b_d(a)
	// 		//console.log('nada')
	// 	} else {																//update cords a_h
	// 		this.a_h.map((j) => {
	// 			let xx
	// 			let zz = JSON.parse(a.attributes)
	// 			if (zz.ignition===true || zz.ignition===false){
	// 				if (zz.ignition===true){
	// 					xx = 1
	// 				} else {
	// 					xx = 0
	// 				}
	// 			} else {
	// 				xx = ''
	// 			}

	// 			//console.log(j, a)
	// 			if (j.id === a.deviceid) {
	// 				j.latitude = a.latitude
	// 				j.longitude = a.longitude
	// 				j.speed = a.speed
	// 				j.devicetime = a.devicetime
	// 				j.attributes = a.attributes
	// 				j.course = a.course
	// 				j.ignition = xx ===''? j.ignition : xx
	// 				j.online = 'online'
	// 				j.lastupdate = dateFormat(a.servertime, 'dd-mm-yyyy HH:MM:ss')
	// 				return j
	// 			}
	// 		})
	// 	}
	// 	//console.log(this.a_h)
	// 	//this.a_j[0][a] = b
	// }


	increment (llave,val) {
		switch (llave) {

			case 3: // descargar															//sessi
				this.increment(1000)
				this.aac[0].aac = true
			break;

			case 5:	// loggin
				if (val.sub === 1) {
					document.getElementById("bodycont").scrollTop = 0
					if (this.aac[0].aae) {
						this.caa('aae', false)
						this.caa('aaea', false)
					} else {
						this.caa('aae', true)
						this.caa('aaea', true)
					}
				}
				if (val.sub === 2) {
					if (!this.aac[0].oal){
						this.aac[0].oal = true
						MUO_APIs.aaa(1, {
							user : this.aac[0].bala,
							pass : this.aac[0].balb
							}, 'MUO_LOGGIN', this.aac[0].oac === true ? this.aac[0].oaka : this.aac[0].oakb, 'POST', (ra)=>{
							this.caa('bala','')
							this.caa('balb','')
							let ps = JSON.parse(ra).data
							if (ps.status === true) {
								if (this.aac[0].balg==='checked'){
									// console.log(ps.data.s, ps.data.s.email)
									localStorage.setItem('muoriggen', JSON.stringify(ps.msg))
								}
								this.cai(ps.msg)
								this.caa('bale', ps.msg.da[0].memb___id)
								this.caa('aae', false)
								this.caa('aaea', false)
								this.increment(6, {sub : 1})
							} else if (ps.status === false) {
								this.caa('balc',3) // this.aad.ae004
								this.caa('bald',2) // this.aad.ae005
							} else if(ps.status === 'locked'){
								this.caa('balc',5) // this.aad.ae008
								this.caa('bald',4) // this.aad.ae009
							}
							this.caa('oal', false)
						}).catch((e)=>{
							console.log('error API MUO_LOGGIN', e)
						})
					}
				}
				if (val.sub ===3) {
					document.getElementById("bodycont").scrollTop = 0
					if (this.aac[0].aae) {
						this.caa('aae', false)
						this.caa('aaeb', false)
					} else {
						this.caa('aae', true)
						this.caa('aaeb', true)
					}
				}
				if (val.sub ===4) {
					this.increment(1000)
					this.increment(1004)
					this.caa('aae', false)
					this.caa('aaeb', false)
					this.caa('bale', '')
					this.cai({})
					localStorage.removeItem('muoriggen')
					this.increment(1)
				}
			break;

			case 1000:	//apagar todos los modulos
				this.aac[0].aaa = false
				this.aac[0].aab = false
				this.aac[0].aac = false
				// this.aac[0].aad = false
				this.aac[0].aae = false
				this.aac[0].aaf = false
				this.aac[0].aag = false
				this.aac[0].aah = false
				this.aac[0].aai = false
			break;
			case 1001:	//apagar todos los modulos del registro
				this.aac[0].aaba = false
				this.aac[0].aabb = false
				this.aac[0].aabc = false
				this.aac[0].aabd = false
			break;
			case 1002:	//apagar todos los modulos del ranking
				this.aac[0].aada = false
				this.aac[0].aadb = false
			break;
			case 1003:	//apagar todos los modulos del ranking delay
				// this.aac[0].aadb = true
			break;
			case 1004:	//apagar todos los modulos del ranking
				this.aac[0].aafa = false
				this.aac[0].aafb = false
				this.aac[0].aafc = false
				this.aac[0].aafd = false
			break;
			case 1005:	//apagar todos los modulos del ranking
				this.aac[0].bamda = 0
				this.aac[0].bamdb = 0
				this.aac[0].bamdc = 0
				this.aac[0].bamdd = 0
				this.aac[0].bamde = 0
				this.aac[0].bamdf = 0
				this.aac[0].bamdg = 0
				this.aac[0].bamdh = 0
				this.aac[0].bamdi = 0
				this.aac[0].bamdj = 0
				this.aac[0].bamdk = 0
				this.aac[0].bamdl = 0
				this.aac[0].bamdm = 0
				this.aac[0].bamdn = 0
			break;
			default:
				console.log("neutro")
			break;

		}

	}

constructor () {
		makeObservable(this, {
			// a_a: observable,
			// a_b: observable,
			// a_c: observable,
			// a_d: observable,
			// a_e: observable,
			// a_f: observable,
			// a_g: observable,
			// a_h: observable,
			// a_i: observable,
			// a_j: observable,
			// a_k: observable,
			// a_l: observable,
			// a_m: observable,
			// a_n: observable,
			// a_o: observable,
			// a_p: observable,
			// a_q: observable,
			// a_r: observable,
			// a_s: observable,
			// a_t: observable,
			// a_u: observable,
			// a_v: observable,
			// a_w: observable,
			// a_x: observable,
			// a_y: observable,
			// a_z: observable,

			aaa: observable,
			aab: observable,
			aac: observable,
			aad: observable,
			aae: observable,
			//aaf: observable, // no es observable por que de momento son datos no sensibles a cambios web
			aag: observable,
			aah: observable,
			aai: observable,
			// aaj: observable,
			// aak: observable,


			// b_a: action,
			// b_b: action,
			// b_c: action,
			// b_d: action,
			// b_e: action,
			// b_f: action,
			caa: action,
			cab: action,
			cac: action,
			cad: action,
			cae: action,
			caf: action,
			cag: action,
			cah: action,
			cai: action,
			caj: action,
			// c_b: action,
			// c_c: action,

			// baa: action,
			// bab: action,
			// bac: action,
			// bad: action,
			// bae: action,
			// baf: action,
			// bag: action,


			increment: action,




		})
	}

}
//const onta_Dispensador = new Onta_Dispensador()

export default new RapidStore();