import React 									from 'react'
import { observer }								from 'mobx-react'

import Eventscolas 								from '../../../fun/js'

import Auxiliar 								from '../../../fun/fun'

import MUO_functions 							from '../js/MUO_fun'
import MUO_str 									from '../storage/MUO_str'

import Apiserver								from "../js/MUO_APIs"

import Shop_spot 								from './shop_spot'
import Shop_items								from './shop_items'

// let start = 0


class Shop extends React.Component {
	
	render () {
		// if (start === 1 ){
		// 	return
		// }

 		return(<>
			{/* {start = 1} */}
			{MUO_str.aac[0].bale === ''?
				<>
					<Shop_spot />
				</>
			:<>
				<Shop_items />
			</>}
 		</>)
	}
}
export default observer(Shop)