import React 									from 'react'
import { observer }								from 'mobx-react'

import Eventscolas 								from '../../../fun/js'

import Auxiliar 								from '../../../fun/fun'

import MUO_functions 							from '../js/MUO_fun'
import MUO_str 									from '../storage/MUO_str'

import Apiserver								from "../js/MUO_APIs"


import { Outlet, Link} 								from "react-router-dom"

class Loggin extends React.Component {
	
	render () {

 		return(<>
			{MUO_str.aac[0].aaea?
				<div
					className='toggled-element-left light loggin'
				>	
					<div className='login-form'>
						<h1 className='titulo c_gray'></h1>
						<input
							className={MUO_str.aac[0].bald === 0 ? 'userid fo_sr12 pa2 p_gray' : 'userid fo_sr12 pa2 p_red'}
							type='text'
							placeholder={ 
								MUO_str.aac[0].balc === 1 ? MUO_str.aad.ae001 : 
								MUO_str.aac[0].balc === 2 ? MUO_str.aad.ae002 : 
								MUO_str.aac[0].balc === 3 ? MUO_str.aad.ae004 :
								MUO_str.aac[0].balc === 4 ? MUO_str.aad.ae006 :
								MUO_str.aac[0].balc === 5 ? MUO_str.aad.ae008 :
								MUO_str.aac[0].balc === 6 ? MUO_str.aad.ae010 : 
								MUO_str.aac[0].balc === 7 ? MUO_str.aad.ae014 : MUO_str.aad.ae011
							}
							value={MUO_str.aac[0].bala ? MUO_str.aac[0].bala : ''}
							onChange={(val) => MUO_functions.formlogin('bala', val.target.value, 1)}
						/>
						<input
							autoComplete='on'
							className={MUO_str.aac[0].bald === 0 ? 'userpw fo_sr12 pa2 p_gray' : 'userpw fo_sr12 pa2 p_red'}
							type={MUO_str.aac[0].bald === 0 ? 'password' : 'text'}
							placeholder={
								MUO_str.aac[0].bald === 1 ? MUO_str.aad.ae003 :
								MUO_str.aac[0].bald === 2 ? MUO_str.aad.ae005 :
								MUO_str.aac[0].bald === 3 ? MUO_str.aad.ae007 :
								MUO_str.aac[0].bald === 4 ? MUO_str.aad.ae009 :
								MUO_str.aac[0].bald === 5 ? MUO_str.aad.ae007 : 
								MUO_str.aac[0].bald === 6 ? MUO_str.aad.ae015 : MUO_str.aad.ae012
							}
							value={MUO_str.aac[0].balb ? MUO_str.aac[0].balb : ''}
							onChange={(val) => MUO_functions.formlogin('balb', val.target.value, 3)}
							onKeyDown={(val) => val.code === 'Enter' || val.code ===  "NumpadEnter" ? MUO_functions.validacion_login() : console.log() }
						/>
						<div className='checkbox alseen'>
							<input
								className='onta-check-form wir20 '
								type='checkbox'
								name='c1'
								checked={MUO_str.aac[0].balg}
								onChange={(e) => MUO_functions.formchecket(e)}
								onKeyDown={(val) => val.code === 'Enter' || val.code ===  "NumpadEnter" ? MUO_functions.validacion_login() : console.log() }
							>
							</input> { MUO_str.aad.ae013}
						</div>
						<div
							className='boton'
							disabled={MUO_str.aac[0].balh}
							onClick={()=> MUO_functions.validacion_login()}
						>
							<div
								className='hocevece hep100 fo_sr40 icon-location_history'
							>
							</div>
						</div>
						<div
							className='recup'
							// onClick={()=> MUO_functions.validacion_login()}
						>
							<div
								className=''
							>
								{MUO_str.aad.ae016}
							</div>
						</div>

						{/* <input type="file" accept="image/jpg,image/jpeg,image/png" id="fileInput" 
							onChange={(e)=>(MUO_functions.bah(e, 0))}
						/>
							 */}
					</div>
					
				</div>
			:''}
			{MUO_str.aac[0].aaeb?
				<div
					className='toggled-element-left light loggin'
				>	
					<div 
						className='logout'
					>
						<div 
							className='logout_opt cupo'
							onClick={()=> MUO_str.increment(5, {sub : 4})}
						><Link to={`home`}>{MUO_str.aad.ae018}</Link>
						</div>
					</div>
				
				</div>
			
			:''}

 		</>)
	}
}
export default observer(Loggin)