import React									from 'react'
import { observer }								from 'mobx-react'

import MUO_str 						        from './storage/MUO_str'
import Eventscolas 						        from '../../fun/js'


//<PDFObject url={this.props.link} />
class Modal extends React.Component {
	render(){
		return (
            MUO_str.aac[0].ccc? 
                <div
                    id={this.props.id}
                    className='ctn_modal'
                    onClick={(e) => Promise.resolve().then(e.target.id===this.props.id ? this.props.bclose: '') }
                >   
                    <div
                        className={'modal ' + this.props.clasn1}
                    >
                        <div
                            className='mdtittle fo_sr10'
                        >
                            <div
                                className={'mdico ico_sr15 ' + this.props.icon}
                            >
                            </div>
                            <div
                                className='mdtittletext'
                            >   
                                {this.props.title}
                            </div>
                            <div
                                className='cu1 mdbuttonclose ico_sr10 icon-close'
                                onClick={() => Promise.resolve().then(this.props.bclose) }
                            >
                            </div>
                        </div>
                        
                        <div
                            className='mdcontent fo_sr11'
                        >
                            {this.props.bodya === true ?
                                <div
                                    className='minternoa '
                                >
                                    {this.props.bodymsg.pa1 === '' ?
                                        ''
                                    :<div
                                        className='minternoaa'
                                    >
                                        {this.props.bodymsg.pa1}
                                    </div>}
                                    {this.props.bodymsg.pa2 === '' ?
                                        ''
                                    :<div
                                        className='minternoab'
                                    >
                                        {this.props.bodymsg.pa2}
                                    </div>}
                                    {this.props.bodymsg.pa3 === '' ?
                                        ''
                                    :<div
                                        className='minternoac'
                                    >
                                        {this.props.bodymsg.pa3}
                                    </div>}
                                    {this.props.bodymsg.pa4 === '' ?
                                        ''
                                    :<div
                                        className='minternoad'
                                    >
                                        {this.props.bodymsg.pa4}
                                    </div>}
                                    {this.props.bodymsg.rec === '' ?
                                        ''
                                    :<div
                                        className='minternoae'
                                    >
                                        {this.props.bodymsg.rec}
                                    </div>}

                                </div>
                            :'' }
                            {this.props.bodyb === true ?
                                <div
                                    className='minternob '
                                >
                                    {this.props.bodyest}
                                </div>
                            :'' }
                        </div>
                        <div
                            className='mdbuttons'
                        >   
                            {this.props.footer === true ?
                                <>
                                    {this.props.bcanac === true ?
                                        <div 
                                            className='mdbuttonred fo_sr12 hep100 dig jucoce alitce'
                                            onClick={this.props.bcance === ''? this.props.bclose : this.props.bcance} 
                                        > 
                                            <div
                                                className=''
                                            >
                                                {this.props.cancel}
                                            </div>
                                        </div>
                                    :''}
                                    {this.props.boptac === true ?
                                        <div 
                                            className='mdbuttonpurple fo_sr12 hep100 dig jucoce alitce'
                                            onClick={this.props.bopton === ''? this.props.bclose : this.props.bopton} 
                                        >
                                            <div
                                                className=''
                                            >
                                                {this.props.option}
                                            </div>
                                        </div>
                                    :''}
                                    {this.props.baccac === true ?
                                        <div 
                                            className='mdbuttonblue fo_sr12 hep100 dig jucoce alitce'
                                            onClick={this.props.bacept}
                                        >
                                            <div
                                                className=''
                                            >
                                                {this.props.accept}
                                            </div>
                                        </div>
                                    :''}
                                </>
                            :''}
                        </div>
                    </div>
                </div>
            :''
		)
	}
}
export default observer(Modal)