import MUO_str									from "../storage/MUO_str"

import Auxiliar 								from '../../../fun/fun'
import MUO_APIs									from "./MUO_APIs"
// import dateFormat 						from 'dateformat'

// // import md5 								from 'js-md5'
// import forge 							from "node-forge"
// import {Buffer} 						from 'buffer'

// import { JSEncrypt } from 'jsencrypt'

class MUO_functions {
	//validation basica
	validation(a, b, c){
		MUO_str.caa('bada', '')
		MUO_str.caa('baea', '')
		MUO_str.caa('bafa', '')
		MUO_str.caa('baga', '')
		MUO_str.caa('baha', '')
		MUO_str.caa('baia', '')
		// if (a === 'bad'){
		// 	if (b === '0'){
		// 		MUO_str.caa('bada', MUO_str.aad.ab032)
		// 		return
		// 	}
		// }
		// if (a === 'bae'){
		// 	if (b.length > MUO_str.aac[0].baeb){
		// 		return
		// 	}
		// 	if (!Auxiliar.regex_validator(b, 4)) {
		// 		return
		// 	}
		// }
		if (a === 'baf'){
			if (b.length > MUO_str.aac[0].bafb){
				return
			}
			if (!Auxiliar.regex_validator(b, 4)) {
				return
			}
		}
		if (a === 'bag'){
			if (b.length > MUO_str.aac[0].bagb){
				return
			}
			if (!Auxiliar.regex_validator(b, 4)) {
				return
			}
		}
		// if (a === 'bah'){
		// 	if (b.length > MUO_str.aac[0].bahb){
		// 		return
		// 	}
		// 	if (!Auxiliar.regex_validator(b, 2)) {
		// 		return
		// 	}
		// }
		MUO_str.caa(a,b)
	}
	validacion_registro(a,b){ // respuesta de validaciones
		// // validar país
		// if (MUO_str.aac[0].bad === 0) {
		// 	MUO_str.caa('bada', MUO_str.aad.ab032)
		// 	return
		// }
		// if (!Auxiliar.regex_validator(MUO_str.aac[0].bad, 2)) {
		// 	MUO_str.caa('bada', MUO_str.aad.ab033)
		// 	return
		// }
		// // validar  DNI
		// if (MUO_str.aac[0].bae.toString().trim().length < 1) {
		// 	MUO_str.caa('baea', MUO_str.aad.ab034)
		// 	return
		// }
		// if (MUO_str.aac[0].bae.length < MUO_str.aac[0].baec){
		// 	MUO_str.caa('baea', MUO_str.aad.ab035)
		// 	return
		// }
		// if (MUO_str.aac[0].bae.length < MUO_str.aac[0].baeb){
		// 	MUO_str.caa('baea', MUO_str.aad.ab036)
		// }
		// validar ID
		if (MUO_str.aac[0].baf.toString().trim().length < 1) {
			MUO_str.caa('bafa', MUO_str.aad.ab037)
			return
		}
		if (MUO_str.aac[0].baf.length < MUO_str.aac[0].bafc){
			MUO_str.caa('bafa', MUO_str.aad.ab038)
			return
		}
		// validar contraseña
		if (MUO_str.aac[0].bag.toString().trim().length < 1) {
			MUO_str.caa('baga', MUO_str.aad.ab039)
			return
		}
		if (MUO_str.aac[0].bag.length < MUO_str.aac[0].bagc){
			MUO_str.caa('baga', MUO_str.aad.ab040)
			return
		}
		// validar numero celular
		// if (MUO_str.aac[0].bah.toString().trim().length < 1) {
		// 	MUO_str.caa('baha', MUO_str.aad.ab041)
		// 	return
		// }
		// if (MUO_str.aac[0].bah.length < MUO_str.aac[0].bahc){
		// 	MUO_str.caa('baha', MUO_str.aad.ab042)
		// 	return
		// }
		// if (!Auxiliar.regex_validator(MUO_str.aac[0].bah, 2)) {
		// 	MUO_str.caa('baha', MUO_str.aad.ab043)
		// 	return
		// }
		// validar correo electronico
		if (MUO_str.aac[0].bai.toString().trim().length < 1) {
			MUO_str.caa('baia', MUO_str.aad.ab044)
			return
		}
		if (!Auxiliar.regex_validator(MUO_str.aac[0].bai, 1)) {
			MUO_str.caa('baia', MUO_str.aad.ab045)
			return
		}
		if (!MUO_str.aac[0].oal){
			MUO_str.caa('oal', true)
			MUO_APIs.aaa(1, {
				id_country : MUO_str.aac[0].bad, 
				tdoc : 1, 
				ndoc : MUO_str.aac[0].bae, 
				correo: MUO_str.aac[0].bai, 
				cell : MUO_str.aac[0].bah, 
				user : MUO_str.aac[0].baf,
				pass : MUO_str.aac[0].bag
				}, 'MUO_USER_EMPTY', MUO_str.aac[0].oac === true ? MUO_str.aac[0].oaka : MUO_str.aac[0].oakb, 'POST', (ra)=>{
				MUO_str.aaf = JSON.parse(ra).data
				MUO_str.increment(1001)
				MUO_str.caa('aabd', true)
				MUO_str.caa('oal', false)
			}).catch((e)=>{
				console.log('error API MUO_USER_EMPTY', e)
			})
		}
	}
	select_languaje(a){
		// console.log(a)
		let key = 0
		let idi = {}
		if (a.length > 2){
			for (let lan of MUO_str.aab) {
				if (lan.iso6392 === 'eng'){idi = lan}
				if (a === lan.iso6392 && lan.active === true){
					// console.log(lan.nombre)
					MUO_str.cad(a)
					MUO_str.cab(lan)
					key = 1
				}
			}
			if (key !== 1){
				MUO_str.cad('eng')
				MUO_str.cab(idi)
			}

		} else {
			for (let lan of MUO_str.aab) {
				if (lan.iso6391 === 'en'){idi = lan}
				if (a === lan.iso6391 && lan.active === true){
					// console.log(lan.nombre)
					MUO_str.cad(lan.iso6392)
					MUO_str.cab(lan)
					key = 1
				}
			}
			if (key !== 1){
				MUO_str.cad('eng')
				MUO_str.cab(idi)
			}
		}
	}
	tituloreset(){
		for (let ti of MUO_str.aai) {
			if (MUO_str.aac[0].bakc.Name === ti.Name){
				return 'g_emblem gensimg' + ti.Influence + ti.Class
			}
		}
	}
	pointsreset(){
		for (let ti of MUO_str.aai) {
			if (MUO_str.aac[0].bakc.Name === ti.Name){
				return ti.Points
			}
		}
	}
	classcharacter(a){
		switch (a) {
			case 0:	// inicio
				return 'Dark Wizard'
			break;
			case 1:	// inicio
				return 'Soul Master'
			break;
			case 3:	// inicio
				return 'Grand Master'
			break;
			case 7:	// inicio
				return 'Soul Wizard'
			break;
			case 16:	// inicio
				return 'Dark Knight'
			break;
			case 17:	// inicio
				return 'Blade Knight'
			break;
			case 19:	// inicio
				return 'Blade Master'
			break;
			case 23:	// inicio
				return 'Dragon Knight'
			break;
			case 32:	// inicio
				return 'Fairy Elf'
			break;
			case 33:	// inicio
				return 'Muse Elf'
			break;
			case 35:	// inicio
				return 'Higt Elf'
			break;
			case 39:	// inicio
				return 'Noble Elf'
			break;
			case 48:	// inicio
				return 'Magic Gladiato'
			break;
			case 50:	// inicio
				return 'Duel Master'
			break;
			case 54:	// inicio
				return 'Magic Knight'
			break;
			case 64:	// inicio
				return 'Dark Lord'
			break;
			case 66:	// inicio
				return 'Lord Emperor'
			break;
			case 70:	// inicio
				return 'Empire Lord'
			break;
			case 80:	// inicio
				return 'Summoner'
			break;
			case 81:	// inicio
				return 'Bloody Summoner'
			break;
			case 83:	// inicio
				return 'Dimension Master'
			break;
			case 87:	// inicio
				return 'Dimension Summoner'
			break;
			case 96:	// inicio
				return 'Rage Fighter'
			break;
			case 98:	// inicio
				return 'Fist Master'
			break;
			case 102:	// inicio
				return 'Fist Blazer'
			break;
			case 112:	// inicio
				return 'Grow Lancer'
			break;
			case 114:	// inicio
				return 'Mirage Lancer'
			break;
			case 118:	// inicio
				return 'Shining Lancer'
			break;
			case 128:	// inicio
				return 'Rune Wizard'
			break;
			case 129:	// inicio
				return 'Rune Spell Master'
			break;
			case 131:	// inicio
				return 'Grand Rune Master'
			break;
			case 135:	// inicio
				return 'Majestic Rune Wizard'
			break;
			case 144:	// inicio
				return 'Slayer'
			break;
			case 145:	// inicio
				return 'Royal Slayer'
			break;
			case 147:	// inicio
				return 'Master Slayer'
			break;
			case 151:	// inicio
				return 'Slaughterer'
			break;
			case 160:	// inicio
				return 'Gun Crusher'
			break;
			case 161:	// inicio
				return 'Gun Breaker'
			break;
			case 163:	// inicio
				return 'Master Gun Breaker'
			break;
			case 167:	// inicio
				return 'Hightest Gun Crusher'
			break;
			case 176:	// inicio
				return 'Light Wizard'
			break;
			case 177:	// inicio
				return 'Light Master'
			break;
			case 179:	// inicio
				return 'Shining Wizard'
			break;
			case 183:	// inicio
				return 'Luminous Wizard'
			break;
			case 192:	// inicio
				return 'Lemuria Mage'
			break;
			case 193:	// inicio
				return 'Warmage'
			break;
			case 195:	// inicio
				return 'Archmage'
			break;
			case 199:	// inicio
				return 'Mystic Mage'
			break;
			case 208:	// inicio
				return 'Illusion Knight'
			break;
			case 209:	// inicio
				return 'Mirage Knight'
			break;
			case 211:	// inicio
				return 'Illusion Master'
			break;
			case 215:	// inicio
				return 'Mystic Knight'
			break;
			default:
				return 'No Class'
			break;
		}
	}
	async orderranking(a, b, c){
		return new Promise( (resolve, reject) =>{
			let aa = []			// array polyline
				let aaa = []			// array polyline
				let aab = []			// array polyline
				let aac = []			// array polyline
				let aad = []			// array polyline
				let aae = []			// array polyline
			let ab = []			// array polyline
				let aba = []			// array polyline
				let abb = []			// array polyline
				let abc = []			// array polyline
				let abd = []			// array polyline
				let abe = []			// array polyline
			let ac = []			// nombre de
				let aca = []			// array polyline
				let acb = []			// array polyline
				let acc = []			// array polyline
				let acd = []			// array polyline
				let ace = []			// array polyline
			let ad = []		    // var state motion
				let ada = []			// array polyline
				let adb = []			// array polyline
				let adc = []			// array polyline
				let add = []			// array polyline
			let ae = []			// var estate engine
				let aea = []			// array polyline
				let aeb = []			// array polyline
				let aec = []			// array polyline
				let aed = []			// array polyline
			let af = []			// confirm estate engine
				let afa = []			// array polyline
				let afb = []			// array polyline
				let afc = []			// array polyline
				let afd = []			// array polyline
				let afe = []			// array polyline
			let ag = []			// gettimes - ii diference
				let aga = []			// array polyline
				let agb = []			// array polyline
				let agc = []			// array polyline
				let agd = []			// array polyline
			let ah = []			// data for markers
				let aha = []			// array polyline
				let ahb = []			// array polyline
				let ahc = []			// array polyline
				let ahd = []			// array polyline
			let ai = []			// coordinates
				let aia = []			// array polyline
				let aib = []			// array polyline
				let aic = []			// array polyline
				let aid = []			// array polyline
				let aie = []			// array polyline
			let aj = [] 		// attributes
				let aja = []			// array polyline
				let ajb = []			// array polyline
				let ajc = []			// array polyline
				let ajd = []			// array polyline
				let aje = []			// array polyline
			let ak = []			// titulo o  solo cordenadas
				let aka = []			// array polyline
				let akb = []			// array polyline
				let akc = []			// array polyline
				let akd = []			// array polyline
				let ake = []			// array polyline
			let al = []			// titulo o  solo cordenadas
				let ala = []			// array polyline
				let alb = []			// array polyline
				let alc = []			// array polyline
				let ald = []			// array polyline
				let ale = []			// array polyline
			let am = []			// titulo o  solo cordenadas
				let ama = []			// array polyline
				let amb = []			// array polyline
				let amc = []			// array polyline
				let amd = []			// array polyline
				let ame = []			// array polyline
			let an = []			// titulo o  solo cordenadas
				let ana = []			// array polyline
				let anb = []			// array polyline
				let anc = []			// array polyline
				let and = []			// array polyline
				let ane = []			// array polyline
	
			MUO_str.caa('bak', c[0].Influence)
			if (c[0].Influence === 1) {
				MUO_str.caa('baka', c[0])
			} else {
				MUO_str.caa('bakb', c[0])
			}
			for (var i=0; i<c.length; i++) {
				if(c[i].Influence !== c[0].Influence){
					if (c[i].Influence === 1) {
						MUO_str.caa('baka', c[i])
						break;
					} else {
						MUO_str.caa('bakb', c[i])
						break;
					}
				}
			}
			MUO_str.caa('bakc', b[0])
			Promise.all(b.map((e, i)=>{
				if (e.Class <= 15 & 0 <= e.Class){
					aa.push(e)
					switch (e.Class) {
						case 0:	// inicio
							aaa.push(e)
						break;
						case 1:	// inicio
							aab.push(e)
						break;	
						case 3:	// inicio
							aac.push(e)
						break;
						case 7:	// inicio
							aad.push(e)
						break;
						default:
							aae.push(e)
						break;
					}
				}
				if (e.Class <= 31 & 16 <= e.Class){
					ab.push(e)
					switch (e.Class) {
						case 16:	// inicio
							aba.push(e)
						break;
						case 17:	// inicio
							abb.push(e)
						break;	
						case 19:	// inicio
							abc.push(e)
						break;
						case 23:	// inicio
							abd.push(e)
						break;
						default:
							abe.push(e)
						break;
					}
				}
				if (e.Class <= 47 & 32 <= e.Class){
					ac.push(e)
					switch (e.Class) {
						case 32:	// inicio
							aca.push(e)
						break;
						case 33:	// inicio
							acb.push(e)
						break;	
						case 35:	// inicio
							acc.push(e)
						break;
						case 39:	// inicio
							acd.push(e)
						break;
						default:
							ace.push(e)
						break;
					}
				}
				if (e.Class <= 63 & 48 <= e.Class){
					ad.push(e)
					switch (e.Class) {
						case 48:	// inicio
							ada.push(e)
						break;
						case 50:	// inicio
							adb.push(e)
						break;	
						case 54:	// inicio
							adc.push(e)
						break;
						default:
							add.push(e)
						break;
					}
				}
				if (e.Class <= 79 & 64 <= e.Class){
					ae.push(e)
					switch (e.Class) {
						case 64:	// inicio
							aea.push(e)
						break;
						case 66:	// inicio
							aeb.push(e)
						break;	
						case 70:	// inicio
							aec.push(e)
						break;
						default:
							aed.push(e)
						break;
					}
				}
				if (e.Class <= 95 & 80 <= e.Class){
					af.push(e)
					switch (e.Class) {
						case 80:	// inicio
							afa.push(e)
						break;
						case 81:	// inicio
							afb.push(e)
						break;	
						case 83:	// inicio
							afc.push(e)
						break;
						case 87:	// inicio
							afd.push(e)
						break;
						default:
							afe.push(e)
						break;
					}
				}	
				if (e.Class <= 111 & 96 <= e.Class){
					ag.push(e)
					switch (e.Class) {
						case 96:	// inicio
							aga.push(e)
						break;
						case 98:	// inicio
							agb.push(e)
						break;	
						case 102:	// inicio
							agc.push(e)
						break;
						default:
							agd.push(e)
						break;
					}
				}
				if (e.Class <= 127 & 112 <= e.Class){
					ah.push(e)
					switch (e.Class) {
						case 112:	// inicio
							aha.push(e)
						break;
						case 114:	// inicio
							ahb.push(e)
						break;	
						case 118:	// inicio
							ahc.push(e)
						break;
						default:
							ahd.push(e)
						break;
					}
				}
				if (e.Class <= 143 & 128 <= e.Class){
					ai.push(e)
					switch (e.Class) {
						case 128:	// inicio
							aia.push(e)
						break;
						case 129:	// inicio
							aib.push(e)
						break;	
						case 131:	// inicio
							aic.push(e)
						break;
						case 135:	// inicio
							aid.push(e)
						break;
						default:
							aie.push(e)
						break;
					}
				}
				if (e.Class <= 159 & 144 <= e.Class){
					aj.push(e)
					switch (e.Class) {
						case 144:	// inicio
							aja.push(e)
						break;
						case 145:	// inicio
							ajb.push(e)
						break;	
						case 147:	// inicio
							ajc.push(e)
						break;
						case 151:	// inicio
							ajd.push(e)
						break;
						default:
							aje.push(e)
						break;
					}
				}
				if (e.Class <= 175 & 160 <= e.Class){
					ak.push(e)
					switch (e.Class) {
						case 160:	// inicio
							aka.push(e)
						break;
						case 161:	// inicio
							akb.push(e)
						break;	
						case 163:	// inicio
							akc.push(e)
						break;
						case 167:	// inicio
							akd.push(e)
						break;
						default:
							ake.push(e)
						break;
					}
				}
				if (e.Class <= 191 & 176 <= e.Class){
					al.push(e)
					switch (e.Class) {
						case 176:	// inicio
							ala.push(e)
						break;
						case 177:	// inicio
							alb.push(e)
						break;	
						case 179:	// inicio
							alc.push(e)
						break;
						case 183:	// inicio
							ald.push(e)
						break;
						default:
							ale.push(e)
						break;
					}
				}
				if (e.Class <= 207 & 192 <= e.Class){
					am.push(e)
					switch (e.Class) {
						case 192:	// inicio
							ama.push(e)
						break;
						case 193:	// inicio
							amb.push(e)
						break;	
						case 195:	// inicio
							amc.push(e)
						break;
						case 199:	// inicio
							amd.push(e)
						break;
						default:
							ame.push(e)
						break;
					}
				}
				if (e.Class <= 220 & 208 <= e.Class){
					an.push(e)
					switch (e.Class) {
						case 208:	// inicio
							ana.push(e)
						break;
						case 209:	// inicio
							anb.push(e)
						break;	
						case 211:	// inicio
							anc.push(e)
						break;
						case 215:	// inicio
							and.push(e)
						break;
						default:
							ane.push(e)
						break;
					}
				}
			})).then (()=>{
				try {
					MUO_str.caf({
						db   	: b,
						aa		: aa,
						aaa		: aaa,
						aab		: aab,
						aac		: aac,
						aad		: aad,
						aae		: aae,
						ab		: ab,
						aba		: aba,
						abb		: abb,
						abc		: abc,
						abd		: abd,
						abe		: abe,
						ac		: ac,
						aca		: aca,
						acb		: acb,
						acc		: acc,
						acd		: acd,
						ace		: ace,
						ad		: ad,
						ada		: ada,
						adb		: adb,
						adc		: adc,
						add		: add,
						ae		: ae,
						aea		: aea,
						aeb		: aeb,
						aec		: aec,
						aed		: aed,
						af		: af,
						afa		: afa,
						afb		: afb,
						afc		: afc,
						afd		: afd,
						afe		: afe,
						ag		: ag,
						aga		: aga,
						agb		: agb,
						agc		: agc,
						agd		: agd,
						ah		: ah,
						aha		: aha,
						ahb		: ahb,
						ahc		: ahc,
						ahd		: ahd,
						ai		: ai,
						aia		: aia,
						aib		: aib,
						aic		: aic,
						aid		: aid,
						aie		: aie,
						aj		: aj,
						aja		: aja,
						ajb		: ajb,
						ajc		: ajc,
						ajd		: ajd,
						aje		: aje,
						ak		: ak,
						aka		: aka,
						akb		: akb,
						akc		: akc,
						akd		: akd,
						ake		: ake,
						al		: al,
						ala		: ala,
						alb		: alb,
						alc		: alc,
						ald		: ald,
						ale		: ale,
						am		: am,
						ama		: ama,
						amb		: amb,
						amc		: amc,
						amd		: amd,
						ame		: ame,
						an		: an,
						ana		: ana,
						anb		: anb,
						anc		: anc,
						and		: and,
						ane		: ane
					})
					resolve(true)
				} catch (e){
					reject(false)
					console.log(e, 'error b_e')
				}
		
			})

		})
	}
	formlogin(a, b, c, d) {
		MUO_str.caa('balc', 0)
		MUO_str.caa('bald', 0)
		if (c === 2){
			b = MUO_str.aac[0].bala + b
		}

		if (a === 'bala'){
			if (b.length > MUO_str.aac[0].bafb){
				return
			}
			if (!Auxiliar.regex_validator(b, 4)) {
				return
			}
		}
		if (a === 'balb'){
			if (b.length > MUO_str.aac[0].bagb){
				return
			}
			if (!Auxiliar.regex_validator(b, 4)) {
				return
			}
		}
		MUO_str.caa(a, b)
	}
	keyevent(a,b){
		console.log(a, b)
	}
	formchecket(a) {
		if (a.currentTarget.checked) {
			MUO_str.caa('balg', 'checked')
		} else {
			MUO_str.caa('balg', '')
		}
	}
	validacion_login(a,b){
		if (MUO_str.aac[0].bala.length < MUO_str.aac[0].bafc){
			MUO_str.caa('bala', '')
			MUO_str.caa('balc', 7)
			return
		}
		
		if (MUO_str.aac[0].balb.length < MUO_str.aac[0].bagc){
			MUO_str.caa('balb', '')
			MUO_str.caa('bald', 6)
			return
		}
		MUO_str.increment(5, {sub : 2})
	}
	buys(a, val) {
		return new Promise((resolve, reject) =>{
			if (a === 1){
				if (!MUO_str.aac[0].oal){
					MUO_str.caa('oal', true)
					MUO_APIs.aaa(1, {
						cite : MUO_str.aak.Cod_item,
						tiit : MUO_str.aak.Tipe_item,
						pric : MUO_str.aak.Price,
						desc : MUO_str.aad['' + MUO_str.aak.Description + ''],
						priw : MUO_str.aak.Price_web,
						memb : MUO_str.aac[0].bale,
						serv : MUO_str.aac[0].bama,
						char : MUO_str.aac[0].bamb,
						idpe : MUO_str.aak.Chars === 'all'? MUO_str.aak.Chars : MUO_str.aac[0].bame,
	
						}, 'MUO_BUY', MUO_str.aac[0].oac === true ? MUO_str.aac[0].oaka : MUO_str.aac[0].oakb, 'POST', (ra)=>{
							let ps = JSON.parse(ra).data
							// console.log(ps.msg.da)
							if (ps.status === true) {
								resolve(ps.msg.da)
							} else if (ps.status === false) {
								console.log(ps)
								window.location.reload()
							} else if (ps.status === 'limit') {
								MUO_str.aak.limit = ps.msg
								Auxiliar.modal_control(0, {var1 : 'cah', var2 : 'ccc', e : true})
							}
						MUO_str.caa('oal', false)
					}).catch((e)=>{
						console.log('error API MUO_BUY', e)
					})
				}
			}
			if (a === 2){
				if (!MUO_str.aac[0].oal){
					MUO_str.caa('oal', true)
					MUO_APIs.aaa(1, {
						paye : val.payerID,
						paym : val.paymentID,
						pams : val.paymentSource
						}, 'MUO_BUY_COMPLETE', MUO_str.aac[0].oac === true ? MUO_str.aac[0].oaka : MUO_str.aac[0].oakb, 'POST', (ra)=>{
							console.log(ra)
							// Auxiliar.modal_control(0, {var1 : 'cag', e : false})
							Auxiliar.modal_control(0, {var1 : 'cah', var2 : 'ccc', e : true})
						MUO_str.caa('oal', false)
					})
				}
			}
		})
	}
}
export default new MUO_functions();