import React 									from 'react'
import { observer }								from 'mobx-react'
import ReactPlayer 								from 'react-player'

// import { PDFObject } 					        from 'react-pdfobject'


// import Eventscolas 								from '../../../fun/js'
import Auxiliar 								from '../../../fun/fun'
import MUO_str 									from '../storage/MUO_str'


import {Link} 								from "react-router-dom"


class Home extends React.Component {

	render () {

		return(<>
			<div
				className='toggled-element-left light home'
			>
				<div
					className='leftblocks'
				>
					<div
						className='sgservers'
					>
						<div
							className='sgserverstit'
						>
							<div
								className=''
							>
								{MUO_str.aad.ag001}
							</div>
						</div>
						<div
							className='sgserverscont'
						>
							<div
								className='serveractive'
							>
								<div
									className='serveroriggentit'
								>
									<div
										className=''
									>
										{MUO_str.aad.aga001}
									</div>
								</div>
								<div
									className='serveroriggenimg server1'
								>
								</div>
								<div
									className='serveroriggenlan'
								>
									<div
										className=''
									>
										{MUO_str.aad.aga002}
									</div>
								</div>
								<div
									className='serveroriggenver'
								>
									<div
										className=''
									>
										{MUO_str.aad.aga003}
									</div>
								</div>
								<div
									className='serveroriggenexp'
								>
									<div
										className=''
									>
										{MUO_str.aad.aga004}
									</div>
								</div>
								<div
									className='serveroriggenvex'
								>
									<div
										className=''
									>
										{MUO_str.aad.aga005}
									</div>
								</div>
								<div
									className='serveroriggenrrl'
								>
									<div
										className=''
									>
										{MUO_str.aad.aga006}
									</div>
								</div>
								<div
									className='serveroriggenmal'
								>
									<div
										className=''
									>
										{MUO_str.aad.aga013}
									</div>
								</div>
								<div
									className='serveroriggense1'
								>
									<div
										className=''
									>
										{MUO_str.aad.aga007}
									</div>
								</div>
								<div
									className='serveroriggense2'
								>
									<div
										className=''
									>
										{MUO_str.aad.aga008}
									</div>
								</div>
								<div
									className='serveroriggense3'
								>
									<div
										className=''
									>
										{MUO_str.aad.aga009}
									</div>
								</div>
								<div
									className='serveroriggense4'
								>
									<div
										className=''
									>
										{MUO_str.aad.aga010}
									</div>
								</div>
								<div
									className='serveroriggense5'
								>
									<div
										className=''
									>
										{MUO_str.aad.aga011}
									</div>
								</div>
								<div
									className='serveroriggense6'
								>
									<div
										className=''
									>
										{MUO_str.aad.aga012}
									</div>
								</div>
							</div>
							<div
								className='serveractive'
							>
								<div
									className='serveroriggentit'
								>
									<div
										className=''
									>
										{MUO_str.aad.agb001}
									</div>
								</div>
								<div
									className='serveroriggenimg server2'
								>
								</div>
								<div
									className='serveroriggenlan'
								>
									<div
										className=''
									>
										{MUO_str.aad.agb002}
									</div>
								</div>
								<div
									className='serveroriggenver'
								>
									<div
										className=''
									>
										{MUO_str.aad.agb003}
									</div>
								</div>
								<div
									className='serveroriggenexp'
								>
									<div
										className=''
									>
										{MUO_str.aad.agb004}
									</div>
								</div>
								<div
									className='serveroriggenvex'
								>
									<div
										className=''
									>
										{MUO_str.aad.agb005}
									</div>
								</div>
								<div
									className='serveroriggenrrl'
								>
									<div
										className=''
									>
										{MUO_str.aad.agb006}
									</div>
								</div>
								<div
									className='serveroriggenmal'
								>
									<div
										className=''
									>
										{MUO_str.aad.agb013}
									</div>
								</div>
								<div
									className='serveroriggense1'
								>
									<div
										className=''
									>
										{MUO_str.aad.agb007}
									</div>
								</div>
								<div
									className='serveroriggense2'
								>
									<div
										className=''
									>
										{MUO_str.aad.agb008}
									</div>
								</div>
								<div
									className='serveroriggense3'
								>
									<div
										className=''
									>
										{MUO_str.aad.agb009}
									</div>
								</div>
								<div
									className='serveroriggense4'
								>
									<div
										className=''
									>
										{MUO_str.aad.agb010}
									</div>
								</div>
								<div
									className='serveroriggense5'
								>
									<div
										className=''
									>
										{MUO_str.aad.agb011}
									</div>
								</div>
								<div
									className='serveroriggense6'
								>
									<div
										className=''
									>
										{MUO_str.aad.agb012}
									</div>
								</div>
							</div>
							
						</div>
						{/* otro server */}

						
					</div>
					{/* otro bloque */}
					<div
						className='asfasfaas'
					>
					
					</div>
				
				</div>
				

				<div
					className='midblocks'
				>
					<div
						className='sevents'
					>
						<div
							className='seventstit'
						>
							<div
								className=''
							>
								{MUO_str.aad.ag002}
							</div>
						</div>
						<div
							className='seventscont'
						>
							<div
								className='seventsactive'
							>
								<div
									className='seventsacttit'
								>
									<div
										className=''
									>
										{MUO_str.aad.agc001}
									</div>
								</div>
								<div
									className='seventsactvid'
									style={ {height:400/16*9}}
								>	

									<ReactPlayer
										url={MUO_str.aad.agc002}
										playing={false}
										volume={0.800}
										speed={1}
										width={400}
										height={400/16*9}
									/>
								</div>
								<div
									className='seventsactdes'
								>
									<div
										className=''
									>
										{MUO_str.aad.agc003}
									</div>
								</div>
								<div
									className='seventsactdat'
								>
									<div
										className=''
									>
										{MUO_str.aad.agc004}
									</div>
								</div>
							</div>
						</div>
						{/* otro server */}

						
					</div>
					{/* otro bloque */}
					<div
						className='asfasfaas'
					>
					
					</div>
				
				</div>
				
				{/* right block */}

				<div
					className='rightblocks'
				>
					<div
						className='micromods'
					>
						<Link 
							className='microbutregister'
							to={`../register`}
						>
							<div
								className=''
							>
								{MUO_str.aad.aa003}
							</div>
						</Link>
						<Link 
							className='microbutshop'
							to={`../shop`}
						>
							<div
								className=''
							>
								{MUO_str.aad.aa011}
							</div>
						</Link>
						<div
							className='microbutdownload cupo'
							onClick={()=> Auxiliar.modal_control(0, {var1 : 'caf', var2 : 'ccc', e : true})}
						>
							<div
								className=''
							>
								{MUO_str.aad.aa009}
							</div>
						</div>
						{/* <div
							className='microbutshop'
						>
							<div
								className=''
							>
								{MUO_str.aad.aa011}
							</div>
						</div> */}
						{/* otro server */}

						
					</div>
					{/* otro bloque */}
					<div
						className='asfasfaas'
					>
					
					</div>
				
				</div>
				
			</div>
 		</>)
	}
}
export default observer(Home)