import React 									from 'react'
import { observer }								from 'mobx-react'


import MUO_str 									from '../storage/MUO_str'




class Shop_spot extends React.Component {
	
	render () {
 		return(<>
			<div
				className='toggled-element-left light shop_spot'
			>	
				<div
					className='img hep100'
				>
					<div
						className='stepsone'
					>
						<div
							className=''
						>
							{MUO_str.aad.af013}	
						</div>
					</div>
					<div
						className='stepsone_details'
					>
						<div
							className=''
						>
							{MUO_str.aad.af023}	
						</div>
					</div>
					<div
						className='stepstwo'
					>
						<div
							className=''
						>
							{MUO_str.aad.af014}		
						</div>
					</div>
					<div
						className='stepstwo_details'
					>
						<div
							className=''
						>
							{MUO_str.aad.af024}	
						</div>
					</div>
					
					
				</div>
			</div>

 		</>)
	}
}
export default observer(Shop_spot)