import React 									from 'react'
import { observer }								from 'mobx-react'

import Eventscolas 								from '../../../fun/js'

import Auxiliar 								from '../../../fun/fun'

import MUO_functions 							from '../js/MUO_fun'
import MUO_str 									from '../storage/MUO_str'

import Apiserver								from "../js/MUO_APIs"
import fun 										from '../../../fun/fun'


let aaa

class Ranking extends React.Component {
	if (aaa) {
		// already mounted previously
		return;
	}
	
	render () {
		return(<>
			<div
				className='toggled-element-left light loading_content'
			>
				<div
					className='loading-img'
				>
				</div>
				<div
					className='loading-text'
				>
					{MUO_str.aad.ab061}
				</div>
			</div>
 		</>)
	}
}

// ""
//             ""
//             ""
//             ""
//             ""
//             ""
//             ""
//             ""
//             ""
//             "";
export default observer(Ranking)