import React 									from 'react'
import { observer }								from 'mobx-react'
import { PDFObject } 					        from 'react-pdfobject'

import Auxiliar 								from '../../fun/fun'
// import Eventscolas 								from '../../fun/js'
import MUO_str 									from './storage/MUO_str'

import MUO_fun 							from './js/MUO_fun'

import Start_web  								from './CGPS_start'
import Modal 									from './modal'
import QRCode 									from "react-qr-code"

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import '../../css/sections.css'
import '../../css/globales.css'
import '../../css/tree.css'
//import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import '../../fnt/style.css'

let aaa
class Muapp extends React.Component {
	componentDidMount() {
		if (aaa) {
			// already mounted previously
			return;
		}
		let ln = navigator.language || navigator.userLanguage;
		let lang = ln.split('-')
		// console.log(ln, navigator)
		aaa = true
		MUO_fun.select_languaje(lang[0])
		// MUO_str.increment(1)
		let COnline = localStorage.getItem('muoriggen')
		if (COnline !== null){
			let ps = JSON.parse(COnline)
			MUO_str.cai(ps)
			MUO_str.caa('bale', ps.da[0].memb___id)
		}
	}


	render(){
		// {setTimeout(function(){
			
		// }, 2000);}
		return (<>
		{MUO_str.aac[0].caa?
			<Modal
				id='lrep'
				// show={MUO_str.aac[0].cba? true : false}
				getOpenState={(e) =>Auxiliar.modal_control(0, {var1 : 'caa', var2 : 'caaa', e : e})}
				icon='icon-pacman'
				clasn1 = 'hep30 wip70'
				title = {MUO_str.aad.ab023}
				bclose = {() =>Auxiliar.modal_control(0, {var1 : 'caa', e : false})}
				bodya ={false}
				bodymsg = {{
					pa1 : '',
					pa2 : '',
					pa3 : '',
					pa4 : '',
					rec : ''
				}}
				bodyb = {true}
				bodyest = {
					MUO_str.aad.ab022
				}
				footer = {true}
				bopton = {''}
				bacept = {() =>Auxiliar.modal_control(0, {var1 : 'caa', e : false})}
				bcance = {''} //parametro adcional al cancelar por defecto solo cierra
				option = ''
				accept = {MUO_str.aad.ab024}
				cancel = ''
				boptac = {false}
				baccac = {true}
				bcanac = {false}
			>
			</Modal>
		:''}
		{MUO_str.aac[0].cab?
			<Modal
				id='tdmuo'
				// show={MUO_str.aac[0].cba? true : false}
				getOpenState={(e) =>Auxiliar.modal_control(0, {var1 : 'cab', var2 : 'caba', e : e})}
				icon='icon-pacman'
				clasn1 = 'hep80 wip70'
				title = {MUO_str.aad.ab025}
				bclose = {() =>Auxiliar.modal_control(0, {var1 : 'cab', e : false})}
				bodya ={false}
				bodymsg = {{
					pa1 : '',
					pa2 : '',
					pa3 : '',
					pa4 : '',
					rec : ''
				}}
				bodyb = {true}
				bodyest = {
					<PDFObject url={MUO_str.aad.ab021} />
				}
				footer = {false}
				bopton = {''}
				bacept = {''}
				bcance = {''} //parametro adcional al cancelar por defecto solo cierra
				option = {''}
				accept = {''}
				cancel = {''}
				boptac = {false}
				baccac = {false}
				bcanac = {false}
			>
			</Modal>
		:''}
		{MUO_str.aac[0].cac?
			<Modal
				id='pdmuo'
				// show={MUO_str.aac[0].cba? true : false}
				getOpenState={(e) =>Auxiliar.modal_control(0, {var1 : 'cac', var2 : 'caca', e : e})}
				icon='icon-pacman'
				clasn1 = 'hep80 wip70'
				title = {MUO_str.aad.ab026}
				bclose = {() =>Auxiliar.modal_control(0, {var1 : 'cac', e : false})}
				bodya ={false}
				bodymsg = {{
					pa1 : '',
					pa2 : '',
					pa3 : '',
					pa4 : '',
					rec : ''
				}}
				bodyb = {true}
				bodyest = {
					<PDFObject url={MUO_str.aad.ab020} />
				}
				footer = {false}
				bopton = {''}
				bacept = {''}
				bcance = {''} //parametro adcional al cancelar por defecto solo cierra
				option = {''}
				accept = {''}
				cancel = {''}
				boptac = {false}
				baccac = {false}
				bcanac = {false}
			>
			</Modal>
		:''}
		{MUO_str.aac[0].cad?
			<Modal
				id='atpcmuo'
				// show={MUO_str.aac[0].cba? true : false}
				getOpenState={(e) =>Auxiliar.modal_control(0, {var1 : 'cad', var2 : 'cada', e : e})}
				icon='icon-pacman'
				clasn1 = 'hep40 wip70'
				title = {MUO_str.aad.ab023}
				bclose = {() =>Auxiliar.modal_control(0, {var1 : 'cad', e : false})}
				bodya ={true}
				bodymsg = {{
					pa1 : MUO_str.aac[0].baa === 1 ? '' : MUO_str.aad.ab027,
					pa2 : MUO_str.aac[0].bab === 1 ? '' : MUO_str.aad.ab028,
					pa3 : MUO_str.aac[0].bac === 1 ? '' : MUO_str.aad.ab029,
					pa4 : '',
					rec : ''
				}}
				bodyb = {true}
				bodyest = {
					MUO_str.aad.ab031
				}
				footer = {true}
				bopton = {''}
				bacept = {() =>Auxiliar.modal_control(0, {var1 : 'cad', e : false})}
				bcance = {''} //parametro adcional al cancelar por defecto solo cierra
				option = ''
				accept = {MUO_str.aad.ab024}
				cancel = ''
				boptac = {false}
				baccac = {true}
				bcanac = {false}
			>
			</Modal>
		:''}
		{MUO_str.aac[0].cae? // modal libre para reutilizarse
			<Modal
				id='atpcmuo'
				// show={MUO_str.aac[0].cba? true : false}
				getOpenState={(e) =>Auxiliar.modal_control(0, {var1 : 'cae', var2 : 'caea', e : e})}
				icon='icon-pacman'
				clasn1 = 'hep40 wip70'
				title = {MUO_str.aad.ab023}
				bclose = {() =>Auxiliar.modal_control(0, {var1 : 'cae', e : false})}
				bodya ={true}
				bodymsg = {{
					pa1 : MUO_str.aad.ab055.replace('%u', MUO_str.aaf.db.memb___id),
					pa2 : MUO_str.aad.ab051 + ' ' + MUO_str.aaf.db.memb__pwd,
					pa3 : MUO_str.aad.ab052 + ' ' + MUO_str.aaf.db.phon_numb,
					pa4 : MUO_str.aad.ab053 + ' ' + MUO_str.aaf.db.mail_addr,
					rec : ''
				}}
				bodyb = {true}
				bodyest = {
					MUO_str.aad.ab056
				}
				footer = {true}
				bopton = {''}
				bacept = {() =>Auxiliar.modal_control(0, {var1 : 'cae', e : false})}
				bcance = {''} //parametro adcional al cancelar por defecto solo cierra
				option = ''
				accept = {MUO_str.aad.ab024}
				cancel = ''
				boptac = {false}
				baccac = {true}
				bcanac = {false}
			>
			</Modal>
		:''}
		{MUO_str.aac[0].caf?
			<Modal
				id='atpcmuo'
				// show={MUO_str.aac[0].cba? true : false}
				getOpenState={(e) =>Auxiliar.modal_control(0, {var1 : 'caf', var2 : 'cafa', e : e})}
				icon='icon-pacman'
				clasn1 = 'hep40 wip70'
				title = {MUO_str.aad.ab023}
				bclose = {() =>Auxiliar.modal_control(0, {var1 : 'caf', e : false})}
				bodya ={true}
				bodymsg = {{
					pa1 : MUO_str.aad.ab057.replace('%i', MUO_str.aac[0].baca),
					pa2 : MUO_str.aad.ab058.replace('%i', MUO_str.aac[0].baca),
					pa3 : MUO_str.aad.ab059,
					pa4 : MUO_str.aad.ab060,
					rec : ''
				}}
				bodyb = {true}
				bodyest = { <a href={MUO_str.aac[0].bacd} target="_blank" onclick="window.open(this.href, this.target, 'width=900,height=800'); return false;">{MUO_str.aad.ab062.replace('%i', MUO_str.aac[0].bacb)}</a>
					
				}
				footer = {true}
				bopton = {''}
				bacept = {() =>Auxiliar.modal_control(0, {var1 : 'caf', e : false})}
				bcance = {''} //parametro adcional al cancelar por defecto solo cierra
				option = ''
				accept = {MUO_str.aad.ab024}
				cancel = ''
				boptac = {false}
				baccac = {true}
				bcanac = {false}
			>
			</Modal>
		:''}
		{MUO_str.aac[0].cag?
			<Modal
				id='atpcmuo'
				// show={MUO_str.aac[0].cba? true : false}
				getOpenState={(e) =>Auxiliar.modal_control(0, {var1 : 'cag', var2 : 'caga', e : e})}
				icon='icon-pacman'
				clasn1 = 'hep70 wip70'
				title = {MUO_str.aad.ab023}
				bclose = {() =>Auxiliar.modal_control(0, {var1 : 'cag', e : false})}
				bodya ={true}
				bodymsg = {{
					pa1 : MUO_str.aad.af004.replace('%i', MUO_str.aad['' + MUO_str.aak.Name_item + ''] ),
					pa2 : MUO_str.aad['' + MUO_str.aak.Description + ''],
					pa3 : MUO_str.aad.af005.replace('%i', MUO_str.aak.Cod_item),
					pa4 : MUO_str.aad.af006.replace('%i', MUO_str.aak.Price_web),
					rec : ''
				}}
				bodyb = {true}
				bodyest = {
					<div
								className='buttondonation'
					>
						<div
							className={MUO_str.aak.Price === 0 ? 'do_yape cupo imgfree' :'do_yape cupo imgyape'}
							onClick={()=>
								MUO_str.aak.Price === 0 ? 
									MUO_fun.buys(1).then ((da)=>{
										// console.log(da, 'MUO-00105', new Date()) // ; return
										Auxiliar.modal_control(0, {var1 : 'cah', var2 : 'ccc', e : true})
								
									}).catch((e)=>{ 
										console.log(e, 'ERROR-MUO-00076' , new Date())
									})
								:
									MUO_str.caa('bamc', 'yape')
							}
						>
							
						</div>
						<div
							className={MUO_str.aak.Price === 0 ? 'do_paypal cupo imgfree' :'do_paypal cupo imgpaypal'}
							onClick={()=> 
								MUO_str.aak.Price === 0 ? 
									MUO_fun.buys(1).then ((da)=>{
										// console.log(da, 'MUO-00105', new Date()) // ; return
										Auxiliar.modal_control(0, {var1 : 'cah', var2 : 'ccc', e : true})
								
									}).catch((e)=>{
										console.log(e, 'ERROR-MUO-00076' , new Date())
									})
								:
									MUO_str.caa('bamc', 'payp')}
						>
							
						</div>
						<div
							className='scan'
						>
							{MUO_str.aak.Price === 0 ?
								<div
									className='alltile'
								>
									<div
										className='chicksquare impollo0'
									>
									</div>
									<div
										className='chicksquare impollo16'
									>
									</div>
									<div
										className='chicksquare impollo32'
									>
									</div>
									<div
										className='chicksquare impollo80'
									>
									</div>
									<div
										className='chicksquare impollo112'
									>
									</div>
									<div
										className='chicksquare impollo144'
									>
									</div>
									<div
										className='chicksquare impollo176'
									>
									</div>
								</div>
							:''}
							
							{MUO_str.aac[0].bamc === 'yape'?
								<div
									className='yapebutton'
								>
									<div
										className='descrip'
									>
										{MUO_str.aad.af009}
									</div>
									<div
										className='yapeqrpen'
									>
										
										<div
											className='penyape fo_sr40'
										>
											{MUO_str.aak.Price_yape}
										</div>
										<div
											className='qryape'
										>
											<QRCode
												size={110}
												title='Yape'
												level='L'
												fgColor='#388fc5'
												value={MUO_str.aac[0].oap}
											/>
										</div>
									</div>
								</div>
							:''	}
							{MUO_str.aac[0].bamc === 'payp'? 
								<div
									className=''
								>
									<PayPalScriptProvider 
										options={{
											clientId: MUO_str.aac[0].oab === true ? MUO_str.aac[0].oaca : MUO_str.aac[0].oacb
										}}
									>
										<PayPalButtons 
											style={{ layout: "horizontal" }}
											createOrder={()=> 
												MUO_fun.buys(1).then ((da)=>{
													// console.log(da, 'MUO-00105', new Date()) // ; return
													return da
											
												}).catch((e)=>{ 
													console.log(e, 'ERROR-MUO-00076' , new Date())
												})
											}
											onApprove={(data, actions) =>{
												actions.order.capture()
												MUO_fun.buys(2, data)
												// console.log(data)
											}}
										/>
									</PayPalScriptProvider>
								</div>
							:''	}
							
							
						</div>
						
					</div>





				}
				footer = {true}
				bopton = {''}
				bacept = {() =>Auxiliar.modal_control(0, {var1 : 'cag', e : false})}
				bcance = {''} //parametro adcional al cancelar por defecto solo cierra
				option = ''
				accept = {MUO_str.aad.ab024}
				cancel = ''
				boptac = {false}
				baccac = {true}
				bcanac = {false}
			>
			</Modal>
		:''}
		{MUO_str.aac[0].cah?
			<Modal
				id='atpcmuo'
				// show={MUO_str.aac[0].cba? true : false}
				getOpenState={(e) =>Auxiliar.modal_control(0, {var1 : 'cah', var2 : 'caha', e : e})}
				icon='icon-pacman'
				clasn1 = 'hep40 wip70'
				title = {MUO_str.aad.ab023}
				bclose = {() =>Auxiliar.modal_control(0, {var1 : 'cah', e : false})}
				bodya ={true}
				bodymsg = {{
					pa1 : '',
					pa2 : '',
					pa3 : '',
					pa4 : '',
					rec : ''
				}}
				bodyb = {true}
				bodyest = {
					<div
						className='fo_sr30'
					>
						{MUO_str.aak.Price === 0 ? 
							MUO_str.aak.limit !== undefined ?
								MUO_str.aad.af011
							: 
								MUO_str.aad.af010
						:
							MUO_str.aak.Tipe_item === 'item' ? 
								MUO_str.aad.af007
							:
								MUO_str.aad.af012
						}
					</div>
				}
				footer = {true}
				bopton = {''}
				bacept = {() =>Auxiliar.modal_control(0, {var1 : 'cah', e : false})}
				bcance = {''} //parametro adcional al cancelar por defecto solo cierra
				option = ''
				accept = {MUO_str.aad.ab024}
				cancel = ''
				boptac = {false}
				baccac = {true}
				bcanac = {false}
			>
			</Modal>
		:''}
		{MUO_str.aac[0].cai?
			<Modal
				id='atpcmuo'
				// show={MUO_str.aac[0].cba? true : false}
				getOpenState={(e) =>Auxiliar.modal_control(0, {var1 : 'cai', var2 : 'caia', e : e})}
				icon='icon-pacman'
				clasn1 = 'hep40 wip70'
				title = {MUO_str.aad.ab023}
				bclose = {() =>Auxiliar.modal_control(0, {var1 : 'cai', e : false})}
				bodya ={true}
				bodymsg = {{
					pa1 : '',
					pa2 : '',
					pa3 : '',
					pa4 : '',
					rec : ''
				}}
				bodyb = {true}
				bodyest = {
					<div
						className='fo_sr30'
					>
						{MUO_str.aad.af008}
					</div>
				}
				footer = {true}
				bopton = {''}
				bacept = {() =>Auxiliar.modal_control(0, {var1 : 'cai', e : false})}
				bcance = {''} //parametro adcional al cancelar por defecto solo cierra
				option = ''
				accept = {MUO_str.aad.ab024}
				cancel = ''
				boptac = {false}
				baccac = {true}
				bcanac = {false}
			>
			</Modal>
		:''}
		{
			<Start_web/>
		}
		</>)

	}
}
export default observer(Muapp)