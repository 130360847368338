import RapidStore							from '../piezas/MUO/storage/MUO_str'
import dateFormat 							from 'dateformat'
// import { endOfToday, parseISO } 			from 'date-fns'
import Auxiliar								from "../fun/fun"
// import jsmunis								from "../fun/js-munis"
import Apiserver							from "../fun/APIs"




class Eventscolas {
	//--------input capter onchange
	a_a(a, b, c, d) {
		RapidStore.c_a('c_e','')
		RapidStore.c_a('c_f','')
		if (c === 2){
			b = RapidStore.a_c[0].c_a + b
		}
		if ( b.length > RapidStore.a_c[0].c_d ){
			return
		}
		if ( d === 1 && b.length > RapidStore.a_c[0].c_j ){
			//ejeccutando subsession.
			return
		}
		// console.log(b)
		if ( d === 1 && b.length === RapidStore.a_c[0].c_j ){
			RapidStore.c_a(a, b)
			RapidStore.c_a('c_c', true)

			this.a_p(3)
			if (RapidStore.a_c[0].c_k === 4){
				// jsmunis.aaa(1, 0)
			}
			return
		}
		RapidStore.c_a(a, b)
	}


	//--------general validator
	a_f(a,b){
		if (a ===1 ){
			if (!b.toString().trim().length) {
				RapidStore.c_a('c_e',1)
				return
			}
			if (!this.a_n(b)) {
				RapidStore.c_a('c_a','')
				RapidStore.c_a('c_e',2)
				return
			}
		}
		if (a ===2){
			if (!b.toString().trim().length) {
				RapidStore.c_a('c_f',1)
				return
			} else {
				if (RapidStore.a_c[0].c_e !== '') {
					return
				} else {
					this.a_p(1)
				}
			}
		}
	}
	//--------email validator
	a_n(a) {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		return re.test(String(a).toLowerCase())
	}
	//--------checkbox validator
	a_o(a) {
		if (a.currentTarget.checked) {
			RapidStore.c_a('c_g', 'checked')
		} else {
			RapidStore.c_a('c_g', '')
		}
	}
	//--------websockets events capter
	a_p(a, b) {
		if (a===1) {
			try {
				RapidStore.a_c[0].j_b.send(JSON.stringify({
					event 		: 		'USER_LOGGIN',
					payload 	: {
						user: RapidStore.a_c[0].c_a,
						pass: RapidStore.a_c[0].c_b
					},
					app			: RapidStore.a_c[0].d_c,
					token		: RapidStore.a_c[0].d_a
				}))
			} catch (e){
				console.log(e)
			}
		}
		if (a===2) {
			try {
				RapidStore.a_c[0].j_b.send(JSON.stringify({
					event 		: 		'USER_RELOGGIN',
					payload 	: {
						token: b
						// token: window.localStorage.email
					},
					app			: RapidStore.a_c[0].d_c,
					token		: RapidStore.a_c[0].d_a,
				}))
			} catch (e){
				console.log(e)
			}
		}
		if (a===3) {
			if (RapidStore.a_c[0].e_c === false){
				RapidStore.c_a('e_c', true)
				// console.log(RapidStore.a_c[0].c_a, RapidStore.a_c[0].c_i, RapidStore.a_c[0].c_k )
				try {
					RapidStore.a_c[0].j_b.send(JSON.stringify({
						event 		: 		'USER_ACCESS',
						payload 	: {
							user		: RapidStore.a_e.Id_user,
							session 	: RapidStore.a_c[0].c_i,
							pass 		: RapidStore.a_c[0].c_a,
							t_admin 	: RapidStore.a_c[0].c_k
						},
						app			: RapidStore.a_c[0].d_c,
						token		: RapidStore.a_c[0].d_a
					}))
				} catch (e){
					console.log(e)
				}
			}
		}
		if (a===4) {
			if (RapidStore.a_c[0].e_c === false){
				RapidStore.c_a('e_c', true)
				// console.log(RapidStore.a_c[0].e_v)
				let yy = localStorage.getItem(RapidStore.a_c[0].e_v)
				// let yy = eval('window.localStorage.' + RapidStore.a_c[0].e_v)
				// console.log(yy)
				if (yy===undefined || yy === null){
					try {
						RapidStore.a_c[0].j_b.send(JSON.stringify({
							event 		: 		'USER_ROUTS',
							payload 	: {
								time	: RapidStore.a_e.Timezone,
								in		: RapidStore.a_c[0].e_h,
								out 	: RapidStore.a_c[0].e_i,
								now 	: RapidStore.a_c[0].e_u,
								veh 	: RapidStore.a_c[0].e_d,
								esc 	: 0,
							},
							app			: RapidStore.a_c[0].d_c,
							token		: RapidStore.a_c[0].d_a
						}))
					} catch (e){
						console.log('Error, websokets not available', e)
					}
				} else {
					yy = JSON.parse(yy)
					let vd = new Date(yy[0].confirm.in).getTime()			//a1
					let ve = new Date(yy[0].confirm.out).getTime()			//a2
					let vf = new Date(RapidStore.a_c[0].e_j[1]).getTime()  	//b2
					let vc = new Date(RapidStore.a_c[0].e_j[0]).getTime()	//c1
					let wa, wb, wc
					wa = 0
					if(vc >= vd && vf > ve){
						wa = 1		//primer escenario b1(c1) y b2 mayores
					}
					if(vc < vd && vf <= ve){
						wa = 2		//segundo escenario c1 y c2(b2) menores
					}
					if(vc < vd && vf > ve){
						wa = 3		//tercer escenario d1(c1) menor y d2(b2) mayor
					}
					if(vc >= vd && vf <= ve){
						wa = 4		//cuarto escenario a1(c1) menor y a2(b2) mayor
					}
					if (wa === 1) {
						try {
							RapidStore.a_c[0].j_b.send(JSON.stringify({
								event 		: 		'USER_ROUTS',
								payload 	: {
									time	: RapidStore.a_e.Timezone,
									in		: yy[0].confirm.out,
									out 	: RapidStore.a_c[0].e_j[1],
									now 	: RapidStore.a_c[0].e_u,
									veh 	: RapidStore.a_c[0].e_d,
									esc 	: 1,
								},
								app			: RapidStore.a_c[0].d_c,
								token		: RapidStore.a_c[0].d_a
							}))
						} catch (e){
							console.log(e)
						}
					}
					if (wa === 2) {
						try {
							RapidStore.a_c[0].j_b.send(JSON.stringify({
								event 		: 		'USER_ROUTS',
								payload 	: {
									time	: RapidStore.a_e.Timezone,
									in		: RapidStore.a_c[0].e_j[0],
									out 	: yy[0].confirm.in,
									now 	: RapidStore.a_c[0].e_u,
									veh 	: RapidStore.a_c[0].e_d,
									esc 	: 2,
								},
								app			: RapidStore.a_c[0].d_c,
								token		: RapidStore.a_c[0].d_a
							}))
						} catch (e){
							console.log(e)
						}
					}
					if (wa === 3) {
						try {
							RapidStore.a_c[0].j_b.send(JSON.stringify({
								event 		: 		'USER_ROUTS',
								payload 	: {
									time	: RapidStore.a_e.Timezone,
									in		: [RapidStore.a_c[0].e_j[0], yy[0].confirm.in],
									out 	: [yy[0].confirm.out, RapidStore.a_c[0].e_j[1]],
									now 	: RapidStore.a_c[0].e_u,
									veh 	: RapidStore.a_c[0].e_d,
									esc 	: 3,
								},
								app			: RapidStore.a_c[0].d_c,
								token		: RapidStore.a_c[0].d_a
							}))
						} catch (e){
							console.log(e)
						}
					}
					if (wa === 4) {
						//if (RapidStore.a_c[0].e_d!==0){
						this.a_c({db : RapidStore.a_c[0].e_v, id : RapidStore.a_c[0].e_d, state : 2, ver : RapidStore.a_c[0].e_g }, 5).then (async (v)=>{
							if (Auxiliar.aad(v)){
								RapidStore.c_a('e_c', false)
								RapidStore.c_a('e_o', false)
							} else {
								this.a_x(v)
							}
						}).catch((e)=>{
							console.log('error a_c 5 update e_d = 0', e)
						})
						// } else {
						// 	console.log(RapidStore.a_l[0].id)
						// 	RapidStore.c_a('e_d', RapidStore.a_l[0].id)
						// 	this.a_c({db : RapidStore.a_c[0].e_f, id : RapidStore.a_l[0].id, state : 2, ver : RapidStore.a_c[0].e_g }, 5).then (async (v)=>{
						// 		if (Auxiliar.aad(v)){
						// 			RapidStore.c_a('e_c', false)
						// 			RapidStore.c_a('e_o', false)
						// 		} else {
						// 			this.a_x(v)
						// 		}
						// 	}).catch((e)=>{
						// 		console.log('error a_c 5 update e_d = 0', e)
						// 	})
						// }
					}
				}
			}
		}
		if (a===5) {
			try {
				RapidStore.a_c[0].j_b.send(JSON.stringify({
					event 		: 		'USER_SOS_V',
					payload 	: {
						sos 	: RapidStore.a_c[0].f_e,
					},
					app			: RapidStore.a_c[0].d_c,
					token		: RapidStore.a_c[0].d_a
				}))
			} catch (e){
				console.log(e)
			}
		}
	
		RapidStore.a_c[0].j_b.onmessage = (e) => {
			let xx = JSON.parse(e.data)
			//console.log(e.data)
			if( xx.event === 'USER_LOGGIN'){
				RapidStore.c_a('c_a','')
				RapidStore.c_a('c_b','')
				if (xx.status === true) {
					if (RapidStore.a_c[0].c_g==='checked'){
						// console.log(xx.data.s, xx.data.s.email)
						localStorage.setItem('email', xx.data.s.email)
						// window.localStorage.email = 
					}
					// localStorage.setItem('FACLoggin', xx.data.p[0].Token_session)
					RapidStore.b_b(xx.data)
					RapidStore.increment(2)
				} else if (xx.status === false) {
					RapidStore.c_a('c_e',3)
					RapidStore.c_a('c_f',2)
				} else if(xx.status === 'error'){
					RapidStore.c_a('c_e',4)
					RapidStore.c_a('c_f',3)
				} else if(xx.status === 'locked'){
					RapidStore.c_a('c_e',5)
					RapidStore.c_a('c_f',4)
				} else if(xx.status === 'expire'){
					RapidStore.c_a('c_e',6)
					RapidStore.c_a('c_f',5)
				}
			}
			if( xx.event === 'USER_RELOGGIN' && xx.status === true){
				if (xx.data.t.Level_client===1){
					RapidStore.b_b(xx.data)
					RapidStore.increment(3)
				}
			}
			if( xx.event === 'USER_ACCESS' && xx.status === true){
				RapidStore.c_a('e_c', false)
				RapidStore.b_c(xx.data)
				RapidStore.increment(5)
			}
			if( xx.event === 'COORDS_UPDATE' && xx.status === true){
				RapidStore.c_b(xx.data)
			}
			if( xx.event === 'COORDS_SOS' && xx.status === true){
				RapidStore.c_c(xx.data)
			}
			if( xx.event === 'COORDS_SOS_V' && xx.status === true){
				RapidStore.c_c(xx.data)
				RapidStore.c_a('f_d', true)
				RapidStore.c_a('f_e', xx.data.deviceid)
			}
			if( xx.event === 'USER_ROUTS' && xx.status === true){
				let yy = localStorage.getItem(RapidStore.a_c[0].e_v)
				// let yy = eval ('window.localStorage.' + RapidStore.a_c[0].e_v)
				let rr = []			// var for fech
				let vv = []			// names vars
				let ss = 0
				//RapidStore.c_a('e_i', dateFormat(new Date(), 'yyyy-mm-dd HH:MM:ss').replace(' ', 'T'))
				// 		console.log(RapidStore.a_c[0].e_h, RapidStore.a_c[0].e_i, parseISO(RapidStore.a_c[0].e_h), 'hummmmm')
				
				// console.log(RapidStore.a_c[0].e_v, yy, 'FUN-00287', new Date())
				if (yy===null){
					this.a_c({unid : xx.data.unid, res : xx.data.res, db : RapidStore.a_c[0].e_f + 'V' +  xx.data.unid, state : 0}, 2).then(async (e)=>{
						/* observada
						if (RapidStore.a_c[0].e_r === true){
							ss = 1
						}
						*/
						// console.log(xx.data)
						rr.push({
							ver		: e.ver,
							unid 	: xx.data.unid,
							confirm : {in : xx.data.time.in, out : Auxiliar.aad(xx.data.res)? xx.data.time.now : RapidStore.a_c[0].e_s ? xx.data.time.out : e.date},
							idb		: e.var,
							client : [{
								id 		: RapidStore.a_d.id,
								client 	: RapidStore.a_d.name,
								time 	: xx.data.time.time
							}]
						})
						localStorage.setItem(RapidStore.a_c[0].e_v, JSON.stringify(rr))
						// eval ('window.localStorage.' + RapidStore.a_c[0].e_v + '= JSON.stringify(rr)')
						//if (RapidStore.a_c[0].e_d!==0){
						RapidStore.c_a('e_d', xx.data.unid)
						RapidStore.c_a('e_r', false)
						RapidStore.c_a('e_y', false)
						if (Auxiliar.aad(xx.data.res)){
							RapidStore.c_a('e_c', false)
							RapidStore.c_a('e_o', false)
							return
						} else {
							this.a_x(xx.data.res)
							return
						}
					}).catch((e)=>{
						console.log('error result a_c 4', e)
					})
				} else {
					yy = JSON.parse(yy)
					let aa = {}
					aa.status = false
					RapidStore.c_a('e_g', yy[0].ver)
					this.a_c({unid : xx.data.unid, res : xx.data.res, db : RapidStore.a_c[0].e_f + 'V' +  xx.data.unid, state : 2, ver : yy[0].ver}, 2).then(async (e)=>{
						rr.push({
							ver		: e.ver,
							unid 	: xx.data.unid,
							confirm : {in : yy[0].confirm.in, out : Auxiliar.aad(xx.data.res)? xx.data.time.now : RapidStore.a_c[0].e_s ? xx.data.time.out : e.date},
							idb		: e.var,
							client : [{
								id 		: RapidStore.a_d.id,
								client 	: RapidStore.a_d.name,
								time 	: xx.data.time.time
							}]
						})
						// console.log(yy, xx.data, rr)
						eval ('window.localStorage.' + RapidStore.a_c[0].e_f + 'V' +  xx.data.unid + ' =  JSON.stringify(rr)')
						RapidStore.c_a('e_d', xx.data.unid)
						RapidStore.c_a('e_r', false)
						RapidStore.c_a('e_y', false)
						
						this.a_c({db : RapidStore.a_c[0].e_f + 'V' +  xx.data.unid, id : RapidStore.a_c[0].e_d, state : 2, ver : RapidStore.a_c[0].e_g }, 5).then (async (v)=>{
							if (Auxiliar.aad(v)){
								RapidStore.c_a('e_c', false)
								RapidStore.c_a('e_o', false)
							} else {
								this.a_x(v)
							}
						}).catch((e)=>{
							console.log('error a_c 5 update e_d = 0', e)
						})

						return
						eval ('window.localStorage.' + RapidStore.a_c[0].e_v + '= JSON.stringify(rr)')
						//if (RapidStore.a_c[0].e_d!==0){
						RapidStore.c_a('e_d', xx.data.unid)
						RapidStore.c_a('e_r', false)
						RapidStore.c_a('e_y', false)
						if (Auxiliar.aad(xx.data.res)){
							RapidStore.c_a('e_c', false)
							RapidStore.c_a('e_o', false)
							return
						} else {
							this.a_x(xx.data.res)
							return
						}
					}).catch((e)=>{
						console.log('error result a_c 4', e)
					})

					// Promise.all(yy[0].clients.map((p, i)=>{
					// 	if (p.id === RapidStore.a_d.id) {
					// 		aa = {
					// 			status  : true,
					// 			index 	: i,
					// 			var		: p.vars
					// 		}
					// 	}
					// 	vv.push(p)
					// })).then(()=>{
					// 	if (aa.status === false){
					// 		console.log('no hay db estatus false')

					// 		this.a_c({unid : xx.data.unid, res : xx.data.res, db : RapidStore.a_c[0].e_f, state : 1, ver : RapidStore.a_c[0].e_g }, 4).then(async (e)=>{
					// 			vv.push({
					// 				id: RapidStore.a_d.id,
					// 				client: RapidStore.a_d.name,
					// 				time: xx.data.time,
					// 				unid: xx.data.unid,
					// 				vars: e.var
					// 			})
					// 			rr.push({
					// 				ver: e.ver,
					// 				clients: vv
					// 			})
					// 			this.a_x(RapidStore.a_c[0].e_d)
					// 			RapidStore.c_a('e_c', false)
					// 			await eval ('window.localStorage.' + RapidStore.a_c[0].e_f + ' =  JSON.stringify(rr)')
					// 		}).catch((e)=>{
					// 			//return b(sender(0, e, val.event, 0))		//errores de cuenta
					// 		})
					// 	} else {
							// console.log('hay db estatus true')
							// this.a_c({unid : xx.data.unid, res : xx.data.res, db : RapidStore.a_c[0].e_f, state : 2, ver : RapidStore.a_c[0].e_g }, 4).then(async (e)=>{
							// 	vv[aa.index].time = {time: '-5', in : RapidStore.a_c[0].f_j.in, out : RapidStore.a_c[0].f_j.out}
							// 	rr.push({
							// 		ver			: e.ver,
							// 		clients		: vv
							// 	})
							// 	await eval ('window.localStorage.' + RapidStore.a_c[0].e_f + ' =  JSON.stringify(rr)')
							// 	if (RapidStore.a_c[0].e_d!==0){
									//console.log(RapidStore.a_c[0].e_f, RapidStore.a_c[0].e_d, RapidStore.a_c[0].e_g)
								// 	await this.a_c({db : RapidStore.a_c[0].e_f, id : RapidStore.a_c[0].e_d, state : 2, ver : RapidStore.a_c[0].e_g }, 5).then (async (v)=>{
								// 		if (Auxiliar.aad(v)){
								// 			RapidStore.c_a('e_c', false)
								// 			RapidStore.c_a('e_o', false)
								// 		} else {
								// 			this.a_x(v)
								// 		}
								// 	}).catch((e)=>{
								// 		console.log('error a_c 5 update e_d = 0', e)
								// 	})
								// } else {
						// RapidStore.c_a('e_d', xx.data.unid)
						// rr.push({
						// 	ver		: yy[0].ver,
						// 	unid 	: xx.data.unid,
						// 	confirm : {in : xx.data.time.in, out : RapidStore.a_c[0].e_s ? xx.data.time.out : e.date},
						// 	idb		: e.var,
						// 	client : [{
						// 		id 		: RapidStore.a_d.id,
						// 		client 	: RapidStore.a_d.name,
						// 		time 	: xx.data.time.time
						// 	}]
						// })
						// vv[aa.index].time = {time: '-5', in : RapidStore.a_c[0].f_j.in, out : RapidStore.a_c[0].f_j.out}
						// 	// 	rr.push({
							// 		ver			: e.ver,
							// 		clients		: vv
							// 	})
						
							// 	}

							// 	//this.a_x(RapidStore.a_c[0].e_d)
							// }).catch((e)=>{
							// 	//return b(sender(0, e, val.event, 0))		//errores de cuenta
							// })
					// 	}
					// })
				}
			}
		}
	}

	//--------shot functions validator
	a_b(a, b, c) {
		RapidStore.c_a('c_c',false)
		this.a_f(1, RapidStore.a_c[0].c_a)
		this.a_f(2, RapidStore.a_c[0].c_b)
	}
	aac(a, b, c){
		RapidStore.c_a('c_c',false)
		if (b === 0){
			const val = RapidStore.a_c[0].c_a.slice(0, -1)
			RapidStore.c_a('c_a',val)
		}
		if (b === 1){
			RapidStore.c_a('c_a','')
		}
		if (b === 2){
			RapidStore.increment(4,0)
		}
		if (b === 3){
			RapidStore.c_a('c_d',15)
		}
	}
	//--------function promesas websockets connection
	async a_c(a, b){
		return new Promise( (resolve, reject) =>{
			if (b===1){											// connecction websokects
				RapidStore.c_a('e_b', new WebSocket(a))
				RapidStore.a_c[0].e_b.onopen = () => {
					resolve(RapidStore.a_c[0].e_b)
				}
				RapidStore.a_c[0].e_b.onerror = (e)=> {
					reject(e)
				}
			}
			if (b===2){
				//console.log(a)

				let indexedDB = window.indexedDB || window.webkitIndexedDB || window.mozIndexedDB || window.OIndexedDB || window.msIndexedDB,
				//IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction || window.OIDBTransaction || window.msIDBTransaction,
				//db : a.db, tables : a.unid, orderdata : m, state : a.state, ver : a.ver
				dbVersion = 1.0
				let db
				let storeRequest
				let transaction
				let openRequest
				let newver
				//console.log(a, 'control de flujo routes')
				if (a.state === 0) {
					RapidStore.c_a('e_g', dbVersion)
					openRequest = indexedDB.open(a.db, dbVersion)
				}
				if (a.state === 1) {
					newver = a.ver + 1
					openRequest = indexedDB.open(a.db, newver)
				}

				if (a.state === 2) {   					// update db  (insertar mas registros)
					openRequest = indexedDB.open(a.db, a.ver)
				}
				//console.log(a)
				openRequest.onupgradeneeded = function(e) {
					db =  e.target.result
					// Promise.all(a.tables.map((t)=>{
					storeRequest = db.createObjectStore(a.db, {  keyPath : 'id', autoIncrement: true })
					storeRequest.createIndex('devicetime', 'devicetime', {unique: false})
						// })).then (()=>{
							//console.log(e, storeRequest, '487')

					// })
				}
				openRequest.onsuccess = function(e) {
					let vv
					db = e.target.result
					//vv.push({var : a.db + 'V' + a.unid})
					let rowLen = a.res.length
					transaction = db.transaction(a.db, 'readwrite')
					const store = transaction.objectStore(a.db)
					Promise.all(a.res.map((el, i) => {
						if (rowLen === i + 1) {
							vv = el.devicetime
							//console.log(vv)
						}
						store.add(el)
					}))
					//console.log(transaction)
					transaction.oncomplete = ev => {
						resolve({ver : db.version, var : a.db, date : vv})
						//console.error('¡prueba!', ev.target)
					}
					transaction.onerror = ev => {
						console.error('¡Se ha producido un error!', ev.target.error.message)
					}
				}
				openRequest.onerror = function(e) {
					//No hemos podido abrir la base de datos
				}
			}
			if (b===3){ // observada aulada queda el condicional
				// let ww
				// this.a_c({tables : a.tables}, 6).then( async (n)=>{
				// 	ww = n
				// 	await Promise.all(a.data.map((p)=>{
				// 		ww['V' + p.deviceid].push({
				// 			full : p
				// 		})
				// 	})).then(()=>{
				// 		RapidStore.b_f({date : '', id: a.tables[0].id})
				// 		resolve(ww)
				// 	})
				// }).catch((e)=>{
				// 	console.log('error ordenando routs', e)
				// })
			}
			if (b===4){		// observada anulada queda el condicional
				//console.log(a)
				// this.a_c({data : a.res, tables : a.unid}, 3).then( async (m)=>{
					// this.a_c({db : a.db, tables : a.unid, orderdata : '', state : a.state, ver : a.ver}, 2).then((n)=>{
					// 	resolve({var : n.var, ver : n.ver})
					// }).catch((e)=>{
					// 	console.log('error a_c 2 routes indexeddb push', e)
					// })
				// }).catch((e)=>{
				// 	console.log('error a_c 3 routes order', e)
				// })
			}
			if (b===5){
				// console.log(a, "JS-00593", new Date())
				let yy
				let ww = []
				//let zz = RapidStore.a_c[0].e_f + 'V' + a.id
				let indexedDB = window.indexedDB || window.webkitIndexedDB || window.mozIndexedDB || window.OIndexedDB || window.msIndexedDB,
				//IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction || window.OIDBTransaction || window.msIDBTransaction,
				//this.a_c({unid : xx.data.unid, res : xx.data.res, db : RapidStore.a_c[0].e_f, state : 0 }, 4).then(async (e)=>{
				//	db: RapidStore.a_c[0].e_f, id: RapidStore.a_l[0].id, state: 0
				dbVersion = 1
				let db
				let data
				let transaction
				let openRequest
				let newver
				if (a.state === 0) {
					openRequest = indexedDB.open(a.db, dbVersion)
				}
				if (a.state === 1) {
					newver = a.ver + 1
					openRequest = indexedDB.open(a.db, newver)
				}
				if (a.state === 2) {
					openRequest = indexedDB.open(a.db, a.ver)
				}
				openRequest.onsuccess = function(e) {
					db = e.target.result
					//console.log(db, '576')
					transaction = db.transaction(a.db, 'readonly')
					data = transaction.objectStore(a.db)
					// if (RapidStore.a_c[0].e_n === false) {
					// 	//console.log(dateFormat(new Date(RapidStore.a_c[0].e_h), 'isoDateTime'), RapidStore.a_c[0].e_i, a.db, 'e_n false')
					// 	yy = IDBKeyRange.bound(dateFormat(new Date(RapidStore.a_c[0].e_h), 'isoDateTime'), dateFormat(new Date(RapidStore.a_c[0].e_i), 'isoDateTime'))
					// 	//console.log(yy.Id_user, 'keyrange')
					// 	data.openCursor(yy).onsuccess = function (f){
					// 		let contend = f.target.result
					// 		if (contend === null){
					// 			return
					// 		}
					// 		ww.push(contend.value)
					// 		contend.continue()
					// 	}
					// } else {
					var index = data.index('devicetime')
					yy = IDBKeyRange.bound(dateFormat(new Date(RapidStore.a_c[0].e_h), 'isoDateTime'), dateFormat(new Date(RapidStore.a_c[0].e_i), 'isoDateTime'))
					index.openCursor(yy).onsuccess = function (f){
						let contend = f.target.result
						if (contend === null){
							return
						}
						ww.push(contend.value)
						contend.continue()
					}
					//}
					transaction.oncomplete = function (){
						if (Auxiliar.aad(ww)===true){
							RapidStore.c_a('eba',0)
						} else {
							RapidStore.c_a('eba', (JSON.parse(ww[ww.length-1].attributes).totalDistance - JSON.parse(ww[0].attributes).totalDistance) / 1000)
						}

						// console.log(JSON.parse(ww[0].attributes).totalDistance, JSON.parse(ww[ww.length-1].attributes).totalDistance, RapidStore.a_c[0].eba, 'push ww')
						// in : d.data[d.data.length-1].Time_register, out : d.data[0].Time_register
						resolve(ww)
					}
					transaction.onerror = ev => {
						console.error('¡Se ha producido un error!', ev.target.error.message)
					}
				}
				openRequest.onerror = function(e) {
					//No hemos podido abrir la base de datos
				}
			}
			if (b===6){ //observada anulada solo queda le raiz
				// let ww = []
				// console.log(a)
				// Promise.all(a.tables.map(async (e)=>{  //cuidado promise.all solo acepta un argumento en este caso una matriz de objetos
				// 	ww['V'+ e.id] = []
				// })).then((a)=>{
				// 	resolve(ww)
				// }).catch((e)=>{
				// 	console.log('error creando vars de tablas', e)
				// })
			}
		})
	}
	//--------function reloggin
	a_d(a, b, c){
		// console.log(!!a, a)
		if (!!a){
			this.a_p(2, a)
		} else {
			RapidStore.increment(1)
		}
	}
	//--------function access
	a_e(a, b) {
		console.log(a)
		RapidStore.c_a('c_i', b.Id_attender)
		RapidStore.c_a('c_l', b.Honta_munis_user)
		RapidStore.c_a('c_m', b.Honta_munis_pass)
		RapidStore.c_a('c_n', b.Id_user_services)
		RapidStore.c_a('c_k', a)
		if (a===0){
			RapidStore.c_a('c_h',false)
			this.a_p(3)
		} else {
			RapidStore.c_a('c_h',true)
		}
	}





	a_g(a,b,c){  //c libre observada
		RapidStore.a_e.forEach(d=>{
			if(d.a_i === a){
				this.a_f(b, 'http://con.localhost', 'POST', d)
			}
		})
	}
	a_h(){
		var ws = new WebSocket("ws://localhost:3000/ws");
		let that = this; // cache the this
		var connectInterval;

		// websocket onopen event listener
		ws.onopen = () => {
			console.log("connected websocket main component");

			this.setState({ ws: ws });

			that.timeout = 250; // reset timer to 250 on open of websocket connection
			clearTimeout(connectInterval); // clear Interval on on open of websocket connection
		};

		// websocket onclose event listener
		ws.onclose = e => {
			console.log(
				`Socket is closed. Reconnect will be attempted in ${Math.min(
					10000 / 1000,
					(that.timeout + that.timeout) / 1000
				)} second.`,
				e.reason
			);

			that.timeout = that.timeout + that.timeout; //increment retry interval
			connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
		};

		// websocket onerror event listener
		ws.onerror = err => {
			console.error(
				"Socket encountered error: ",
				err.message,
				"Closing socket"
			);

			ws.close();
		}
	}
	a_i(){
		const { ws } = this.state;
		if (!ws || ws.readyState === WebSocket.CLOSED) this.connect(); //check if websocket instance is closed, if so call `connect` function.
	}
		/*  con eso se m
		render() {
		return <ChildComponent websocket={this.state.ws} />;
		 }

		///   componente
	sendMessage=()=>{
		const {websocket} = this.props // websocket instance passed as props to the child component.

		try {
			websocket.send(data) //send data to the server
		} catch (error) {
			console.log(error) // catch error
		}
	}
	render() {
		return (
			<div>
				........
			</div>
		);
	}

		*/
	a_j(){
		this.a_f(7, RapidStore.a_c[0].d_f, 'POST', new Date ((new Date((new Date(new Date())).toISOString() )).getTime() - ((new Date()).getTimezoneOffset()*60000)).toISOString().slice(0, 19).replace('T', ' '))
	}
	a_k(a, c, b){
		this.a_f(9, RapidStore.a_c[0].d_f, 'POST', a)
	}
	a_l(a){
		if (a === 1){
			return 'red_pla onta-input-form'
		}
		if (a === 2){
			return 'orange_pla onta-input-form'
		}
		//if (a === 3){
		//	return 'red_pla onta-input-form'
		//}
	}
	a_m(a){
		if (a === 1){
			return 'Ingrese un e-mail para su sesión.'
		}
		if (a === 2){
			return 'Formato de e-mail no válido.'
		}
		if (a === 3){
			return 'Ingrese su contraseña.'
		}
		if (a === 4){
			return 'E-mail y contraseña no válidos.'
		}
		if (a === 5){
			return 'Intente otra vez.'
		}
		if (a === 6){
			return 'Contraseña de usuario incorrecta.'
		}
		if (a === 7){
			return 'consulte a su administrador.'
		}
		if (a === 8){
			return 'Su cuenta esta bloqueda.'
		}
		if (a === 9){
			return 'consulte para mas detalles.'
		}
		if (a === 10){
			return 'Venció la licencia de su cuenta.'
		}
	}
	a_q(a){
		let yy, xx
		let zz = JSON.parse(a.attributes)
		// console.log(a)
		if (a.online===null){
			yy = 'red'
			xx = 'icon-pacman'
		}
		if (a.online==='deviceUnknown'){
			yy = 'red'
			xx = 'icon-taxi_alert'
		}
		if (a.online!=='deviceUnknown' && a.online!==null){
			if (a.ignition===1 && zz.motion===true){
				yy = 'teal'
				xx = 'icon-local_taxi'
			}
			if (a.ignition===1 && zz.motion===false){
				yy = 'duc'
				xx = 'icon-local_taxi'
			}
			if (a.ignition===0 && zz.motion===false){
				yy = 'blue'
				xx = 'icon-local_taxi'
			}
			if (a.ignition===0 && zz.motion===true){
				yy = 'gray'
				xx = 'icon-local_taxi'
			}
		}
			return ({
				className: 'custom-div-icon',
				html: "<div class='ccontend-marker'><div class='marker-pin teal'></div><i class='" + xx + "'></i><div class='marker-rotulo c_dark " + yy + "'>" + a.name + "</div></div>",
				iconSize: [30, 30],
				iconAnchor: [15, 30]
			})

	}
	a_r(a){
		RapidStore.c_a('f_c', true)
		//console.log(a)
		let yy, xx
		let zz = JSON.parse(a.attributes)
		//console.log(a)
		if (a.online==='deviceUnknown'){
			yy = 'red'
			xx = 'icon-notifications_on'
		} else {
			if (a.ignition===1 && zz.motion===true){
				yy = 'teal'
				xx = 'icon-notifications_on'
			}
			if (a.ignition===1 && zz.motion===false){
				yy = 'duc'
				xx = 'icon-notifications_on'
			}
			if (a.ignition===0 && zz.motion===false){
				yy = 'blue'
				xx = 'icon-notifications_on'
			}
			if (a.ignition===0 && zz.motion===true){
				yy = 'gray'
				xx = 'icon-notifications_on'
			}
		}
		return ({
			className: 'custom-div-icon bell',
			html: "<div class='marker-pin " +  yy + "'></div><i class='" + xx + "'></i>",
			iconSize: [46, 46],
			iconAnchor: [23, 46]
		})

	}
	aar(a){
		RapidStore.c_a('f_c', true)
		//console.log(a)
		let yy, xx
		// let zz = JSON.parse(a.attributes)
		//console.log(a)
		yy = 'red'
		xx = 'icon-notifications_on'
	
		return ({
			className: 'custom-div-icon bell z-i ',
			html: "<div class='marker-pin " +  yy + "'></div><i class='" + xx + "'></i>",
			iconSize: [46, 46],
			iconAnchor: [23, 46]
		})

	}
	aas(a){
		RapidStore.c_a('f_c', true)
		//console.log(a)
		let yy, xx
		// let zz = JSON.parse(a.attributes)
		//console.log(a)
		yy = 'red'
		xx = 'icon-home_work'
	
		return ({
			className: 'custom-div-icon bell z-i ',
			html: "<div class='marker-pin " +  yy + "'></div><i class='" + xx + "'></i>",
			iconSize: [46, 46],
			iconAnchor: [23, 46]
		})

	}
	a_s(a, b){
		console.log(a)
		if (a===0){
			RapidStore.increment(5)
		}
		if (a===1){
			RapidStore.increment(6)
		}
		if (a===2){
			//this.a_p(4)
			RapidStore.increment(7)
			//if (RapidStore.a_c[0].e_d!== 0){
			//	RapidStore.increment(71)
			//}
		}
		if (a===3){
			RapidStore.increment(8)
		}
		if (a===4){
			RapidStore.increment(9)
		}
		if (a===5){
			RapidStore.increment(10)
		}
		if (a===6){
			RapidStore.increment(11)
		}
	}
	a_t(a, b, c){
		// console.log(a, b)
		if (a===0 && b === 1){
			RapidStore.increment(74)
		}
		if (a===4 && b === 1){
			RapidStore.increment(74)
		}
		if (a===4 && b === 2){
			RapidStore.increment(75)
		}
		if (a===2 && b === 1){
			RapidStore.increment(71)
		}
		if (a===2 && b === 2){
			RapidStore.increment(72)
		}
		if (a===2 && b === 3){
			this.b_b(0)
			this.a_p(4)
			// if (RapidStore.a_c[0].e_d!== 0){
			// 	RapidStore.increment(71)
			// }
			//RapidStore.increment(73)
		}

	}
	a_u(a){
		if (a === 0){
			return 'listvh-icon icon-00001 '
		}
		if (0 < a && a <= 10){
			return 'listvh-icon icon-00021 c_teal'
		}
		if (10 < a && a <= 15){
			return 'listvh-icon icon-00031 c_yellow'
		}
		if (15 < a && a <= 20){
			return 'listvh-icon icon-00041 c_orange'
		}
		if (20 < a && a <= 30){
			return 'listvh-icon icon-00051 c_red'
		}
		if (30 < a ){
			return 'listvh-icon icon-00061 c_danger'
		}
	}
	a_v(a){
		// 

		// console.log(a, RapidStore.a_c[0].f_k, new Date())
		if (a === RapidStore.a_c[0].f_f){
			return 'r-button gray blocked'
		}
		if (a === RapidStore.a_c[0].f_h){
			return 'r-button gray blocked'
		}
		if (a === RapidStore.a_c[0].f_i){
			return 'r-button gray blocked'
		}
		if (a === RapidStore.a_c[0].f_k){
			return 'r-button gray blocked'
		}

		return 'r-button white'
	}
	a_w(a, b, c){
		if (b===1){
			// historial desde tracer}
			if (!RapidStore.a_c[0].e_y){
				if (a === null){
					return
				} else {
					// console.log(RapidStore.a_c[0].e_t)
					this.bac(2, RapidStore.a_c[0].e_t)
					RapidStore.c_a('e_q', [c.lat, c.lon])
					RapidStore.c_a('e_p', true)
					let ww, oo, dd, zz
					// e_u hora actual
					zz = new Date(RapidStore.a_c[0].e_u).getTime() < new Date(RapidStore.a_c[0].e_t[1]).getTime() ? false : true //observada no se ejecuta.
					ww = a
					oo = RapidStore.a_c[0].e_f + 'V' + ww
					dd = RapidStore.a_c[0].e_x===0? 96 : 48
					RapidStore.c_a('e_w', dd)
					RapidStore.c_a('e_d', ww)
					RapidStore.c_a('e_v', oo)
					RapidStore.c_a('e_s', zz)
					RapidStore.c_a('e_z', 0)
					RapidStore.c_a('e_y', true)
					RapidStore.c_a('e_h', RapidStore.a_c[0].e_t[0])
					RapidStore.c_a('e_i', RapidStore.a_c[0].e_t[1])
					RapidStore.c_a('e_j', zz? [RapidStore.a_c[0].e_t[0], RapidStore.a_c[0].e_t[1]] : [RapidStore.a_c[0].e_t[0], RapidStore.a_c[0].e_u])
					RapidStore.c_a('e_k', RapidStore.a_c[0].e_t)
					RapidStore.c_a('e_l', zz? [{start: 0, end: 0}] : [{start: RapidStore.a_c[0].e_u, end: RapidStore.a_c[0].e_t[1]}])
					RapidStore.c_a('e_m', false)
					// let pp = eval ('window.localStorage.' + oo)
					let pp = localStorage.getItem(oo)
					if (pp === null){
						this.a_p(4)
						RapidStore.increment(7)
						RapidStore.increment(71)
						return
					} else {
						pp = JSON.parse(pp)
						RapidStore.c_a('e_g', pp[0].ver)
						this.a_p(4)
						RapidStore.increment(7)
						RapidStore.increment(71)
						return
					}
				}
			} else {
				return
			}
			// this.b_b(0)
			// this.a_p(4)
			// RapidStore.c_a('e_d', a)
		}
		if (b===2){														//cambiar id de vehiculo cuando esta en carga de puntos
			// RapidStore.c_a('e_d', a)
			return
		}
		if (b===3){														//obtener recorrido desde el timeline
			RapidStore.c_a('e_c', true)
			this.a_c({db : RapidStore.a_c[0].e_v, id : RapidStore.a_c[0].e_d, state : 2, ver : RapidStore.a_c[0].e_g}, 5).then (async (v)=>{
				if (Auxiliar.aad(v)){
					RapidStore.c_a('e_c', false)
					RapidStore.c_a('e_o', false)
				} else {
					this.a_x(v)
				}
			}).catch((e)=>{
				console.log('error a_w 3 result', e)
			})
		}
		if (b===4){							//obtener recorrido desde lista de vehiculos
			if (!RapidStore.a_c[0].e_y){
				if (a === null){
					return
				} else {
					// console.log(RapidStore.a_c[0].e_t)
					this.bac(2, RapidStore.a_c[0].e_t)
					RapidStore.c_a('e_q', [c.lat, c.lon])
					RapidStore.c_a('e_p', true)
					let ww, oo, dd, zz
					// e_u hora actual
					zz = new Date(RapidStore.a_c[0].e_u).getTime() < new Date(RapidStore.a_c[0].e_t[1]).getTime() ? false : true //observada no se ejecuta.
					ww = a
					oo = RapidStore.a_c[0].e_f + 'V' + ww
					dd = RapidStore.a_c[0].e_x===0? 96 : 48
					RapidStore.c_a('e_w', dd)
					RapidStore.c_a('e_d', ww)
					RapidStore.c_a('e_v', oo)
					RapidStore.c_a('e_s', zz)
					RapidStore.c_a('e_z', 0)
					RapidStore.c_a('e_y', true)
					RapidStore.c_a('e_h', RapidStore.a_c[0].e_t[0])
					RapidStore.c_a('e_i', RapidStore.a_c[0].e_t[1])
					RapidStore.c_a('e_j', zz? [RapidStore.a_c[0].e_t[0], RapidStore.a_c[0].e_t[1]] : [RapidStore.a_c[0].e_t[0], RapidStore.a_c[0].e_u])
					RapidStore.c_a('e_k', RapidStore.a_c[0].e_t)
					RapidStore.c_a('e_l', zz? [{start: 0, end: 0}] : [{start: RapidStore.a_c[0].e_u, end: RapidStore.a_c[0].e_t[1]}])
					RapidStore.c_a('e_m', false)
					let pp = localStorage.getItem(oo)
					// let pp = eval ('window.localStorage.' + oo)
					if (pp === null){
						this.a_p(4)
						return
					} else {
						pp = JSON.parse(pp)
						RapidStore.c_a('e_g', pp[0].ver)
						this.a_p(4)
						return
					}
				}
			} else {
				return
			}
		}
		if (b===5){
			if (a === null){
				return
			} else {
				this.bac(2, a)
				let ww, oo, dd, zz
				zz = new Date(RapidStore.a_c[0].e_u).getTime() < new Date(a[1]).getTime() ? false : true //observada no se ejecuta.
				ww = RapidStore.a_c[0].e_d===0? RapidStore.a_g[0].id : RapidStore.a_c[0].e_d
				oo = RapidStore.a_c[0].e_f + 'V' + ww
				dd = RapidStore.a_c[0].e_x===0? 96 : 48
				//dd = RapidStore.a_c[0].e_x===0? 96 : 96 / (RapidStore.a_c[0].e_x + 1)
				//return
				RapidStore.c_a('e_w', dd)
				RapidStore.c_a('e_d', ww)
				RapidStore.c_a('e_v', oo)
				RapidStore.c_a('e_s', zz)
				RapidStore.c_a('e_z', 0)
				// RapidStore.c_a('e_r', true)
				RapidStore.c_a('e_h', a[0])
				RapidStore.c_a('e_i', a[1])
				RapidStore.c_a('e_j', zz? [a[0], a[1]] : [a[0], RapidStore.a_c[0].e_u])
				RapidStore.c_a('e_k', a)
				RapidStore.c_a('e_l', zz? [{start: 0, end: 0}] : [{start: RapidStore.a_c[0].e_u, end: a[1]}])
				RapidStore.c_a('e_m', false)
				let pp = eval ('window.localStorage.' + oo)
				if (pp === undefined){
					this.a_p(4)
					return
				} else {
					pp = JSON.parse(pp)
					RapidStore.c_a('e_g', pp[0].ver)
					this.a_p(4)
					return
				}
			}
		}
		if (b===6){
			// console.log(RapidStore.a_c[0].e_d)
			// console.trace()
			if (RapidStore.a_c[0].e_r){
				console.log('go go ')
				RapidStore.c_a('e_n', true)
				RapidStore.c_a('e_j', a)
				let vv = new Date(a[0]).getTime() + (1000 * 60 * 60 * 5)
				let ww = new Date(a[1]).getTime() + (1000 * 60 * 60 * 5)
				RapidStore.c_a('e_h', dateFormat(new Date(vv), 'yyyy-mm-dd HH:MM:ss'))
				RapidStore.c_a('e_i', dateFormat(new Date(ww), 'yyyy-mm-dd HH:MM:ss'))
				this.a_w(RapidStore.a_c[0].e_d ,3)
			} else {
				console.log('go go 2')
			}
		}
		if (b===7){

			
			return
		}


	}
	a_x(a){
		let tot = a.length, res, div, va, vb, vc, vd, ve, vf, vg, vh, vi, vj, vk
		res = tot % 11
		div = (tot - res) / 11

		if (tot < 11){
			RapidStore.c_a('e_c', false)
			RapidStore.c_a('e_o', true)
			return 
		} else {
			va = {a : 0,        b : div - 1,         	 c : 'var(--bs-red1)'}
			vb = {a : div,	    b : div * 2 - 1,     	 c : 'var(--bs-red2)'}
			vc = {a : div * 2,  b : div * 3 - 1,     	 c : 'var(--bs-orange1)'}
			vd = {a : div * 3,  b : div * 4 - 1,     	 c : 'var(--bs-orange2)'}
			ve = {a : div * 4,  b : div * 5 - 1,     	 c : 'var(--bs-orange3)'}
			vf = {a : div * 5,  b : div * 6 - 1,     	 c : 'var(--bs-orange4)'}
			vg = {a : div * 6,  b : div * 7 - 1,     	 c : 'var(--bs-green1)'}
			vh = {a : div * 7,  b : div * 8 - 1,     	 c : 'var(--bs-green2)'}
			vi = {a : div * 8,  b : div * 9 - 1,     	 c : 'var(--bs-green3)'}
			vj = {a : div * 9,  b : div * 10 - 1,    	 c : 'var(--bs-green4)'}
			vk = {a : div * 10, b : div * 11 + res - 1,  c : 'var(--bs-green5)'}
		}
		
		// console.log(tot, res, div, va, vb, vc, vd, ve, vf, vg, vh, vi, vj, vk)   // observada hay que iterarlo reducir codigo

		let aa = []			// array coordinates for polyline
		let az = []			// array coordinates for polyline
		let ay = []			// array coordinates for polyline

		let ab = []			// array polyline
		let ac = []			// nombre de
		let ad = []		    // var state motion
		let ae = []			// var estate engine
		let af = []			// confirm estate engine
		let ag = []			// gettimes - ii diference
		let ah = []			// data for markers
		let ai = []			// coordinates
		let aj = [] 		// attributes
		let ak = []			// titulo o  solo cordenadas
		let al = []			// titulo o  solo cordenadas
		let am = []			// titulo o  solo cordenadas
		let an = []			// titulo o  solo cordenadas

		let ba = ''
		let bb = ''
		let bc = ''
		let bd = ''

		let ca
		// console.log(a, res, tot, div,  'JS-01194', new Date())
		Promise.all(a.map((e, i)=>{
			ca = JSON.parse(e.attributes)
			// console.log(ca)

			if (va.a <= i & i <= va.b){
				az.push([e.latitude, e.longitude])
				if (i === va.b){
					ay.push([e.latitude, e.longitude])
					aa.push({color: va.c, cords : [az]})
				}
			}
			if (vb.a <= i & i <= vb.b){
				if (vb.a === i){
					az = []
					az.push(ay[0])
				}
				az.push([e.latitude, e.longitude])
				if (i === vb.b){
					ay = []
					ay.push([e.latitude, e.longitude])
					aa.push({color: vb.c, cords : [az]})
				}
			}
			if (vc.a <= i & i <= vc.b){
				if (vc.a === i){
					az = []
					az.push(ay[0])
				}
				az.push([e.latitude, e.longitude])
				if (i === vc.b){
					ay = []
					ay.push([e.latitude, e.longitude])
					aa.push({color: vc.c, cords : [az]})
				}
			}
			if (vd.a <= i & i <= vd.b){
				if (vd.a === i){
					az = []
					az.push(ay[0])
				}
				az.push([e.latitude, e.longitude])
				if (i === vd.b){
					ay = []
					ay.push([e.latitude, e.longitude])
					aa.push({color: vd.c, cords : [az]})
				}
			}
			if (ve.a <= i & i <= ve.b){
				if (ve.a === i){
					az = []
					az.push(ay[0])
				}
				az.push([e.latitude, e.longitude])
				if (i === ve.b){
					ay = []
					ay.push([e.latitude, e.longitude])
					aa.push({color: ve.c, cords : [az]})
				}
			}
			if (vf.a <= i & i <= vf.b){
				if (vf.a === i){
					az = []
					az.push(ay[0])
				}
				az.push([e.latitude, e.longitude])
				if (i === vf.b){
					ay = []
					ay.push([e.latitude, e.longitude])
					aa.push({color: vf.c, cords : [az]})
				}
			}
			if (vg.a <= i & i <= vg.b){
				if (vg.a === i){
					az = []
					az.push(ay[0])
				}
				az.push([e.latitude, e.longitude])
				if (i === vg.b){
					ay = []
					ay.push([e.latitude, e.longitude])
					aa.push({color: vg.c, cords : [az]})
				}
			}
			if (vh.a <= i & i <= vh.b){
				if (vh.a === i){
					az = []
					az.push(ay[0])
				}
				az.push([e.latitude, e.longitude])
				if (i === vh.b){
					ay = []
					ay.push([e.latitude, e.longitude])
					aa.push({color: vh.c, cords : [az]})
				}
			}
			if (vi.a <= i & i <= vi.b){
				if (vi.a === i){
					az = []
					az.push(ay[0])
				}
				az.push([e.latitude, e.longitude])
				if (i === vi.b){
					ay = []
					ay.push([e.latitude, e.longitude])
					aa.push({color: vi.c, cords : [az]})
				}
			}
			if (vj.a <= i & i <= vj.b){
				if (vj.a === i){
					az = []
					az.push(ay[0])
				}
				az.push([e.latitude, e.longitude])
				if (i === vj.b){
					ay = []
					ay.push([e.latitude, e.longitude])
					aa.push({color: vj.c, cords : [az]})
				}
			}
			if (vk.a <= i & i <= vk.b){
				if (vk.a === i){
					az = []
					az.push(ay[0])
				}
				az.push([e.latitude, e.longitude])
				if (i === vk.b){
					ay = []
					ay.push([e.latitude, e.longitude])
					aa.push({color: vk.c, cords : [az]})
				}
			}



			// aa.push([e.latitude, e.longitude])



			


			
			an.push({
				id   : i,
				data : e
			})
			// if (0 === e.speed){  // original solo cordenadas de salto con velocidad 0
			// 	an.push({
			// 		id   : i,
			// 		data : e
			// 	})
			// 	return
			// }
			if (10 < e.speed && e.speed <= 15){
				ab.push({
					id   : i,
					data : e
				})
				return
			}
			if (15 < e.speed && e.speed <= 20){
				ac.push({
					id   : i,
					data : e
				})
				return
			}
			if (20 < e.speed && e.speed <= 30){
				ad.push({
					id   : i,
					data : e
				})
				return
			}
			if (30 < e.speed){
				ae.push({
					id   : i,
					data : e
				})
				return
			}
			if (ca.alarm === 'overspeed'){
				af.push({
					id   : i,
					data : e
				})
				return
			}
			if (ca.ignition===true){
				ag.push({
					id   : i,
					data : e
				})
				return
			}
			if (ca.ignition===false){
				ah.push({
					id   : i,
					data : e
				})
				return
			}
			if (ca.alarm==='powerCut'){
				ai.push({
					id   : i,
					data : e
				})
				return
			}
			if (ca.alarm==='lowBattery'){
				aj.push({
					id   : i,
					data : e
				})
				return
			}
			if (ca.alarm==='sos'){
				ak.push({
					id   : i,
					data : e
				})
				return
			}
			if (ca.alarm==='shock'){
				al.push({
					id   : i,
					data : e
				})
				return
			}
			if (ca.alarm==='door'){
				am.push({
					id   : i,
					data : e
				})
				return
			}


		})).then (()=>{
			try {
				//RapidStore.b_e({
				//	jumpin  : an
				//})

				RapidStore.b_e({
					rout   	: aa,
					speeye  : ab,
					speeor  : ac,
					speere  : ad,
					speeda  : ae,
					oversp  : af,
					poweon  : ag,
					poweof  : ah,
					powecu  : ai,
					lowbat  : aj,
					alasos  : ak,
					ashock  : al,
					aldoor  : am,
					jumpin  : an
				})
			} catch (e){
				console.log(e, 'error b_e')
			}

		})


		//console.log('b', RapidStore.a_k)

		/*
		if (ll.ignition === true){
			nn = true
			mm = true
			if (ll.motion === true){
				pp = true
				aa = 'limeOptions'
				bb = {
					code 	: 1,
					color 	: 'orange',
					icon 	: 'icon-encendido trabado',
					icolor 	: 'yellow',
					coords 	: [jj, kk],
				}
			} else {
				pp = false
				aa = 'limeOptions'
				bb = {
					code 	: 2,
					color 	: 'green',
					icon 	: 'icon-encendido adecuado',
					icolor 	: 'green',
					coords 	: [jj, kk],
				}
			}

		}
		if (ll.ignition === false) {
			mm = true
			if (ll.motion === true){
				pp = true
				aa = 'limeOptions'
				bb = {
					code 	: 3,
					color : 'red',
					icon : 'icon-apagado brusco',
					icolor : 'yellow',
					coords 	: [jj, kk],
				}

			} else {
				pp = false
				aa = 'grayOptions'
				bb = {
					code 	: 4,
					color : 'blue',
					icon : 'icon-apagado adecuado',
					icolor : 'green',
					coords 	: [jj, kk],
				}
			}
		}
		if (ll.ignition === undefined) {
			cc = true
			if (ll.motion === true){
				pp = true
				aa = 'limeOptions'
			} else {
				pp = false
				aa = 'grayOptions'
			}
		}






							if (mm === false){
										if (ii < 60000  || ii < 15000){
											nn = true
										}
									} else {
										if (nn === true && pp=== true && cc === false){
											if (ll.ignition === true){
												if (ll.motion === true){
													ww.push({
														full 	: p,
														icon 	: bb,
														colorl	: aa,
														cords 	: [p.latitude, p.longitude]
													})
													bb = {color : 'Red', icon : 'icon-encnedido movimiento - trabado - naranja '}
												} else {
													pp = false
													bb = {color : 'yellow', icon : 'icon-encendido adecuado observado - yellow'}
												}
											}
											if (ll.ignition === false){
												if (ll.motion === true){
													nn = false
													bb = {color : 'Red', icon : 'icon-manipulacion-de-chapa - naranja'}
												} else {
													nn = false
													pp = false
													bb = {color : 'verde', icon : 'icon-apagado correctivo - verde'}
												}
											}
											if (ll.ignition === undefined) {
												cc = true
												if (ll.motion === true){
													pp = true
												} else {
													pp = false
													bb = {color : 'verde', icon : 'icon-apagado correctivo - verde'}
												}
											}
											oo.push([jj, kk])
										}
										if (nn === true && pp=== true && cc === false){
											if (ll.ignition === true){
												if (ll.motion === true){
													pp = true
													bb = {color : 'Yellow', icon : 'icon-encnedido movimiento - trabado - yellow '}
												} else {
													bb = {color : 'yellow', icon : 'icon-manipulando la chapa - yellow'}
												}
											}
											if (ll.ignition === false){
												if (ll.motion === true){
													nn = false
													pp = true
													bb = {color : 'yellow', icon : 'icon - encendido en primer cambio apagado brusco - naranja'}
												} else {
													nn = false
													bb = {color : 'verde', icon : 'icon-apagado correctivo - verde'}
												}
											}
											if (ll.ignition === undefined) {
												cc = true
												if (ll.motion === true){
													pp = true
												} else {
													pp = false
												}
											}
											oo.push([jj, kk])
										}





										if (nn === true && cc === true){
											if (ll.ignition === true){
												if (ll.motion === true){
													aa = 'limeOptions'
													bb = {color : 'Red', icon : 'icon-manipulacion-de-chapa'}
												} else {
													aa = 'grayOptions'
													bb = {color : 'red', icon : 'icon-apagado repentino'}
												}
											}
										}


									}










				if (gg === undefined){
									dd++
									gg = new Date(p.devicetime).getTime()

									ww.push({
										full 	: p,
										icon 	: bb,
										colorl	: aa,
										cords 	: [p.latitude, p.longitude]
									})
									//oo.push([jj, kk])
								} else {
									hh = new Date(p.devicetime).getTime()
									ii = hh - gg


									if (ll.motion === true && nn ===  true){

										oo.push([p.latitude, p.longitude])
										if (p.speed > 5){
											bb++
										}
									}

									//gg = new Date(p.devicetime).getTime()
								}
















				*/
	}
	a_y(a, b){
		let yy, xx
		if (b === 1){
			yy = 'duc'
			xx = 'icon-00031'
		}
		if (b === 2){
			yy = 'orange'
			xx = 'icon-00041'
		}
		if (b === 3){
			yy = 'red'
			xx = 'icon-00051'
		}
		if (b === 4){
			yy = 'danger'
			xx = 'icon-00061'
		}
		if (b === 5){
			yy = 'danger'
			xx = 'icon-00061'
		}
		if (b === 6){
			yy = 'green'
			xx = 'icon-model_training'
		}
		if (b === 7){
			yy = 'green'
			xx = 'icon-settings_backup_restore'
		}
		if (b === 8){
			yy = 'red'
			xx = 'icon-flash_off'
		}
		if (b === 9){
			yy = 'orange'
			xx = 'icon-battery_alert'
		}
		if (b === 10){
			yy = 'danger'
			xx = 'icon-ic6'
		}
		if (b === 11){
			yy = 'orange'
			xx = 'icon-ic4'
		}
		if (b === 12){
			yy = 'red'
			xx = 'icon-privacy_tip'
		}
		if (b === 13){
			yy = 'yellow'
			xx = 'icon-pacman'
		}
		return ({
			className: 'custom-div-icon-mini',
			html: "<div class='marker-pin " +  yy + "'></div><i class='" + xx + "'></i>",
			iconSize: [14, 14],
			iconAnchor: [7, 14]
		})
	}
	a_z(a){
		if (a===1){
			if (RapidStore.a_c[0].g_a === false){
				RapidStore.c_a('g_a', true)

			} else {
				RapidStore.c_a('g_a', false)
			}
		}
		if (a===2){
			if (RapidStore.a_c[0].g_b === false){
				RapidStore.c_a('g_b', true)

			} else {
				RapidStore.c_a('g_b', false)
			}
		}
		if (a===3){
			if (RapidStore.a_c[0].g_c === false){
				RapidStore.c_a('g_c', true)

			} else {
				RapidStore.c_a('g_c', false)
			}
		}
		if (a===4){
			if (RapidStore.a_c[0].g_d === false){
				RapidStore.c_a('g_d', true)

			} else {
				RapidStore.c_a('g_d', false)
			}
		}
		if (a===5){
			if (RapidStore.a_c[0].g_e === false){
				RapidStore.c_a('g_e', true)

			} else {
				RapidStore.c_a('g_e', false)
			}
		}
		if (a===6){
			if (RapidStore.a_c[0].g_f === false){
				RapidStore.c_a('g_f', true)

			} else {
				RapidStore.c_a('g_f', false)
			}
		}
		if (a===7){
			if (RapidStore.a_c[0].g_g === false){
				RapidStore.c_a('g_g', true)

			} else {
				RapidStore.c_a('g_g', false)
			}
		}
		if (a===8){
			if (RapidStore.a_c[0].g_h === false){
				RapidStore.c_a('g_h', true)

			} else {
				RapidStore.c_a('g_h', false)
			}
		}
		if (a===9){
			if (RapidStore.a_c[0].g_i === false){
				RapidStore.c_a('g_i', true)

			} else {
				RapidStore.c_a('g_i', false)
			}
		}
		if (a===10){
			if (RapidStore.a_c[0].g_j === false){
				RapidStore.c_a('g_j', true)

			} else {
				RapidStore.c_a('g_j', false)
			}
		}
		if (a===11){
			if (RapidStore.a_c[0].g_k === false){
				RapidStore.c_a('g_k', true)

			} else {
				RapidStore.c_a('g_k', false)
			}
		}
		if (a===12){
			if (RapidStore.a_c[0].g_l === false){
				RapidStore.c_a('g_l', true)

			} else {
				RapidStore.c_a('g_l', false)
			}
		}
		if (a===13){
			if (RapidStore.a_c[0].g_m === false){
				RapidStore.c_a('g_m', true)

			} else {
				RapidStore.c_a('g_m', false)
			}
		}

		// 	xx = 'icon-notifications_on'
		// else {
		// 	if (a.ignition===1 && zz.motion===true){
		// 		yy = 'teal'
		// 		xx = 'icon-notifications_on'
		// 	}
		// 	if (a.ignition===1 && zz.motion===false){
		// 		yy = 'duc'
		// 		xx = 'icon-notifications_on'
		// 	}
		// 	if (a.ignition===0 && zz.motion===false){
		// 		yy = 'blue'
		// 		xx = 'icon-notifications_on'
		// 	}
		// 	if (a.ignition===0 && zz.motion===true){
		// 		yy = 'gray'
		// 		xx = 'icon-notifications_on'
		// 	}
		// }

	}
	b_a(a){
		if (a===1){
			if (RapidStore.a_c[0].g_a === false){
				return 'base-button inactive'
			} else {
				return 'base-button active'
			}
		}
		if (a===2){
			if (RapidStore.a_c[0].g_b === false){
				return 'base-button inactive'
			} else {
				return 'base-button active'
			}
		}
		if (a===3){
			if (RapidStore.a_c[0].g_c === false){
				return 'base-button inactive'
			} else {
				return 'base-button active'
			}
		}
		if (a===4){
			if (RapidStore.a_c[0].g_d === false){
				return 'base-button inactive'
			} else {
				return 'base-button active'
			}
		}
		if (a===5){
			if (RapidStore.a_c[0].g_e === false){
				return 'base-button inactive'
			} else {
				return 'base-button active'
			}
		}
		if (a===6){
			if (RapidStore.a_c[0].g_f === false){
				return 'base-button inactive'
			} else {
				return 'base-button active'
			}
		}
		if (a===7){
			if (RapidStore.a_c[0].g_g === false){
				return 'base-button inactive'
			} else {
				return 'base-button active'
			}
		}
		if (a===8){
			if (RapidStore.a_c[0].g_h === false){
				return 'base-button inactive'
			} else {
				return 'base-button active'
			}
		}
		if (a===9){
			if (RapidStore.a_c[0].g_i === false){
				return 'base-button inactive'
			} else {
				return 'base-button active'
			}
		}
		if (a===10){
			if (RapidStore.a_c[0].g_j === false){
				return 'base-button inactive'
			} else {
				return 'base-button active'
			}
		}
		if (a===11){
			if (RapidStore.a_c[0].g_k === false){
				return 'base-button inactive'
			} else {
				return 'base-button active'
			}
		}
		if (a===12){
			if (RapidStore.a_c[0].g_l === false){
				return 'base-button inactive'
			} else {
				return 'base-button active'
			}
		}
		if (a===13){
			if (RapidStore.a_c[0].g_m === false){
				return 'base-button inactive'
			} else {
				return 'base-button active'
			}
		}
		if (a===14){
				return 'base-button inactive'
		}
	}
	b_b(a){
		if (a === 0 ){
			RapidStore.c_a('e_h', dateFormat(new Date(), 'yyyy-mm-dd 00:00:00'))
			RapidStore.c_a('e_i', dateFormat(new Date(), 'yyyy-mm-dd HH:MM:ss'))
			return
		}
		if (a === 1 ){

			return
		}
	}
	//return range date for range date picker
	bac(a, b){
		let now =  new Date();
		RapidStore.c_a('e_u', new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds()))
		if (a===1){  // periodo de horas, 24 horas del dia anterior + mas horas del dia actual 
			RapidStore.c_a('e_x', 0)
			RapidStore.c_a('e_t', [
				new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1),
				new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999)
			])
		}
		if (a===2){
			RapidStore.c_a('e_x', b[1].getDate() - b[0].getDate())
			RapidStore.c_a('e_t', [
				b[0],
				b[1]
			])
		}
	}
	bad(a){
		//console.log(a)
		if (RapidStore.a_c[0].e_x != 0){
			return dateFormat(a, 'HH')
		} else {
			return dateFormat(a, 'HH:MM')
		}
	}
	bae(a) {
		// console.log(a, RapidStore.a_c[0].e_z)
		if (RapidStore.a_c[0].e_z < 2){
			RapidStore.c_a('e_z', RapidStore.a_c[0].e_z + 1)
		} else {
			RapidStore.c_a('e_r', true)
		}
		RapidStore.c_a('e_m', a.error)
	}
	async baf(a, b){
		if (b === 'pr'){  // server onta
			return new Promise( (resolve, reject) =>{
				RapidStore.c_a('j_b', new WebSocket(a))
				RapidStore.c_a('j_c', true)
				RapidStore.a_c[0].j_b.onopen = () => {
					RapidStore.c_a('j_d', true)
					resolve('WebSocket Client Connected. ')
				}
				//console.log(RapidStore.a_c[0].g_b)
				RapidStore.a_c[0].j_b.onerror = (e)=> {
					RapidStore.c_a('j_c', false)
					reject(e)
				}
				/*
				setTimeout( () =>{
	
				}, 500)
				*/
			})
		}
		if (b === 'lo'){  //server munis
			return new Promise( (resolve, reject) =>{
				RapidStore.c_a('j_n', new WebSocket(a))
				RapidStore.a_c[0].j_n.onopen = () => {
					RapidStore.c_a('j_o', true)
					RapidStore.c_a('j_p', true)
					resolve('WebSocket Client Connected. ')
				}
				RapidStore.a_c[0].j_n.onerror = (e)=> {
					RapidStore.c_a('j_o', false)
					reject(e)
				}
				/*
				setTimeout( () =>{
	
				}, 500)
				*/
			})
		}
		if (b === 'wi'){  // provicional
			return new Promise( (resolve, reject) =>{
				RapidStore.c_a('j_h', new WebSocket(a))
				RapidStore.a_c[0].j_h.onopen = () => {
					RapidStore.c_a('j_i', true)
					RapidStore.c_a('j_j', true)
					resolve('WebSocket Client Connected. ')
				}
				RapidStore.a_c[0].j_h.onerror = (e)=> {
					RapidStore.c_a('j_i', false)
					reject(e)
				}
				/*
				setTimeout( () =>{
	
				}, 500)
				*/
			})
		}
	}
	bag(a, b){
	// var a contiene info del error
		if (b ==='pr'){   // revoite server
			setTimeout( () =>{
				console.log('Coneccion perdida, reconectando.')
				this.baf(RapidStore.a_c[0].j_a, 'pr').then((b)=>{
					console.log(b)
					// this.a_q(7, 0) // reconeccion
				}).catch((e)=>{
					console.log('Reintentando')
				})
				RapidStore.a_c[0].j_b.onclose = (e) => {
					this.bag(e, 'pr')
				}
			}, 10000)
		}
		if (b ==='lo'){ // revotre munius
			setTimeout( () =>{
				console.log('Coneccion perdida, reconectando.')
				this.baf(RapidStore.a_c[0].j_m, 'lo').then((b)=>{
					console.log(b)
					// this.a_q(7, 0) // reconeccion
				}).catch((e)=>{
					console.log('Reintentando')
				})
				RapidStore.a_c[0].j_n.onclose = (e) => {
					this.bag(e, 'lo')
				}
			}, 10000)
		}
		if (b ==='wi'){ // revote proicional
			setTimeout( () =>{
				console.log('Coneccion perdida, reconectando.')
				this.baf(RapidStore.a_c[0].j_g, 'wi').then((b)=>{
					console.log(b)
				}).catch((e)=>{
					console.log('Reintentando')
				})
				RapidStore.a_c[0].j_n.onclose = (e) => {
					this.bag(e, 'wi')
				}
			}, 10000)
		}
	}
	bah(a, b){
		let reader = new FileReader();
		console.log(a)
		
		reader.readAsDataURL(a.target.files[0]);
		reader.onload = function () {
			console.log(reader.result)
		}
		reader.onerror = function (error) {
			console.log('Error: ', error);
		}
	}
	bai(a, b, c){
		let time = new Date()
		let time2 = new Date()

		if (a===1) {
			Apiserver.aab(3, 0, 'http://147.182.180.146:' + RapidStore.a_f[0].Honta_munis_port + '/alert/complaintperson/?start_date='+ Auxiliar.returndate(Auxiliar.adddays(time2, -1)) + '&end_date=' + Auxiliar.returndate(time), 'GET', (e)=>{
				let segunda = JSON.parse(e)
				RapidStore.bag(segunda)
				// console.log(segunda)
				// localStorage.setItem('munistoken', segunda.tokens.access)
			}).catch((e)=>{
				console.log('error result a_c 4', e)
			})

			return
			console.log(time, ' ---------------------- ',  Auxiliar.adddays(time2, -1))
		}
		if (a===2) {
			
		}
		if (a===3) {
			
		}
		if (a===4) {
			
		}
		if (a===5) {
			console.log(a, b[0].getTime(),  Auxiliar.returndate(time), Auxiliar.returndate(Auxiliar.adddays(time2, -1)))

		}
	}
	

}
export default new Eventscolas();