import React 									from 'react'
import { observer }								from 'mobx-react'

import Eventscolas 								from '../../../fun/js'

import Auxiliar 								from '../../../fun/fun'

import MUO_functions 							from '../js/MUO_fun'
import MUO_str 									from '../storage/MUO_str'

import Apiserver								from "../js/MUO_APIs"

import Shop_spot 								from './shop_spot'

// let start = 0


class Shop_items extends React.Component {
	
	render () {
 		return(<>
			<div
				className='toggled-element-left light shop'
			>	
				
				<div 
					className='shop_details c_gray fo_sr20'
				>
					Abiso!!! por el momento esta shop permite comprar articulos solo par el servidor Kaelum,
					
				</div>
				<div 
					className='categories'
				>	
					<div 
						className='sp_popular caton'
					>
						{MUO_str.aad.af001}
					</div>
					<div 
						className='sp_packages catoff'
					>
						{MUO_str.aad.af002}
					</div>
					<div 
						className='sp_individual catoff'
					>
						{MUO_str.aad.af003}
					</div>
					
				</div>
				<div 
					className='shop_contend'
				>	
					<div
						className='shop_character'
					>	
						<div
							className='chars'
						>	
							<div
								className='sh_char_img cupo clasimg0'
								onClick={()=>(Auxiliar.togle_swith('bamda', MUO_str.aac[0].bamda, 1, '0'))}
							>	
								
							</div>
							<div
								className='sh_char_swt di5'
							>
								<input
									type='checkbox'
									id='shop_charsa'
									className='di0 sw'
									checked={MUO_str.aac[0].bamda === 1 ? 'checked' : ''} 
									onChange={()=>(Auxiliar.togle_swith('bamda', MUO_str.aac[0].bamda, 1, '0'))} 
									
								/>
								<label className='lbl' htmlFor='shop_charsa'></label> 
							</div>
						</div>
						<div
							className='chars'
						>	
							<div
								className='sh_char_img cupo clasimg16'
								onClick={()=>(Auxiliar.togle_swith('bamdb', MUO_str.aac[0].bamdb, 1, '16'))} 
							>	
								
							</div>
							<div
								className='sh_char_swt di5'
							>	
								<input
									type='checkbox'
									id='shop_charsb'
									className='di0 sw'
									checked={MUO_str.aac[0].bamdb === 1 ? 'checked' : ''} 
									onChange={()=>(Auxiliar.togle_swith('bamdb', MUO_str.aac[0].bamdb, 1, '16'))} 
									
								/>
								<label className='lbl' htmlFor='shop_charsb'></label> 
							</div>
							
						</div>
						<div
							className='chars'
						>	
							<div
								className='sh_char_img cupo clasimg32'
								onClick={()=>(Auxiliar.togle_swith('bamdc', MUO_str.aac[0].bamdc, 1, '32'))} 
							>	
								
							</div>
							<div
								className='sh_char_swt di5'
							>	
								<input
									type='checkbox'
									id='shop_charsc'
									className='di0 sw'
									checked={MUO_str.aac[0].bamdc === 1 ? 'checked' : ''} 
									onChange={()=>(Auxiliar.togle_swith('bamdc', MUO_str.aac[0].bamdc, 1, '32'))} 
									
								/>
								<label className='lbl' htmlFor='shop_charsc'></label> 
							</div>
						</div>
						<div
							className='chars'
						>	
							<div
								className='sh_char_img cupo clasimg48'
								onClick={()=>(Auxiliar.togle_swith('bamdd', MUO_str.aac[0].bamdd, 1, '48'))} 
							>	
								
							</div>
							<div
								className='sh_char_swt di5'
							>	
								<input
									type='checkbox'
									id='shop_charsd'
									className='di0 sw'
									checked={MUO_str.aac[0].bamdd === 1 ? 'checked' : ''} 
									onChange={()=>(Auxiliar.togle_swith('bamdd', MUO_str.aac[0].bamdd, 1, '48'))} 
									
								/>
								<label className='lbl' htmlFor='shop_charsd'></label> 
							</div>
						</div>
						<div
							className='chars'
						>	
							<div
								className='sh_char_img cupo clasimg64'
								onClick={()=>(Auxiliar.togle_swith('bamde', MUO_str.aac[0].bamde, 1, '64'))} 
							>	
								
							</div>
							<div
								className='sh_char_swt di5'
							>	
								<input
									type='checkbox'
									id='shop_charse'
									className='di0 sw'
									checked={MUO_str.aac[0].bamde === 1 ? 'checked' : ''} 
									onChange={()=>(Auxiliar.togle_swith('bamde', MUO_str.aac[0].bamde, 1, '64'))} 
									
								/>
								<label className='lbl' htmlFor='shop_charse'></label> 
							</div>
						</div>
						<div
							className='chars'
						>	
							<div
								className='sh_char_img cupo clasimg80'
								onClick={()=>(Auxiliar.togle_swith('bamdf', MUO_str.aac[0].bamdf, 1, '80'))} 
							>	
								
							</div>
							<div
								className='sh_char_swt di5'
							>	
								<input
									type='checkbox'
									id='shop_charsf'
									className='di0 sw'
									checked={MUO_str.aac[0].bamdf === 1 ? 'checked' : ''} 
									onChange={()=>(Auxiliar.togle_swith('bamdf', MUO_str.aac[0].bamdf, 1, '80'))} 
									
								/>
								<label className='lbl' htmlFor='shop_charsf'></label> 
							</div>
						</div>
						<div
							className='chars'
						>	
							<div
								className='sh_char_img cupo clasimg96'
								onClick={()=>(Auxiliar.togle_swith('bamdg', MUO_str.aac[0].bamdg, 1, '96'))} 
							>	
								
							</div>
							<div
								className='sh_char_swt di5'
							>	
								<input
									type='checkbox'
									id='shop_charsg'
									className='di0 sw'
									checked={MUO_str.aac[0].bamdg === 1 ? 'checked' : ''} 
									onChange={()=>(Auxiliar.togle_swith('bamdg', MUO_str.aac[0].bamdg, 1, '96'))} 
									
								/>
								<label className='lbl' htmlFor='shop_charsg'></label> 
							</div>
						</div>
						<div
							className='chars'
						>	
							<div
								className='sh_char_img cupo clasimg112'
								onClick={()=>(Auxiliar.togle_swith('bamdh', MUO_str.aac[0].bamdh, 1, '112'))} 
							>	
								
							</div>
							<div
								className='sh_char_swt di5'
							>	
								<input
									type='checkbox'
									id='shop_charsh'
									className='di0 sw'
									checked={MUO_str.aac[0].bamdh === 1 ? 'checked' : ''} 
									onChange={()=>(Auxiliar.togle_swith('bamdh', MUO_str.aac[0].bamdh, 1, '112'))} 
									
								/>
								<label className='lbl' htmlFor='shop_charsh'></label> 
							</div>
						</div>
						<div
							className='chars'
						>	
							<div
								className='sh_char_img cupo clasimg128'
								onClick={()=>(Auxiliar.togle_swith('bamdi', MUO_str.aac[0].bamdi, 1, '128'))} 
							>	
								
							</div>
							<div
								className='sh_char_swt di5'
							>	
								<input
									type='checkbox'
									id='shop_charsi'
									className='di0 sw'
									checked={MUO_str.aac[0].bamdi === 1 ? 'checked' : ''} 
									onChange={()=>(Auxiliar.togle_swith('bamdi', MUO_str.aac[0].bamdi, 1, '128'))} 
									
								/>
								<label className='lbl' htmlFor='shop_charsi'></label> 
							</div>
						</div>
						<div
							className='chars'
						>	
							<div
								className='sh_char_img cupo clasimg144'
								onClick={()=>(Auxiliar.togle_swith('bamdj', MUO_str.aac[0].bamdj, 1, '144'))} 
							>	
								
							</div>
							<div
								className='sh_char_swt di5'
							>	
								<input
									type='checkbox'
									id='shop_charsj'
									className='di0 sw'
									checked={MUO_str.aac[0].bamdj === 1 ? 'checked' : ''} 
									onChange={()=>(Auxiliar.togle_swith('bamdj', MUO_str.aac[0].bamdj, 1, '144'))} 
									
								/>
								<label className='lbl' htmlFor='shop_charsj'></label> 
							</div>
						</div>
						<div
							className='chars'
						>	
							<div
								className='sh_char_img cupo clasimg160'
								onClick={()=>(Auxiliar.togle_swith('bamdk', MUO_str.aac[0].bamdk, 1, '160'))} 
							>	
								
							</div>
							<div
								className='sh_char_swt di5'
							>	
								<input
									type='checkbox'
									id='shop_charsk'
									className='di0 sw'
									checked={MUO_str.aac[0].bamdk === 1 ? 'checked' : ''} 
									onChange={()=>(Auxiliar.togle_swith('bamdk', MUO_str.aac[0].bamdk, 1, '160'))} 
									
								/>
								<label className='lbl' htmlFor='shop_charsk'></label> 
							</div>
						</div>
						<div
							className='chars'
						>	
							<div
								className='sh_char_img cupo clasimg176'
								onClick={()=>(Auxiliar.togle_swith('bamdl', MUO_str.aac[0].bamdl, 1, '176'))} 
							>	
								
							</div>
							<div
								className='sh_char_swt di5'
							>	
								<input
									type='checkbox'
									id='shop_charsl'
									className='di0 sw'
									checked={MUO_str.aac[0].bamdl === 1 ? 'checked' : ''} 
									onChange={()=>(Auxiliar.togle_swith('bamdl', MUO_str.aac[0].bamdl, 1, '176'))} 
									
								/>
								<label className='lbl' htmlFor='shop_charsl'></label> 
							</div>
						</div>
						<div
							className='chars'
						>	
							<div
								className='sh_char_img cupo clasimg192'
								onClick={()=>(Auxiliar.togle_swith('bamdm', MUO_str.aac[0].bamdm, 1, '192'))} 
							>	
								
							</div>
							<div
								className='sh_char_swt di5'
							>	
								<input
									type='checkbox'
									id='shop_charsm'
									className='di0 sw'
									checked={MUO_str.aac[0].bamdm === 1 ? 'checked' : ''} 
									onChange={()=>(Auxiliar.togle_swith('bamdm', MUO_str.aac[0].bamdm, 1, '192'))} 
									
								/>
								<label className='lbl' htmlFor='shop_charsm'></label> 
							</div>
						</div>
						<div
							className='chars'
						>	
							<div
								className='sh_char_img cupo clasimg208'
								onClick={()=>(Auxiliar.togle_swith('bamdn', MUO_str.aac[0].bamdn, 1, '208'))} 
							>	
								
							</div>
							<div
								className='sh_char_swt di5'
							>	
								<input
									type='checkbox'
									id='shop_charsn'
									className='di0 sw'
									checked={MUO_str.aac[0].bamdn === 1 ? 'checked' : ''} 
									onChange={()=>(Auxiliar.togle_swith('bamdn', MUO_str.aac[0].bamdn, 1, '208'))} 
									
								/>
								<label className='lbl' htmlFor='shop_charsn'></label> 
							</div>
						</div>
					</div>
					<div
						className='shop_items'
					>	
						{console.log(MUO_str.aaj.dd)}

						{MUO_str.aaj.dd.map((m, i) =>(
							m.Active?
								<div
									key={m.Id_shop}
									className='sp_item cupo'
									// sfasfsf={console.log(MUO_str.aac[0].bame,  m.Chars)}
									onClick={()=>
										m.Chars === null && MUO_str.aac[0].bame === '' ? 
											Auxiliar.modal_control(0, {var1 : 'cai', var2 : 'ccc', e : true})
										: 
											Auxiliar.modal_control(1, {var1 : 'cag', var2 : 'ccc', e : true, data : m})
									}
								>
									<div 
										className='sh_item_img'
									>
										<img 
											className="wip100 hep100"
											src={m.Img} alt=""
										/>
									</div>
									<div 
										className='sh_item_tit'
									>
										{MUO_str.aad['' + m.Name_item + '']}
									</div>
									<div 
										className='sh_item_det'
									>
										{MUO_str.aad['' + m.Description + '']}
									</div>
									<div 
										className='sh_item_pri'
									>
										<div 
											className=''
										>
											{'$' + m.Price_web}
										</div>
									</div>



								</div>
							: ''
						))}
					</div>


				</div>
			</div>
 		</>)
	}
}
export default observer(Shop_items)