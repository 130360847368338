import "core-js"
import "core-js/features/set"
import "core-js/actual/set"
import "core-js/stable/set"
import "core-js/es/set"

// import 'core-js/es6/set';
// import 'core-js/fn/array/find';
// import 'core-js/fn/array/includes';
// import 'core-js/fn/number/is-nan';

import React from 'react'
import ReactDOM from 'react-dom/client'

import {createBrowserRouter, RouterProvider} from "react-router-dom"
import './index.css'

// import MUO_str 								from './modulos/piezas/MUO/storage/MUO_str'

// modulos muweb
import Muapp                  from './modulos/piezas/MUO/MUO_App'
import Muapp_error            from './modulos/piezas/MUO/error/Muapp_error'

import Home 									from './modulos/piezas/MUO/home/home'
import Ranking 								from './modulos/piezas/MUO/ranking/ranking'
import Shop 								  from './modulos/piezas/MUO/shop/shop'
import Register 						  from './modulos/piezas/MUO/register/register'

// modulos clicgps
import CGPSapp                  from './modulos/piezas/CGPS/CGPS_App'
import CGPSapp_error            from './modulos/piezas/CGPS/error/CGPS_error'

import reportWebVitals from './reportWebVitals'

// muo -> muweb
// cgps -> Consorcio clic GPS 
let web = 'cgps'

const router = createBrowserRouter([

  {
    path: "/",
    element: 
      web ==='muo'? <Muapp /> : 
      web ==='cgps'? <CGPSapp /> : 
      '',
    errorElement:
      web ==='muo'? <Muapp_error /> :
      web ==='cgps'? <CGPSapp_error /> :
      '',

    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "ranking",
        element: <Ranking />,  
      },
      {
        path: "shop",
        element: <Shop />,
      },
      {
        path: "register",
        element: <Register />,
      },
      // {
      //   path: "contacts/:contactId",
      //   element: <Contact />,
      // },
    ],
  },
]);



const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
    {/* <App /> */}
  </React.StrictMode>
)
// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
